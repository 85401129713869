import clsx from "clsx";
import { useStore } from "effector-react";
import React, { ComponentProps, FC, useEffect, useRef, useState } from "react";
import Transition from "react-transition-group/Transition";
import ReactResizeDetector from "react-resize-detector";
import { blockStore } from "stores/block";
import { ContainerSize } from "types";
import { calculateInnerBlockSizeToCoverContainerFromRatio } from "utils/ratio";
import blockController from "controllers/blockController";

type AdjustedSizeBlockProps = {
  containerSize: ContainerSize;

  isActive: boolean;
  isPrevious: boolean;
  isNext: boolean;
  blockKey: string;
  blockType: string;
};

function generateStyle(
  containerSize: ContainerSize,
  innerRatio: number,
): ComponentProps<"div">["style"] {
  const { width, height } = calculateInnerBlockSizeToCoverContainerFromRatio(
    containerSize,
    innerRatio,
  );

  const left = (containerSize.width - width) / 2;
  const top = (containerSize.height - height) / 2;

  const style: ComponentProps<"div">["style"] = {
    width,
    height,
    left,
    top,
    overflow: "auto",
  };

  return style;
}

const AdjustedSizeBlock: FC<AdjustedSizeBlockProps> = ({
  children,
  containerSize,
  isActive,
  isPrevious,
  isNext,
  blockKey,
  blockType,
}) => {
  const blocksStates = useStore(blockStore);
  const thisBlockState = blocksStates[blockKey];

  const divRef = useRef<HTMLDivElement>(null);

  const readyForAdjustingSize = thisBlockState?.readyForAdjustingSize || false;
  const innerRatio = thisBlockState?.ratioSnapshot?.ratio;

  const containerWidth = Math.ceil(containerSize.width); // just cannot be bothered with putting an object as a dependency...
  const containerHeight = Math.ceil(containerSize.height);

  const [styles, setStyles] = useState<ComponentProps<"div">["style"]>({});

  useEffect(() => {
    if (!readyForAdjustingSize) {
      return;
    }

    if ("number" !== typeof innerRatio) {
      return;
    }

    const newStyles = generateStyle(
      {
        width: containerWidth,
        height: containerHeight,
      },
      innerRatio,
    );

    setStyles(newStyles);
  }, [innerRatio, containerWidth, containerHeight, readyForAdjustingSize]);

  return (
    <Transition timeout={1000} in={isActive}>
      {(state) => (
        <div
          data-blocktype={blockType}
          data-blockkey={blockKey}
          style={styles}
          className={clsx(state, "block", {
            transparent: !(isActive || isPrevious),
            active: isActive,
            previous: isPrevious,
            next: isNext,
          })}
        >
          <ReactResizeDetector
            handleWidth
            handleHeight
            targetRef={divRef}
            onResize={(
              width: undefined | number,
              height: undefined | number,
            ) => {
              if ("number" === typeof width && "number" === typeof height) {
                blockController.setRatioSnapshot(
                  blockKey,
                  {
                    accuracy: Math.hypot(width, height),
                    ratio: width / height,
                  },
                  { ignoreIfLowerAccuracy: true },
                );
              }
            }}
          >
            <div className="block-inner" ref={divRef}>
              {children}
            </div>
          </ReactResizeDetector>
        </div>
      )}
    </Transition>
  );
};

export default AdjustedSizeBlock;
