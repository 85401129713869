import configService from "services/configService";
import { getResponseErrorMessage } from "utils/responseError";

async function get() {
  try {
    const result = await configService.get();

    const output = {
      data: result?.data || null,
    };

    return output;
  } catch (e) {
    return {
      errorMessage: getResponseErrorMessage(e),
    };
  }
}

const configActions = {
  get,
};

export default configActions;
