import { createStore, createEvent } from "effector";
import { ImageSequenceVideoPausePoint } from "../components/ImageSequenceVideo";

export enum ImageSequenceVideoPlayStatus {
  PLAYING_FORWARD = "->",
  PLAYING_BACKWARDS = "<-",
  PAUSED = "=",
}

export type ImageSequenceVideoState = {
  momentumTimeoutId: number | null;
  momentum: number;

  activeFrameIndex: number;
  playIntervalId: number | null;
  playStatus: ImageSequenceVideoPlayStatus;
  fps: number;
  maxFrameIndex: number;
  pauses: ImageSequenceVideoPausePoint[];
  ignoreEdge: boolean;
};

export type ImageSequenceVideoStore = {
  [key: string]: ImageSequenceVideoState;
};

export type UpdateVideoPayload = {
  imageSequenceVideoKey: string;
  updatedState: Partial<ImageSequenceVideoState>;
};

export const updateImageSequenceVideoState = createEvent<UpdateVideoPayload>(
  "updateImageSequenceVideoState",
);

export const imageSequenceVideoStore = createStore<ImageSequenceVideoStore>(
  {},
).on(updateImageSequenceVideoState, (oldState, payload) => ({
  ...oldState,
  [payload.imageSequenceVideoKey]: {
    ...(oldState[payload.imageSequenceVideoKey] || {}),
    ...payload.updatedState,
  },
}));

/*
imageSequenceVideoStore.watch((state) => {
  console.log(JSON.stringify(state, null, 2));
});
*/
