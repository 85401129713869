import React, { FC } from "react";
import clsx from "clsx";
import qs from "qs";
import { useTranslation } from "react-i18next";

import I18NextLanguageSwitch from "components/I18NextLanguageSwitch";
import HeaderLogo from "./HeaderLogo";

import "./styles.scss";
import { prepareSearch } from "utils/qs";
import { CommonSearchParams } from "types";

type MenuItem = {
  title: string;
  key: string;
  isActive: boolean;
  onClick: () => void;
};

type MenuProps = {
  items: MenuItem[];
  expanded: boolean;
};

const Header: FC<MenuProps> = ({ items, expanded }) => {
  const { t } = useTranslation();

  const search = qs.parse(
    prepareSearch(window.location.search),
  ) as CommonSearchParams;

  const hideLanguageSelector = search.hidelanguageoption === "1";
  const showLanguageSelector = !hideLanguageSelector;

  return (
    <div className={clsx("header", { expanded })}>
      <HeaderLogo visible={false} />
      <nav className={clsx("header__nav")}>
        {items.map((item) => {
          return (
            <button
              className={clsx("header__nav-item", {
                "header__nav-item--active": item.isActive,
              })}
              onClick={() => {
                item.onClick();
              }}
              key={item.key}
            >
              {t(item.title)}
            </button>
          );
        })}
      </nav>
      {showLanguageSelector && <I18NextLanguageSwitch />}
    </div>
  );
};

export default Header;
