import { createStore, createEvent } from "effector";
import { VideoPausePoint } from "../components/Video";
import { VideoDirection } from "../types";

export type VideoState = {
  direction: VideoDirection;

  currentSecond: number;
  playStartedAt: number;

  forwardPauses: VideoPausePoint[];
  backwardsPauses: VideoPausePoint[];

  forwardRef: HTMLVideoElement | null;
  reverseRef: HTMLVideoElement | null;

  onForwardTimeUpdateFunction:
    | null
    | ((this: HTMLVideoElement, e: Event) => void);
  onReverseTimeUpdateFunction:
    | null
    | ((this: HTMLVideoElement, e: Event) => void);
};

export type VideoStore = {
  [key: string]: VideoState;
};

export type UpdateVideoPayload = {
  videoKey: string;
  updatedState: Partial<VideoState>;
};

export const updateVideoState = createEvent<UpdateVideoPayload>("updateVideo");

export const videoStore = createStore<VideoStore>({}).on(
  updateVideoState,
  (oldState, payload) => ({
    ...oldState,
    [payload.videoKey]: {
      ...(oldState[payload.videoKey] || {}),
      ...payload.updatedState,
    },
  })
);

//videoStore.watch(console.log);
