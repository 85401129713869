import React, { FC } from "react";
import PageTitle from "components/PageTitle";
import { useTranslation } from "react-i18next";

import titleImageSrc from "./assets/media-library-title.svg";
import mediaImageSrc from "./assets/media-item.svg";
import videoImageSrc from "./assets/video-item.svg";
import clsx from "clsx";
import "./styles.scss";

type MediaCategoyProps = {
  title: string;
  type: string;
  items: MediaItemProps[];
};

type MediaItemProps = {
  name: string;
  link: string;
};

type MediaLibraryProps = {
  mediaList?: MediaCategoyProps[];
};

const MediaLibrary: FC<MediaLibraryProps> = ({ mediaList }) => {
  const { t } = useTranslation();

  return (
    <div className="media-library anim01 in anim-delay--085">
      <div className="media-library__container content-container">
        <PageTitle title={t("Media library")} imageSrc={titleImageSrc} />
        <div className="media-library__content">
          <div className="media-library__subtitle-wrapper">
            <h2 className="media-library__subtitle">{t("Documents")}</h2>
          </div>
          {mediaList ? (
            <div className="media-library__categories-list">
              {mediaList.map((category, index) => (
                <div className="media-library__category" key={index}>
                  <img
                    src={
                      category.type === "video" ? videoImageSrc : mediaImageSrc
                    }
                    alt={t(category.title)}
                    className={clsx({
                      "media-library__category-image": true,
                      video: category.type === "video",
                    })}
                  />
                  <div className="media-library__category-content">
                    <h3 className="media-library__category-title">
                      {t(category.title)}
                    </h3>
                    <ul className="media-library__items-list">
                      {category.items.map((item, index) => (
                        <li className="media-library__item" key={index}>
                          <a
                            className="media-library__item-name"
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>{t("No documents available")}</p>
          )}
          <div className="media-library__subtitle-wrapper bottom"></div>
        </div>
      </div>
    </div>
  );
};

export default MediaLibrary;
