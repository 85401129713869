import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const VerticalModeError = () => {
  const { t } = useTranslation();
  return (
    <div
      className="vertical-mode-error"
      dangerouslySetInnerHTML={{ __html: t("Vertical mode") }}
    />
  );
};

export default VerticalModeError;
