import { CommonResponsiveComponent } from "types";

export function findResponsiveOptionByWidth<
  T extends CommonResponsiveComponent
>(options: T[], width: number) {
  return (
    options.find((option) => {
      const fromMatches =
        "number" !== typeof option.inclusiveFrom ||
        width >= option.inclusiveFrom;
      const toMatches =
        "number" !== typeof option.nonInclusiveTo ||
        width < option.nonInclusiveTo;

      return toMatches && fromMatches;
    }) || null
  );
}
