import { VideoEdge } from "types";
import { ImageSequenceVideoPausePoint } from "../components/ImageSequenceVideo";
import {
  ImageSequenceVideoPlayStatus,
  ImageSequenceVideoState,
  imageSequenceVideoStore,
  updateImageSequenceVideoState,
} from "../stores/imageSequenceVideo";
import { reducePausePoints } from "../utils/commonVideo";
import { getIntervalDelayMs } from "../utils/imageSequenceVideo";

const momentumSettings = {
  // todo: store this for each imageSeq block in config. need to think of how to address the `intervalFromMomentum` function
  maxMomentum: 1200,
  defaultMomentumToAdd: 1,
  rezistancePerPlayCall: 250,
  intervalFromMomentum: (momentum: number) => 1000 / Math.abs(momentum),
};

function init(
  imageSequenceVideoKey: string,
  fps: number,
  pauses: ImageSequenceVideoPausePoint[],
  maxFrameIndex: number,
) {
  updateImageSequenceVideoState({
    imageSequenceVideoKey,
    updatedState: { fps, pauses, maxFrameIndex },
  });
}

function getImageSequenceVideoByKey(
  imageSequenceVideoKey: string,
): ImageSequenceVideoState | null {
  const state = imageSequenceVideoStore.getState();

  const imageSequenceVideo = state[imageSequenceVideoKey];

  return imageSequenceVideo || null;
}

function throwNotFoundError(imageSequenceVideoKey: string): never {
  throw new Error(
    `${imageSequenceVideoKey} was not found in state object. Most likely it was not inited`,
  );
}

function getImageSequenceVideoByKeyOrThrow(imageSequenceVideoKey: string) {
  const currentVideoSequence = getImageSequenceVideoByKey(
    imageSequenceVideoKey,
  );

  if (!currentVideoSequence) {
    throwNotFoundError(imageSequenceVideoKey);
  }

  return currentVideoSequence;
}

function playForward(
  imageSequenceVideoKey: string,
  {
    fromFrameIndex,
    onLastFrame,
  }: {
    fromFrameIndex?: number;
    onLastFrame?: (lastFrameIndex: number) => void;
  } = {},
) {
  const currentVideoSequence = getImageSequenceVideoByKeyOrThrow(
    imageSequenceVideoKey,
  );

  const { fps, pauses, maxFrameIndex } = currentVideoSequence;

  const imtervalMs = getIntervalDelayMs(fps);
  const reducedVideoPauses = reducePausePoints(pauses);
  const imageSequenceVideoState = imageSequenceVideoStore.getState();
  const selectedVideoState = imageSequenceVideoState[imageSequenceVideoKey];

  if (
    selectedVideoState?.playStatus ===
    ImageSequenceVideoPlayStatus.PLAYING_FORWARD
  ) {
    return;
  }

  if (selectedVideoState?.playIntervalId) {
    clearInterval(selectedVideoState.playIntervalId);
  }

  if ("number" === typeof fromFrameIndex) {
    setActiveFrameIndex(imageSequenceVideoKey, fromFrameIndex);
  }

  const newIntervalId = setInterval(() => {
    setActiveFrameIndex(imageSequenceVideoKey, (old) => {
      let newFrameIndex = old + 1;

      if (newFrameIndex > maxFrameIndex) {
        clearInterval(newIntervalId);
        newFrameIndex = maxFrameIndex;

        if ("function" === typeof onLastFrame) {
          onLastFrame(newFrameIndex);
        }

        updateImageSequenceVideoState({
          imageSequenceVideoKey,
          updatedState: {
            playStatus: ImageSequenceVideoPlayStatus.PAUSED,
            playIntervalId: null,
          },
        });
      }

      if (reducedVideoPauses[newFrameIndex]) {
        pause(imageSequenceVideoKey, newFrameIndex);
      }

      return newFrameIndex;
    });
  }, imtervalMs);

  updateImageSequenceVideoState({
    imageSequenceVideoKey,
    updatedState: {
      playStatus: ImageSequenceVideoPlayStatus.PLAYING_FORWARD,
      playIntervalId: Number(newIntervalId),
    },
  });
}

function playBackwards(
  imageSequenceVideoKey: string,
  {
    fromFrameIndex,
    fromEnd,
    onFirstFrame,
  }: {
    fromFrameIndex?: number;
    fromEnd?: boolean;
    onFirstFrame?: () => void;
  } = {},
) {
  const currentVideoSequence = getImageSequenceVideoByKeyOrThrow(
    imageSequenceVideoKey,
  );

  const { fps, pauses, maxFrameIndex } = currentVideoSequence;

  const imtervalMs = getIntervalDelayMs(fps);
  const reducedVideoPauses = reducePausePoints(pauses);
  const imageSequenceVideoState = imageSequenceVideoStore.getState();
  const selectedVideoState = imageSequenceVideoState[imageSequenceVideoKey];

  if (
    selectedVideoState?.playStatus ===
    ImageSequenceVideoPlayStatus.PLAYING_BACKWARDS
  ) {
    return;
  }

  if (selectedVideoState?.playIntervalId) {
    clearInterval(selectedVideoState.playIntervalId);
  }

  if (fromEnd) {
    setActiveFrameIndex(imageSequenceVideoKey, maxFrameIndex);
  } else if ("number" === typeof fromFrameIndex) {
    setActiveFrameIndex(imageSequenceVideoKey, maxFrameIndex);
  }

  const newIntervalId = setInterval(() => {
    setActiveFrameIndex(imageSequenceVideoKey, (old) => {
      let newFrameIndex = old - 1;

      const minIndex = 0;
      if (newFrameIndex < minIndex) {
        clearInterval(newIntervalId);
        newFrameIndex = minIndex;

        if ("function" === typeof onFirstFrame) {
          onFirstFrame();
        }

        updateImageSequenceVideoState({
          imageSequenceVideoKey,
          updatedState: {
            playStatus: ImageSequenceVideoPlayStatus.PAUSED,
            playIntervalId: null,
          },
        });
      }

      if (reducedVideoPauses[newFrameIndex]) {
        pause(imageSequenceVideoKey, newFrameIndex);
      }

      return newFrameIndex;
    });
  }, imtervalMs);

  updateImageSequenceVideoState({
    imageSequenceVideoKey,
    updatedState: {
      playStatus: ImageSequenceVideoPlayStatus.PLAYING_BACKWARDS,
      playIntervalId: Number(newIntervalId),
    },
  });
}

function pause(imageSequenceVideoKey: string, atFrameIndex?: number) {
  const imageSequenceVideoState = imageSequenceVideoStore.getState();
  const selectedVideoState = imageSequenceVideoState[imageSequenceVideoKey];

  if (!selectedVideoState) {
    return;
  }
  if (selectedVideoState.playStatus === ImageSequenceVideoPlayStatus.PAUSED) {
    return;
  }

  const playIntervalId = selectedVideoState.playIntervalId;

  if (playIntervalId) {
    clearInterval(playIntervalId);
  }

  const updatedState: Partial<ImageSequenceVideoState> = {
    playIntervalId: null,
    playStatus: ImageSequenceVideoPlayStatus.PAUSED,
  };

  const specifiedAtFrameIndex = "number" === typeof atFrameIndex;

  if (specifiedAtFrameIndex) {
    updatedState.activeFrameIndex = atFrameIndex;
  }

  updateImageSequenceVideoState({
    imageSequenceVideoKey,
    updatedState,
  });
}

function setActiveFrameIndex(
  imageSequenceVideoKey: string,
  activeFrameIndex: ((old: number) => number) | number,
) {
  if ("number" === typeof activeFrameIndex) {
    const updatedState: Partial<ImageSequenceVideoState> = {
      activeFrameIndex,
    };

    updateImageSequenceVideoState({
      imageSequenceVideoKey,
      updatedState,
    });
  }

  if ("function" === typeof activeFrameIndex) {
    updateImageSequenceVideoState({
      imageSequenceVideoKey,
      updatedState: {
        activeFrameIndex: activeFrameIndex(
          imageSequenceVideoStore.getState()?.[imageSequenceVideoKey]
            ?.activeFrameIndex || 0,
        ),
      },
    });
  }
}

function playTowardsClosestEdge(
  imageSequenceVideoKey: string,
  { onEdgeFrame }: { onEdgeFrame?: () => void },
) {
  const currentVideoSequence = getImageSequenceVideoByKeyOrThrow(
    imageSequenceVideoKey,
  );

  const reverseIsCloser =
    currentVideoSequence.maxFrameIndex > 0 &&
    currentVideoSequence.activeFrameIndex <
      currentVideoSequence.maxFrameIndex / 2;

  if (reverseIsCloser) {
    playBackwards(imageSequenceVideoKey, { onFirstFrame: onEdgeFrame });
  } else {
    playForward(imageSequenceVideoKey, { onLastFrame: onEdgeFrame });
  }
}

function setProgressToEdge(
  imageSequenceVideoKey: string,
  edgeType: VideoEdge,
  { ignoreIfNotFound }: { ignoreIfNotFound?: boolean } = {},
) {
  const currentVideoSequence = getImageSequenceVideoByKey(
    imageSequenceVideoKey,
  );

  if (!currentVideoSequence) {
    if (ignoreIfNotFound) {
      return;
    } else {
      throwNotFoundError(imageSequenceVideoKey);
    }
  }

  if (edgeType === VideoEdge.FINISH) {
    updateImageSequenceVideoState({
      imageSequenceVideoKey,
      updatedState: {
        activeFrameIndex: currentVideoSequence.maxFrameIndex,
      },
    });
  } else if (edgeType === VideoEdge.START) {
    updateImageSequenceVideoState({
      imageSequenceVideoKey,
      updatedState: {
        activeFrameIndex: 0,
      },
    });
  }
}

function addMomentum(
  imageSequenceVideoKey: string,
  momentumToAdd = momentumSettings.defaultMomentumToAdd,
) {
  const currentVideoSequence = getImageSequenceVideoByKeyOrThrow(
    imageSequenceVideoKey,
  );

  const { momentum } = currentVideoSequence;

  let newMomentum = (momentum || 0) + momentumToAdd;

  if (newMomentum > momentumSettings.maxMomentum) {
    newMomentum = momentumSettings.maxMomentum;
  }

  if (newMomentum < -momentumSettings.maxMomentum) {
    newMomentum = -momentumSettings.maxMomentum;
  }

  updateImageSequenceVideoState({
    imageSequenceVideoKey,
    updatedState: { momentum: newMomentum },
  });

  playWithMomentum(imageSequenceVideoKey);
}

function playWithMomentum(imageSequenceVideoKey: string) {
  const currentVideoSequence = getImageSequenceVideoByKeyOrThrow(
    imageSequenceVideoKey,
  );

  const momentum = currentVideoSequence.momentum || 0;
  const activeFrameIndex = currentVideoSequence.activeFrameIndex || 0;

  if (momentum === 0) {
    return;
  } else if (momentum > 0) {
    const { maxFrameIndex } = currentVideoSequence;

    const calcValue = () => {
      const newValue = activeFrameIndex + 1;

      if (newValue > maxFrameIndex) {
        return maxFrameIndex;
      }

      return newValue;
    };

    setActiveFrameIndex(imageSequenceVideoKey, calcValue());

    const newMomentum = Math.max(
      0,
      momentum - momentumSettings.rezistancePerPlayCall,
    );

    updateImageSequenceVideoState({
      imageSequenceVideoKey,
      updatedState: { momentum: newMomentum },
    });
  } else if (momentum < 0) {
    const calcValue = () => {
      const newValue = activeFrameIndex - 1;

      if (newValue < 0) {
        return 0;
      }

      return newValue;
    };
    setActiveFrameIndex(imageSequenceVideoKey, calcValue());

    const newMomentum = Math.min(
      0,
      momentum + momentumSettings.rezistancePerPlayCall,
    );

    updateImageSequenceVideoState({
      imageSequenceVideoKey,
      updatedState: { momentum: newMomentum },
    });
  }

  const timeoutId = setTimeout(() => {
    playWithMomentum(imageSequenceVideoKey);
  }, momentumSettings.intervalFromMomentum(momentum));

  updateImageSequenceVideoState({
    imageSequenceVideoKey,
    updatedState: { momentumTimeoutId: Number(timeoutId) },
  });
}

function cancelMomentumTimeout(imageSequenceVideoKey: string) {
  const currentVideoSequence = getImageSequenceVideoByKeyOrThrow(
    imageSequenceVideoKey,
  );

  const { momentumTimeoutId } = currentVideoSequence;

  if ("number" === typeof momentumTimeoutId) {
    clearTimeout(momentumTimeoutId);
    updateImageSequenceVideoState({
      imageSequenceVideoKey,
      updatedState: { momentumTimeoutId: null },
    });
  }
}

function stopMomentum(
  imageSequenceVideoKey: string,
  { ignoreIfNotFound }: { ignoreIfNotFound?: boolean } = {},
) {
  const currentVideoSequence = getImageSequenceVideoByKey(
    imageSequenceVideoKey,
  );

  if (!currentVideoSequence) {
    if (ignoreIfNotFound) {
      return;
    } else {
      throwNotFoundError(imageSequenceVideoKey);
    }
  }

  const timeoutId = currentVideoSequence?.momentumTimeoutId;

  if ("number" === typeof timeoutId) {
    clearTimeout(timeoutId);
  }

  updateImageSequenceVideoState({
    imageSequenceVideoKey,
    updatedState: { momentum: 0, momentumTimeoutId: null },
  });
}

const imageSequenceVideoController = {
  init,
  playForward,
  playBackwards,
  playTowardsClosestEdge,
  pause,
  setActiveFrameIndex,
  setProgressToEdge,
  addMomentum,
  stopMomentum,
};

export default imageSequenceVideoController;
