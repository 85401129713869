import React, { FC, useMemo, useRef, useState } from "react";
import { useStore } from "effector-react";
import ResizeDetector from "react-resize-detector";

import LayersOverVideo from "../LayersOverVideo";
import {
  imageSequenceVideoStore,
  updateImageSequenceVideoState,
} from "../../stores/imageSequenceVideo";
import ActiveFrame from "../ActiveFrame";
import { ResponsiveFrameGroup, VideoLayer } from "../../types";
import Drag360Processor from "../Drag360Processor";
import { findResponsiveOptionByWidth } from "utils/responsive";

export type ImageSequenceVideoPausePoint = {
  atIndex: number;
};

type ImageSequenceVideoProps = {
  isActive: boolean;
  frameGroups: ResponsiveFrameGroup[];
  layers: VideoLayer[];
  imageSequenceVideoKey: string;
  onStartReached: () => void;
  onEndReached: () => void;

  looped?: boolean;

  onReadyForAdjustingSize: () => void;
  swipeTouchDisabled?: boolean;
};

const ImageSequenceVideo: FC<ImageSequenceVideoProps> = ({
  frameGroups,
  layers,
  imageSequenceVideoKey,

  isActive,
  looped,

  onStartReached,
  onEndReached,

  onReadyForAdjustingSize,
  swipeTouchDisabled,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageSequenceVideoStates = useStore(imageSequenceVideoStore);

  // const [callbacksFired, setCallbacksFired] = useState({
  //   onStartReached: false,
  //   onEndReached: false,
  // });

  // useEffect(() => {
  //   if (isActive) {
  //     setCallbacksFired({
  //       onStartReached: false,
  //       onEndReached: false,
  //     });
  //   }
  // }, [isActive]);

  const currentState = useMemo(() => {
    return imageSequenceVideoStates[imageSequenceVideoKey];
  }, [imageSequenceVideoKey, imageSequenceVideoStates]);

  const activeFrameIndex = useMemo(() => {
    return currentState?.activeFrameIndex || 0;
  }, [currentState]);

  const shouldIgnoreEdge = useMemo(() => {
    return currentState?.ignoreEdge || false;
  }, [currentState]);

  const [maxWidth, setMaxWidth] = useState(() => window.innerWidth);

  const responsiveFrames = useMemo(() => {
    if ("number" !== typeof maxWidth) {
      return [];
    }

    return findResponsiveOptionByWidth(frameGroups, maxWidth)?.frames || [];
  }, [maxWidth, frameGroups]);

  return (
    <ResizeDetector
      handleWidth
      targetRef={wrapperRef}
      onResize={(width: undefined | number) => {
        if ("number" === typeof width) {
          setMaxWidth((old) => Math.max(old, width));
        }
      }}
    >
      <div ref={wrapperRef}>
        <Drag360Processor
          hasMomentum={false}
          looped={looped}
          //ignoreEdgeFrame={shouldIgnoreEdge}
          isActive={isActive}
          imageSequenceVideoKey={imageSequenceVideoKey}
          imagesAmount={responsiveFrames.length}
          sensitivity={0.12}
          onEndReached={onEndReached}
          onStartReached={onStartReached}
          onActiveFrameIndexChanged={(activeFrameIndex) =>
            updateImageSequenceVideoState({
              imageSequenceVideoKey,
              updatedState: {
                activeFrameIndex,
              },
            })
          }
          swipeTouchDisabled={swipeTouchDisabled}
          // onDrag={progress => {
          //   const roundedProgress = Math.round(progress)

          //   const previousIndex = imageSequenceVideoStates[imageSequenceVideoKey]?.activeFrameIndex || 0;
          //   const maxIndex = imageSequenceVideoStates[imageSequenceVideoKey]?.maxFrameIndex || 0;

          //   const newActiveIndex = previousIndex + (roundedProgress > 0 ? 1 : -1)

          //   if (newActiveIndex >= maxIndex) {
          //     onEndReached()
          //   } else if (newActiveIndex <= 0) {
          //     onStartReached()
          //   } else {

          //     updateImageSequenceVideoState({
          //       imageSequenceVideoKey, updatedState: {
          //         activeFrameIndex: previousIndex + roundedProgress
          //       }
          //     })
          //   }

          // }}
        >
          <ActiveFrame
            onReadyForAdjustingSize={onReadyForAdjustingSize}
            ignoreEdgeFrame={shouldIgnoreEdge || looped || !isActive}
            onStartReached={onStartReached}
            onEndReached={onEndReached}
            responsiveFrames={responsiveFrames}
            imageSequenceVideoKey={imageSequenceVideoKey}
          />
          <LayersOverVideo
            activeFrameOrSecond={activeFrameIndex}
            layers={layers}
          />
        </Drag360Processor>
      </div>
    </ResizeDetector>
  );
};

export default ImageSequenceVideo;
