import React, { useMemo, useRef, useState } from "react";
import clsx from "clsx";

import { ReactComponent as CheckSVG } from "./assets/check.svg";

import useOutsideClick from "hooks/useOutsideClick";

import "./styles.scss";

type LanguageOption = {
  title: string;
  key: string;
  code: string;
  iconUrl: string;
};

const LanguageSwitch = ({
  activeLanguage,
  onChangeLanguage,
  languages,
}: {
  activeLanguage: string;
  onChangeLanguage: (lng: string) => void;
  languages: LanguageOption[];
}) => {
  const buttonDivRef = useRef<HTMLButtonElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useOutsideClick(
    dropdownRef,
    () => setIsDropdownOpen(false),
    () => !isDropdownOpen,
    [buttonDivRef],
  );

  const languageIconSrc = useMemo(
    () => languages.find((data) => activeLanguage === data.code)?.iconUrl,
    [languages, activeLanguage],
  );

  return (
    <div
      className={clsx("language-switch", {
        "language-switch--open": isDropdownOpen,
        "no-options": languages.length <= 1
      })}
    >
      <button
        ref={buttonDivRef}
        className={clsx("language-switch__select")}
        onClick={() => setIsDropdownOpen(true)}
      >
        <img
          src={languageIconSrc}
          alt={activeLanguage}
          className="language-switch__flag"
        />
      </button>
      <nav className="language-switch__dropdown" ref={dropdownRef}>
        {languages.map((language) => {
          const activeItem = activeLanguage === language.code;
          return (
            <button
              onClick={() => {
                !activeItem && onChangeLanguage(language.key);
                setIsDropdownOpen(false);
              }}
              className={clsx("language-switch__dropdown-item", {
                "language-switch__dropdown-item--active":
                  activeLanguage === language.key,
              })}
              key={language.key}
            >
              <span className="language-switch__dropdown-title">
                {language.title}
              </span>
              {activeItem && (
                <CheckSVG className="language-switch__dropdown-check" />
              )}
            </button>
          );
        })}
      </nav>
    </div>
  );
};

export default LanguageSwitch;
