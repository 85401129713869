import React, { FC, useRef, useEffect, useState, useCallback } from "react";
import Transition from "react-transition-group/Transition";
import clsx from "clsx";

import { getScrollDirection } from "utils/uiEvents";

type CustomBlockProps = {
  isActive: boolean;
  isPrevious: boolean;
  onEndReached?: () => void;
  onStartReached?: () => void;
  className?: string;
};

export const CustomBlock: FC<CustomBlockProps> = ({
  isActive,
  isPrevious,
  onEndReached,
  onStartReached,
  className,
  children,
}) => {
  const customBlockRef = useRef<HTMLDivElement>(null);
  const [startIsReady, setStartIsReady] = useState<boolean>(false);
  const [endIsReady, setEndIsReady] = useState<boolean>(false);
  const [pause, setPause] = useState<boolean>(false);
  const scrollTimeout = 300;

  useEffect(() => {
    if (isActive && customBlockRef.current) {
      customBlockRef.current.scrollTop = 0;
    }
  }, [isActive]);

  useEffect(() => {
    if (
      isActive &&
      customBlockRef.current &&
      customBlockRef.current.scrollTop === 0
    ) {
      setStartIsReady(true);
    }
  }, [isActive]);

  return (
    <Transition timeout={15} in={isActive}>
      {(state) => (
        <div
          ref={customBlockRef}
          className={clsx(state, className, {
            block: true,
            "block--custom": true,
            transparent: !(isActive || isPrevious),
            active: isActive,
            previous: isPrevious,
          })}
          onWheel={(e) => {
            if (!isActive) {
              return;
            }
            if (customBlockRef.current) {
              const direction = getScrollDirection(e);

              if (direction === "down" && onEndReached) {
                if (
                  customBlockRef.current.offsetHeight +
                    customBlockRef.current.scrollTop >=
                  customBlockRef.current.scrollHeight
                ) {
                  if (!endIsReady) {
                    if (pause) return;
                    setPause(true);
                    setTimeout(() => {
                      setEndIsReady(true);
                      setPause(false);
                    }, scrollTimeout);
                  } else {
                    onEndReached();
                  }
                } else {
                  setEndIsReady(false);
                }
              } else if (direction === "up" && onStartReached) {
                if (customBlockRef.current.scrollTop === 0) {
                  if (!startIsReady) {
                    if (pause) return;
                    setPause(true);
                    setTimeout(() => {
                      setStartIsReady(true);
                      setPause(false);
                    }, scrollTimeout);
                  } else {
                    onStartReached();
                  }
                } else {
                  setStartIsReady(false);
                }
              }
            }
          }}
        >
          <div className={clsx("block__inner")}>{children}</div>
        </div>
      )}
    </Transition>
  );
};

export default CustomBlock;
