import React, {
  FC,
  useEffect,
  useMemo,
  //useRef,
  useState,
} from "react";
import { useStore } from "effector-react";

import { imageSequenceVideoStore } from "../../stores/imageSequenceVideo";
import Frames from "../Frames";
import { ResponsiveFrame } from "../../types";

type ActiveFrameProps = {
  imageSequenceVideoKey: string;
  responsiveFrames: ResponsiveFrame[];

  onStartReached: () => void;
  onEndReached: () => void;

  ignoreEdgeFrame?: boolean;

  onReadyForAdjustingSize: () => void;
};

const ActiveFrame: FC<ActiveFrameProps> = ({
  imageSequenceVideoKey,
  responsiveFrames,
  onStartReached,
  onEndReached,
  ignoreEdgeFrame,
  onReadyForAdjustingSize,
}) => {
  const maxFrameIndex = responsiveFrames.length - 1;

  const imageSequenceVideoStates = useStore(imageSequenceVideoStore);

  const currentState = useMemo(
    () => imageSequenceVideoStates[imageSequenceVideoKey],
    [imageSequenceVideoStates, imageSequenceVideoKey]
  );

  const activeFrameIndex = currentState?.activeFrameIndex || 0;

  const [wasMovedOrPlayed, setWasMovedOrPlayed] = useState(false);

  // const playing = useMemo(() => {
  //   if (!currentState) {
  //     return false;
  //   }

  //   if (currentState.playStatus === ImageSequenceVideoPlayStatus.PAUSED) {
  //     return false;
  //   }

  //   return !!currentState.playIntervalId;
  // }, [currentState]);

  useEffect(() => {
    if (ignoreEdgeFrame) {
      return;
    }
    if (!wasMovedOrPlayed) {
      return;
    }

    if (activeFrameIndex <= 0) {
      setWasMovedOrPlayed(false);
      onStartReached();
    }

    if (activeFrameIndex >= maxFrameIndex) {
      setWasMovedOrPlayed(false);
      onEndReached();
    }
  }, [activeFrameIndex, wasMovedOrPlayed, maxFrameIndex, ignoreEdgeFrame]);

  useEffect(() => {
    if (activeFrameIndex !== 0 && activeFrameIndex !== maxFrameIndex) {
      setWasMovedOrPlayed(true);
    }
  }, [activeFrameIndex, maxFrameIndex]);

  return (
    <Frames
      onReadyForAdjustingSize={onReadyForAdjustingSize}
      activeFrameIndex={activeFrameIndex}
      responsiveFrames={responsiveFrames}
    />
  );
};

export default ActiveFrame;
