import clsx from "clsx";
import React, { FC, ReactNode, useState, WheelEvent } from "react";
import { Swipeable } from "react-swipeable";
import { getScrollDirection } from "utils/uiEvents";

type Step = {
  component: ReactNode;
  key: string;
};

type StepsLayerProps = {
  initialStepIndex?: number;
  steps: Step[];
};

const StepsLayer: FC<StepsLayerProps> = ({ initialStepIndex = 0, steps }) => {
  const maxStepIndex = steps.length - 1;
  const [stepIndex, setStepIndex] = useState(initialStepIndex);

  const internalNext = stepIndex < maxStepIndex;
  const internalPrevious = stepIndex > 0;

  const onNext = (e: WheelEvent | MouseEvent | TouchEvent) => {
    if (internalNext) {
      e.stopPropagation();
    }

    setStepIndex((old) => {
      const newValue = Math.min(maxStepIndex, old + 1);
      return newValue;
    });
  };

  const onPrevious = (e: WheelEvent | MouseEvent | TouchEvent) => {
    if (internalPrevious) {
      e.stopPropagation();
    }

    setStepIndex((old) => {
      const newValue = Math.max(0, old - 1);
      return newValue;
    });
  };

  return (
    <div
      onWheel={(e) => {
        e.preventDefault();

        const direction = getScrollDirection(e);

        if (direction === "down") {
          onNext(e);
        } else if (direction === "up") {
          onPrevious(e);
        }
      }}
    >
      <Swipeable
        trackTouch
        trackMouse
        onSwipedDown={(e) => {
          onPrevious(e.event);
        }}
        onSwipedUp={(e) => {
          onNext(e.event);
        }}
      >
        {steps.map(({ key, component }, index) => {
          return (
            <div
              key={key}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              className={clsx({
                active: index === stepIndex,
                transparent: index !== stepIndex,
                "step-layer": true,
              })}
            >
              {component}
            </div>
          );
        })}
      </Swipeable>
    </div>
  );
};

export default StepsLayer;
