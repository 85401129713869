import React, { FC, Fragment } from "react";
import { useStore } from "effector-react";

import Header from "components/Header";

import config from "../config";
import { containerStore, updateContainerState } from "../stores/container";
import { updateTabState } from "stores/tabs";

import "assets/styles/_general.scss";
import htmlVideoController from "controllers/htmlVideo.controller";
import containerController from "controllers/containerController";
import imageSequenceVideoController from "controllers/imageSequenceVideo.controller";
import OverallProgress from "./OverallProgress";
import { stackedBlockStore } from "stores/stackedBlock";
import stackedBlockController from "controllers/stackedBlock.controller";

type AppProps = {
  expandHeader: boolean;
};

const App: FC<AppProps> = ({ expandHeader }) => {
  const states = useStore(containerStore);
  const myContainer = states["1_container"];
  const currentBlock = myContainer?.currentBlock;

  const stackedBlocksStates = useStore(stackedBlockStore);
  const mediaLibraryAndContactFormState =
    stackedBlocksStates["media_library&contact_form"];

  const overMediaLibrary = mediaLibraryAndContactFormState?.index === 0;
  const overContactForm = mediaLibraryAndContactFormState?.index === 1;

  return (
    <>
      <Header
        expanded={expandHeader}
        items={[
          {
            key: "0",
            isActive: currentBlock === "360",
            title: "Overview",
            onClick: () => {
              containerController.pauseActiveBlock("1_container");

              updateContainerState({
                containerKey: "1_container",
                updatedState: {
                  currentBlock: "360",
                },
              });

              imageSequenceVideoController.setActiveFrameIndex("360", 0);
            },
          },
          {
            key: "1",
            isActive: !!currentBlock && currentBlock.startsWith("Mod01_"),
            onClick: () => {
              containerController.pauseActiveBlock("1_container");

              updateContainerState({
                containerKey: "1_container",
                updatedState: {
                  currentBlock: "Mod01_USP01a",
                },
              });

              htmlVideoController.playForward("Mod01_USP01a", {
                fromStart: true,
              });
            },
            title: "Explore the Device",
          },
          {
            key: "2",
            isActive: !!currentBlock && currentBlock.startsWith("Mod02_"),
            onClick: () => {
              containerController.pauseActiveBlock("1_container");

              updateContainerState({
                containerKey: "1_container",
                updatedState: {
                  currentBlock: "Mod02_USP01",
                },
              });

              htmlVideoController.playForward("Mod02_USP01", {
                fromStart: true,
              });
            },
            title: "Try it out",
          },
          {
            key: "3",
            isActive: !!currentBlock && currentBlock.startsWith("Mod03_"),
            onClick: () => {
              containerController.pauseActiveBlock("1_container");

              updateContainerState({
                containerKey: "1_container",
                updatedState: {
                  currentBlock: "Mod03_USP01",
                },
              });

              htmlVideoController.playForward("Mod03_USP01", {
                fromStart: true,
              });

              updateTabState({
                tabKey: "connectivity",
                updatedState: {
                  currentTab: 0,
                },
              });
            },
            title: "Connectivity",
          },
          {
            key: "4",
            isActive:
              currentBlock === "media_library&contact_form" && overMediaLibrary,
            onClick: () => {
              containerController.pauseActiveBlock("1_container");
              stackedBlockController.scrollToIndex(
                "media_library&contact_form",
                0,
                {
                  withAnimation: currentBlock === "media_library&contact_form",
                },
              );
              containerController.setActiveBlock(
                "1_container",
                "media_library&contact_form",
              );
            },
            title: "Media library",
          },
          {
            key: "5",
            isActive:
              currentBlock === "media_library&contact_form" && overContactForm,
            onClick: () => {
              containerController.pauseActiveBlock("1_container");
              stackedBlockController.scrollToIndex(
                "media_library&contact_form",
                1,
                {
                  withAnimation: currentBlock === "media_library&contact_form",
                },
              );
              containerController.setActiveBlock(
                "1_container",
                "media_library&contact_form",
              );
            },
            title: "Get in touch",
          },
        ]}
      />
      {config.screens.map((screen) => {
        return <Fragment key={screen.key}>{screen.component}</Fragment>;
      })}
      <OverallProgress
        visible={
          currentBlock?.match(
            /^(?!Mod01_USP4_Outro_Contact_button__frame).*Mod01_+.*\w/,
          )
            ? true
            : false
        }
        steps={[
          {
            key: "1",
            isActive: [
              "Mod01_USP01a",
              "Mod01_USP01a__Mod01_USP01b-d__frame",
            ].includes(currentBlock),
          },
          {
            key: "2",
            isActive: [
              "Mod01_USP01b-d",
              "Mod01_USP01b-d__Mod01_USP01e1__frame",
            ].includes(currentBlock),
          },
          {
            key: "3",
            isActive: ["Mod01_USP01b-d__Mod01_USP01e2__frame"].includes(
              currentBlock,
            ),
          },
          {
            key: "4",
            isActive: ["Mod01_USP01b-d__Mod01_USP01e3__frame"].includes(
              currentBlock,
            ),
          },
          {
            key: "5",
            isActive: [
              "Mod01_USP01e",
              "Mod01_USP01e__Mod01_USP02a__frame",
            ].includes(myContainer?.currentBlock),
          },
          {
            key: "6",
            isActive: [
              "Mod01_USP02a",
              "Mod01_USP02a__Mod01_USP02b__frame",
            ].includes(myContainer?.currentBlock),
          },
          {
            key: "7",
            isActive: [
              "Mod01_USP02b",
              "Mod01_USP02b__Mod01_USP02c-d__frame",
            ].includes(currentBlock),
          },
          {
            key: "8",
            isActive: [
              "Mod01_USP02c-d",
              "Mod01_USP02c-d__Mod01_USP03a__frame",
            ].includes(currentBlock),
          },
          {
            key: "9",
            isActive: [
              "Mod01_USP03a",
              "Mod01_USP03a__Mod01_USP4_Outro__frame",
            ].includes(currentBlock),
          },
          {
            key: "10",
            isActive: [
              "Mod01_USP03a__Mod01_USP4_Outro2__frame",
              "Mod01_USP4_Outro",
            ].includes(currentBlock),
          },
        ]}
      />
      <OverallProgress
        visible={
          currentBlock?.match(/^(?!Mod02_USP01).*Mod02_+.*\w/) ? true : false
        }
        steps={[
          {
            key: "1",
            isActive: ["Mod02_USP02", "Mod02_USP02_Scan"].includes(
              currentBlock,
            ),
          },
          {
            key: "2",
            isActive: ["Mod02_USP03", "Mod02_USP03_Scan"].includes(
              currentBlock,
            ),
          },
          {
            key: "3",
            isActive: ["Mod02_USP04", "Mod02_USP04_Scan"].includes(
              myContainer?.currentBlock,
            ),
          },
          {
            key: "4",
            isActive: ["Mod02_USP05", "Mod02_USP05_Scan"].includes(
              myContainer?.currentBlock,
            ),
          },
          {
            key: "5",
            isActive: ["Mod02_USP06", "Mod02_USP06_Scan"].includes(
              myContainer?.currentBlock,
            ),
          },
          {
            key: "6",
            isActive: ["Mod02_USP07__Mod02_USP08__frame"].includes(
              currentBlock,
            ),
          },
          {
            key: "7",
            isActive: ["Mod02_USP08__Mod02_USP09__frame"].includes(
              currentBlock,
            ),
          },
          {
            key: "8",
            isActive: ["Mod02_USP09__Mod02_USP10__frame"].includes(
              currentBlock,
            ),
          },
          {
            key: "9",
            isActive: ["Mod02_USP10__Mod02_USP11__frame"].includes(
              currentBlock,
            ),
          },
          {
            key: "10",
            isActive: ["Mod02_USP11__Mod02_USP12__frame"].includes(
              currentBlock,
            ),
          },
          {
            key: "11",
            isActive: [
              "Mod02_USP12_Intro",
              "Mod02_USP12_Intro__Mod02_USP12_Outro__frame",
            ].includes(currentBlock),
          },
        ]}
      />
    </>
  );
};

export default App;
