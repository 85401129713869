import React, { FC, useRef, useState } from "react";
import { useStore } from "effector-react";

import { containerStore } from "../../stores/container";
import BlockComponent, { FlowDirection } from "../Block";
import { ReducedBlocks } from "types";
import Screen from "components/Screen";
import ReactResizeDetector from "react-resize-detector";

export type ContainerProps = {
  blocks: ReducedBlocks;
  containerKey: string;
  initialBlockKey: string;
};

function shouldAddBlock(blockKey: string, lastActiveBlocks: string[]): boolean {
  const alreadyLastActiveBlock = lastActiveBlocks[0] === blockKey;

  const shouldAdd = !alreadyLastActiveBlock;

  return shouldAdd;
}

const Container: FC<ContainerProps> = ({
  blocks,
  containerKey,
  initialBlockKey,
}) => {
  const [flowDirection, setFlowDirection] = useState<FlowDirection>("forward");

  const screenDivRef = useRef<HTMLDivElement>(null);
  const [wrapperSize, setWrapperSize] = useState({
    width: 0,
    height: 0,
  });

  const containerStates = useStore(containerStore);

  const currentBlock =
    containerStates[containerKey]?.currentBlock || initialBlockKey;

  const [
    /* 
    current active block is first (lastActiveBlocks[0]).
    previous is second (lastActiveBlocks[1]) 
    */
    lastActiveBlocks,
    setLastActiveBlocks,
  ] = useState<string[]>(() => [currentBlock]);

  if (!currentBlock) {
    return null;
  }

  // not using useEffect for this because useEffect works with a little lag (You can see it with naked eye when browser is busy, for example)
  if (shouldAddBlock(currentBlock, lastActiveBlocks)) {
    setLastActiveBlocks((old) => {
      const newValueWithDupes = [currentBlock, ...old];
      const newValueWithoutDupes = [...new Set(newValueWithDupes)];
      return newValueWithoutDupes;
    });
  }

  const previousActiveBlockKey = lastActiveBlocks[1] || null;
  const nextActiveBlockKey = blocks[currentBlock]
    ? blocks[currentBlock].previousBlockKey === previousActiveBlockKey
      ? blocks[currentBlock].nextBlockKey
      : blocks[currentBlock].previousBlockKey
    : null;

  /*
  console.log(
    "current: " +
      currentBlock +
      " - prev: " +
      previousActiveBlockKey +
      " - next: " +
      nextActiveBlockKey,
  );
  */

  return (
    <ReactResizeDetector
      targetRef={screenDivRef}
      handleHeight
      handleWidth
      onResize={(width: undefined | number, height: undefined | number) => {
        if (typeof height === "number" && typeof width === "number") {
          setWrapperSize({
            height,
            width,
          });
        }
      }}
    >
      <Screen containerKey={containerKey} blocks={blocks} ref={screenDivRef}>
        {Object.keys(blocks).map((blockKey) => {
          return (
            <BlockComponent
              wrapperSize={wrapperSize}
              key={blockKey}
              isPrevious={blockKey === previousActiveBlockKey}
              isNext={blockKey === nextActiveBlockKey}
              isActive={blockKey === currentBlock}
              blocks={blocks}
              blockKey={blockKey}
              containerKey={containerKey}
              flowDirection={flowDirection}
              onFlowDirectionChanged={(direction: FlowDirection) =>
                setFlowDirection(direction)
              }
            />
          );
        })}
      </Screen>
    </ReactResizeDetector>
  );
};

export default Container;
