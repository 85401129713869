import { AxiosResponse } from "axios";
import { Config } from "types";

import { axiosInstance } from "utils/axiosInstance";
import { apiRoutes } from "utils/routing";

function get(): Promise<AxiosResponse<Config>> {
  return axiosInstance.get(apiRoutes.getConfig.toPath({}));
}

const configService = {
  get,
};

export default configService;
