import React, { ComponentProps, FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import "./styles.scss";

type HotspotProps = {
  title: string;
  onClick: ComponentProps<"button">["onClick"];
  height: number;
};

const Hotspot: FC<HotspotProps> = ({ height, onClick, title }) => {
  const { t } = useTranslation();
  const hotspotLabelRef = useRef<HTMLButtonElement>(null);
  const [direction, setDirection] = useState<"left" | "right">("right");

  const edgeIndentPercent = 4.5;
  const maxHotspotSize = 28;
  const minHotspotSize = 20;

  useEffect(() => {
    if (hotspotLabelRef.current) {
      const hotspotRect = hotspotLabelRef.current.getBoundingClientRect();
      const absoluteEdgeIndent = (edgeIndentPercent * window.innerWidth) / 100;

      if (hotspotRect.right + absoluteEdgeIndent > window.innerWidth) {
        setDirection("left");
      } else {
        setDirection("right");
      }
    }
  }, []);

  const getHotspotSize = () => {
    return Math.max(minHotspotSize, Math.min(height / 2, maxHotspotSize));
  };

  const getSpanStyle = () => {
    const style: ComponentProps<"span">["style"] = {};

    style.marginLeft = getHotspotSize() / 2 - 8;

    if ("left" === direction) {
      style.marginLeft *= -1;
    }

    return style;
  };

  return (
    <button
      className={clsx("hotspot", `hotspot--${direction}`)}
      onClick={onClick}
      style={{
        height: getHotspotSize(),
        width: getHotspotSize(),
      }}
    >
      <span
        className="hotspot__label"
        style={getSpanStyle()}
        ref={hotspotLabelRef}
      >
        {t(title)}
      </span>
    </button>
  );
};

export default Hotspot;
