import clsx from "clsx";
import React, { FC } from "react";
import { ReactComponent as SvgLogo } from "./assets/logo.svg";

import "./styles.scss";

type HeaderLogoProps = {
  visible: boolean;
  variant?: "light" | "dark";
};

const HeaderLogo: FC<HeaderLogoProps> = ({ visible, variant = "dark" }) => {
  return (
    <button className={clsx("header-logo", { visible })}>
      <SvgLogo
        className="header-logo__image"
        fill={variant === "dark" ? "#00053a" : "#ffffff"}
      />
    </button>
  );
};

export default HeaderLogo;
