import { compile } from "path-to-regexp";

export class Path {
  url = "";
  toPath: Function = () => "";

  constructor(url: string) {
    this.url = url;
    this.toPath = compile(url, { encode: encodeURIComponent });
  }
}

export const apiRoutes = {
  getConfig: new Path("./config/config.json"),
};
