import React, { FC, useMemo, SetStateAction, useState, useEffect } from "react";
import clsx from "clsx";

import SingleFrameWrapper from "components/SingleFrameWrapper";
import LayersOverVideo from "components/LayersOverVideo";

import { Layer, ResponsiveImageFadeGroup } from "types";
import { FlowDirection } from "components/Block";
import { findResponsiveOptionByWidth } from "utils/responsive";

import "./styles.scss";

type ImageFadeBlockProps = {
  frameGroup: ResponsiveImageFadeGroup[];
  isActive: boolean;
  onEndReached: () => void;
  onStartReached: () => void;
  layers: Layer[];
  onReadyForAdjustingSize: () => void;
  fadeDuration: number;
  fadeTransition?: string;

  lastDirection: FlowDirection;
};

/* Based on SingleFrameWrapper */
const ImageFadeBlock: FC<ImageFadeBlockProps> = ({
  frameGroup,
  isActive,
  onEndReached,
  onStartReached,
  layers,
  onReadyForAdjustingSize,
  fadeDuration,
  fadeTransition,
  lastDirection,
}) => {
  const startTransition = isActive;

  const [maxWidth, setMaxWidth] = useState<number>(0);

  const [transitionInProgress, setTransitionInProgress] = useState<boolean>(
    false,
  );

  const convertedLayers = useMemo(
    () => layers.map((layer) => ({ ...layer, from: 0, to: 0 })),
    [layers],
  );

  const startImgProps = useMemo(() => {
    const current = findResponsiveOptionByWidth(frameGroup, maxWidth);

    if (lastDirection === "forward") {
      return current?.images?.[0]?.imgProps;
    } else if (lastDirection === "backwards") {
      return current?.images?.[2]?.imgProps;
    }
  }, [maxWidth, frameGroup, lastDirection]);

  const endImgProps = useMemo(() => {
    const current = findResponsiveOptionByWidth(frameGroup, maxWidth);

    return current?.images?.[1]?.imgProps;
  }, [maxWidth, frameGroup, lastDirection]);

  useEffect(() => {
    if (!startTransition) {
      return;
    }

    setTransitionInProgress(true);

    const timeoutId = setTimeout(() => {
      setTransitionInProgress(false);
    }, 1000 * fadeDuration);

    return () => {
      clearTimeout(timeoutId);
      setTransitionInProgress(false);
    };
  }, [startTransition, fadeDuration]);

  return (
    <SingleFrameWrapper
      isActive={isActive}
      onEndReached={() => {
        if (!transitionInProgress && isActive) {
          //console.log("onEndReached");
          onEndReached();
        }
      }}
      onStartReached={() => {
        if (!transitionInProgress && isActive) {
          //console.log("onStartReached");
          onStartReached();
        }
      }}
      onResizeDetected={(width: SetStateAction<number>) => setMaxWidth(width)}
    >
      <div className="image-fade-block">
        {startImgProps && endImgProps && (
          <>
            <img
              alt=""
              {...startImgProps}
              onLoad={() => {
                onReadyForAdjustingSize();
              }}
              onError={() => {
                onReadyForAdjustingSize();
              }}
              className={clsx("image-fade-block__start-image", fadeTransition, {
                "image-fade-block__start-image--active": true,
              })}
              style={{
                transitionDuration: `${fadeDuration}s`,
              }}
            />
            <img
              alt=""
              {...endImgProps}
              onLoad={() => {
                onReadyForAdjustingSize();
              }}
              onError={() => {
                onReadyForAdjustingSize();
              }}
              className={clsx("image-fade-block__end-image", fadeTransition, {
                "image-fade-block__end-image--active": startTransition,
              })}
              style={{
                transitionDuration: `${fadeDuration}s`,
              }}
            />
          </>
        )}
        {layers && (
          <div
            className={clsx("image-fade-block__layers", {
              "image-fade-block__layers--active": isActive,
            })}
          >
            <LayersOverVideo activeFrameOrSecond={0} layers={convertedLayers} />
          </div>
        )}
      </div>
    </SingleFrameWrapper>
  );
};

export default ImageFadeBlock;
