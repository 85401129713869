import { ImageSequenceVideoPausePoint } from "components/ImageSequenceVideo";
import { ComponentProps, ReactNode } from "react";

type TranslationObject = {
  [key: string /* key (get_in_touch, ...) */]: TranslationObject | string;
};

export type Config = {
  i18n: {
    [key: string /* language (ru, en, ...) */]: {
      name: string;
      iconUrl: string;
      translations: TranslationObject;
    };
  };
};

export type BlockRatioSnapshot = {
  accuracy: number; // don't update when scaling down. plan is to use hypotenuse as accuracy
  ratio: number;
};

export enum VideoDirection {
  FORWARD = "FORWARD",
  REVERSE = "REVERSE",
}

export enum KeyCode {
  UP = "ArrowUp",
  DOWN = "ArrowDown",
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",
}

export type CommonResponsiveComponent = {
  inclusiveFrom: number | null; // width
  nonInclusiveTo: number | null; // width
};

export type Key = number | string;

type Keyed = { key: Key };

export type ResponsiveVideoSource = Keyed & {
  sourceProps: ComponentProps<"source">;
};

export type ResponsiveSingleFrame = CommonResponsiveComponent & {
  imgProps: ComponentProps<"img">;
};

export type ResponsiveVideoSourceGroup = Keyed &
  CommonResponsiveComponent & {
    sources: ResponsiveVideoSource[];
  };

export type ResponsiveFrame = Keyed & {
  imgProps: ComponentProps<"img">;
};

export type ResponsiveImageFadeGroup = Keyed &
  CommonResponsiveComponent & {
    images: {
      imgProps: ComponentProps<"img">;
    }[];
  };

export type ResponsivePoster = CommonResponsiveComponent & {
  src: string;
};

export type ResponsiveFrameGroup = Keyed &
  CommonResponsiveComponent & {
    frames: ResponsiveFrame[];
  };

export enum BlockAction {
  STOP_ON_CURRENT = "STOP_ON_CURRENT",
  STOP_ON_NEXT = "STOP_ON_NEXT",
  PLAY_NEXT = "PLAY_NEXT",
  IGNORE = "IGNORE",
}

export type CommonSearchParams = {
  language?: string;
  hidelanguageoption?: string;
};

export enum BlockType {
  STACKED = "STACKED",
  CUSTOM = "CUSTOM",
  SINGLE_FRAME = "SINGLE_FRAME",
  HTML_VIDEO = "HTML_VIDEO",
  IMAGE_SEQUENCE = "IMAGE_SEQUENCE",
  IMAGE_FADE = "IMAGE_FADE",
}

// export enum GestureListener {
//     DRAG = 'DRAG',
//     SCROLL = 'SCROLL',
// }

export type Layer = {
  key: number | string;
  wrapperDivProps: ComponentProps<"div">;
  component: React.ReactNode;
};

export type VideoLayer = Layer & {
  from: number;
  to: number;
};

export type CommonBlock = {
  blockKey: string;
  onStartReached: BlockAction;
  onEndReached: BlockAction;
  nextBlockKey: string | null;
  previousBlockKey: string | null;
  wrapper?: ReactNode;
};

export type ForwardOrReverseVideoData = {
  fallback: string;
  videoProps: ComponentProps<"video">;
  sourceGroups: ResponsiveVideoSourceGroup[];
  postersGroup: ResponsivePoster[];
};

export type SingleFrameBlock = CommonBlock & {
  blockType: BlockType.SINGLE_FRAME;
  frameGroup: ResponsiveSingleFrame[];
  layers: Layer[];
};

export type HtmlVideoBlock = CommonBlock & {
  blockType: BlockType.HTML_VIDEO;
  layers: VideoLayer[];
  videos: {
    forward: null | ForwardOrReverseVideoData;
    backwards: null | ForwardOrReverseVideoData;
  };
  //pauses: VideoPausePoint[];
  videoKey: string;
};

export enum VideoEdge {
  START = "START",
  FINISH = "FINISH",
}

export type ImageSequenceBlock = CommonBlock & {
  blockType: BlockType.IMAGE_SEQUENCE;
  layers: VideoLayer[];
  pauses: ImageSequenceVideoPausePoint[];
  frameGroups: ResponsiveFrameGroup[];
  imageSequenceVideoKey: string;
  looped?: boolean;
  swipeTouchDisabled?: boolean;
};

export type ImageFadeBlock = CommonBlock & {
  blockType: BlockType.IMAGE_FADE;
  fadeDuration: number;
  fadeTransition?: string;
  layers: Layer[];
  frameGroup: ResponsiveImageFadeGroup[];
};

type CustomBlock = {
  previousBlockKey: string;
  nextBlockKey: string | null;

  blockKey: string;
  blockType: BlockType.CUSTOM;
  component: ReactNode;

  onStartReached?: BlockAction;
  onEndReached?: BlockAction;
  className?: string;
};

export type StackedBlockStackItem = {
  key: string;
  component: ReactNode;
};

type StackedBlock = CommonBlock & {
  blockType: BlockType.STACKED;
  stack: StackedBlockStackItem[];
  className?: string;
};

export type Block =
  | ImageSequenceBlock
  | HtmlVideoBlock
  | CustomBlock
  | SingleFrameBlock
  | ImageFadeBlock
  | StackedBlock;

export type ReducedBlocks = {
  [key: string]: Block;
};

export type WidthAndHeight = {
  width: number;
  height: number;
};
export type ContainerSize = WidthAndHeight;
