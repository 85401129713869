import { Config } from "types";

export function getLanguagesFromConfig(config: Config) {
  return Object.entries(config.i18n).map(([key, data]) => {
    const { name, iconUrl } = data;

    return {
      key,
      name,
      iconUrl,
    };
  });
}
