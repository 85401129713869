import { ContainerSize } from "types";

export function calculateInnerBlockSizeToCoverContainerFromRatio(
  outerSize: ContainerSize,
  innerBlockRatio: number,
) {
  if (
    "object" !== typeof outerSize ||
    (outerSize.width || 0) <= 0 ||
    (outerSize.height || 0) <= 0 ||
    (innerBlockRatio || 0) <= 0
  ) {
    return {
      width: 0,
      height: 0,
    };
  }

  const outerRatio = outerSize.width / outerSize.height;

  if (outerRatio === innerBlockRatio) {
    return outerSize;
  }

  if (outerRatio > innerBlockRatio) {
    return {
      width: outerSize.width,
      height: outerSize.width / innerBlockRatio,
    };
  }

  if (outerRatio < innerBlockRatio) {
    return {
      height: outerSize.height,
      width: outerSize.height * innerBlockRatio,
    };
  }

  throw new Error(
    `How did that even happen?... outerSize=${JSON.stringify(
      outerSize,
    )}, innerBlockRatio=${JSON.stringify(innerBlockRatio)}`,
  );
}

export function calculateInnerBlockSizeToCoverContainer(
  outerSize: ContainerSize,
  innerBlockSize: ContainerSize,
) {
  if (
    "object" !== typeof innerBlockSize ||
    (innerBlockSize.width || 0) <= 0 ||
    (innerBlockSize.height || 0) <= 0
  ) {
    return {
      width: 0,
      height: 0,
    };
  }

  const innerBlockRatio = innerBlockSize.width / innerBlockSize.height;

  return calculateInnerBlockSizeToCoverContainerFromRatio(
    outerSize,
    innerBlockRatio,
  );
}
