import clsx from "clsx";
import React, { FC } from "react";

import "./styles.scss";

type ProgressStep = {
  key: string;
  isActive: boolean;
};

type OverallProgressProps = {
  visible: boolean;
  steps: ProgressStep[];
};

const OverallProgress: FC<OverallProgressProps> = ({
  steps,
  visible = false,
}) => {
  return (
    <div className={clsx("overall-progress", { hide: !visible })}>
      {steps.map((step) => (
        <span
          className={clsx("overall-progress__item", {
            "overall-progress__item--active": step.isActive,
          })}
          key={step.key}
        />
      ))}
    </div>
  );
};

export default OverallProgress;
