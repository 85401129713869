import { updateVideoState, VideoState, videoStore } from "../stores/video";
import { VideoDirection, VideoEdge } from "../types";
import { videoEdgeTolerance, withinTolerance } from "../utils/commonVideo";

function onForwardTimeUpdate(
  videoElement: HTMLVideoElement,
  e: Event,
  reverseRef: HTMLVideoElement | undefined | null,
  videoKey: string,
  nextPause: number
) {
  const states = videoStore.getState();
  const videoState = states[videoKey];

  if (videoState?.direction === VideoDirection.FORWARD) {
    const currentSecond = videoElement.currentTime;

    if (reverseRef) {
      try {
        reverseRef.currentTime = reverseRef.duration - currentSecond;
      } catch (e) {
        console.error(e);
      }
    }

    updateVideoState({
      videoKey,
      updatedState: {
        currentSecond,
      },
    });

    if (currentSecond >= nextPause && nextPause !== Infinity) {
      pause(videoKey /* , { at: nextPause } */);
    }
  }
}

function onBackwardsTimeUpdate(
  videoElement: HTMLVideoElement,
  e: Event,
  forwardRef: HTMLVideoElement | undefined | null,
  videoKey: string,
  nextPause: number
) {
  const states = videoStore.getState();
  const videoState = states[videoKey];

  if (videoState?.direction === VideoDirection.REVERSE) {
    const currentSecond = videoElement.duration - videoElement.currentTime;

    if (forwardRef) {
      try {
        forwardRef.currentTime = currentSecond;
      } catch (e) {
        console.error(e);
      }
    }

    updateVideoState({
      videoKey,
      updatedState: {
        currentSecond,
      },
    });

    if (currentSecond <= nextPause && nextPause !== Infinity) {
      pause(videoKey /* , { at: nextPause } */);
    }
  }
}

function initForward(videoKey: string, forwardRef: HTMLVideoElement) {
  updateVideoState({ videoKey, updatedState: { forwardRef } });
}

function unmountForward(videoKey: string) {
  updateVideoState({ videoKey, updatedState: { forwardRef: null } });
}

function initBackwards(videoKey: string, reverseRef: HTMLVideoElement) {
  updateVideoState({ videoKey, updatedState: { reverseRef } });
}

function init(videoKey: string, initialData: Partial<VideoState>) {
  updateVideoState({
    videoKey,
    updatedState: initialData,
  });
}

function unmountBackwards(videoKey: string) {
  updateVideoState({ videoKey, updatedState: { forwardRef: null } });
}

function playForward(
  videoKey: string,
  {
    fromStart,
    ignoreIfAtTheEnd,
  }: { fromStart?: boolean; ignoreIfAtTheEnd?: boolean } = {}
) {
  const states = videoStore.getState();

  const videoState = states[videoKey];

  if (!videoState) {
    throw new Error(`State for HTML video ${videoKey} was not found`);
  }
  if (!videoState?.forwardRef) {
    throw new Error(`forwardRef for HTML video ${videoKey} was not found`);
  }

  const isPaused = videoState.forwardRef.paused;
  const isPlaying = !isPaused;
  if (isPlaying && VideoDirection.FORWARD === videoState.direction) {
    /* already playing forward */ return;
  }

  if (ignoreIfAtTheEnd) {
    if (
      withinTolerance({
        a: videoState.forwardRef.duration,
        b: videoState.forwardRef.currentTime,
        tolerance: videoEdgeTolerance,
      })
    ) {
      return;
    }
  }

  if (!Array.isArray(videoState.forwardPauses)) {
    throw new Error(`${videoKey}: videoState.forwardPauses is not an array...`);
  }

  const currentTime = videoState.forwardRef.currentTime;
  const nextPause = videoState.forwardPauses.reduce((acc: number, curr) => {
    const previousDiff = Math.abs(acc - currentTime);
    const currentDiff = Math.abs(curr.at - currentTime);

    if (curr.at > currentTime && currentDiff < previousDiff) {
      acc = curr.at;
    }

    return acc;
  }, Infinity);

  if (videoState.reverseRef) {
    if (!videoState.reverseRef.paused) {
      videoState.reverseRef.pause();
    }
  }

  if (videoState.onForwardTimeUpdateFunction) {
    videoState.forwardRef.removeEventListener(
      "timeupdate",
      videoState.onForwardTimeUpdateFunction
    );
    updateVideoState({
      videoKey,
      updatedState: { onForwardTimeUpdateFunction: null },
    });
  }

  const onForwardTimeUpdateFunction = function (
    this: HTMLVideoElement,
    e: Event
  ) {
    onForwardTimeUpdate(this, e, videoState?.reverseRef, videoKey, nextPause);
  };

  videoState.forwardRef.addEventListener(
    "timeupdate",
    onForwardTimeUpdateFunction
  );

  updateVideoState({
    videoKey,
    updatedState: {
      direction: VideoDirection.FORWARD,
      onForwardTimeUpdateFunction,
    },
  });

  if (fromStart) {
    videoState.forwardRef.currentTime = 0;
  }

  videoState.forwardRef.play();
}

function playBackwards(
  videoKey: string,
  { fromEnd }: { fromEnd?: boolean } = {}
) {
  const states = videoStore.getState();

  const videoState = states[videoKey];

  if (!videoState) {
    throw new Error(`State for HTML video ${videoKey} was not found`);
  }

  if (!videoState?.reverseRef) {
    throw new Error(`reverseRef for HTML video ${videoKey} was not found`);
  }

  const isPaused = videoState.reverseRef.paused;
  const isPlaying = !isPaused;
  if (isPlaying && VideoDirection.REVERSE === videoState.direction) {
    /* already playing in reverse */ return;
  }

  // if (
  //   !!videoState.forwardRef &&
  //   withinTolerance({
  //     a: videoState.forwardRef.currentTime,
  //     b: 0,
  //     tolerance: videoEdgeTolerance,
  //   })
  // ) {
  //   /* it's sitting at the start already, probably already was played backwards */
  //   return;
  // }

  if (!Array.isArray(videoState.forwardPauses)) {
    throw new Error(`videoState.forwardPauses is not an array...`);
  }

  const currentTime = videoState.currentSecond;
  const nextPause = videoState.backwardsPauses.reduce((acc: number, curr) => {
    const previousDiff = Math.abs(acc - currentTime);
    const currentDiff = Math.abs(curr.at - currentTime);

    if (curr.at < currentTime && currentDiff < previousDiff) {
      acc = curr.at;
    }

    return acc;
  }, Infinity);

  if (videoState.forwardRef) {
    if (!videoState.forwardRef.paused) {
      videoState.forwardRef.pause();
    }
  }

  if (videoState.onReverseTimeUpdateFunction) {
    videoState.reverseRef.removeEventListener(
      "timeupdate",
      videoState.onReverseTimeUpdateFunction
    );
    updateVideoState({
      videoKey,
      updatedState: { onReverseTimeUpdateFunction: null },
    });
  }

  const onBackwardsTimeUpdateFunction = function (
    this: HTMLVideoElement,
    e: Event
  ) {
    onBackwardsTimeUpdate(this, e, videoState?.forwardRef, videoKey, nextPause);
  };

  videoState.reverseRef.addEventListener(
    "timeupdate",
    onBackwardsTimeUpdateFunction
  );

  updateVideoState({
    videoKey,
    updatedState: {
      direction: VideoDirection.REVERSE,
      onReverseTimeUpdateFunction: onBackwardsTimeUpdateFunction,
    },
  });

  if (fromEnd) {
    videoState.reverseRef.currentTime = 0;
  }

  videoState.reverseRef.play();
}

function pause(videoKey: string, { at }: { at?: number } = {}) {
  const states = videoStore.getState();

  const videoState = states[videoKey];

  if (!videoState) {
    throw new Error(`State for HTML video ${videoKey} was not found`);
  }

  if (videoState.forwardRef) {
    if ("number" === typeof at) {
      try {
        videoState.forwardRef.currentTime = at;
      } catch (e) {
        console.error(e);
      }
    }

    if (!videoState.forwardRef.paused) {
      videoState.forwardRef.pause();
    }

    if (videoState.onForwardTimeUpdateFunction) {
      videoState.forwardRef.removeEventListener(
        "timeupdate",
        videoState.onForwardTimeUpdateFunction
      );
      updateVideoState({
        videoKey,
        updatedState: { onForwardTimeUpdateFunction: null },
      });
    }
  }

  if (videoState.reverseRef) {
    if ("number" === typeof at) {
      try {
        videoState.reverseRef.currentTime = videoState.reverseRef.duration - at;
      } catch (e) {
        console.error(e);
      }
    }

    if (!videoState.reverseRef.paused) {
      videoState.reverseRef.pause();
    }

    if (videoState.onReverseTimeUpdateFunction) {
      videoState.reverseRef.removeEventListener(
        "timeupdate",
        videoState.onReverseTimeUpdateFunction
      );
      updateVideoState({
        videoKey,
        updatedState: { onReverseTimeUpdateFunction: null },
      });
    }
  }
}

function setProgressToEdge(
  videoKey: string,
  edgeType: VideoEdge,
  { ignoreIfNotFound }: { ignoreIfNotFound?: boolean } = {}
) {
  const states = videoStore.getState();
  const currentVideoState = states[videoKey];

  if (!currentVideoState) {
    if (ignoreIfNotFound) {
      return;
    }
    throw new Error(`${videoKey} video state was not found`);
  }

  if (currentVideoState.forwardRef) {
    if (VideoEdge.START === edgeType) {
      currentVideoState.forwardRef.currentTime = 0;
    } else if (VideoEdge.FINISH === edgeType) {
      try {
        currentVideoState.forwardRef.currentTime =
          currentVideoState.forwardRef.duration;
      } catch (e) {
        console.error(e);
      }
    }
  }

  if (currentVideoState.reverseRef) {
    if (VideoEdge.START === edgeType) {
      try {
        currentVideoState.reverseRef.currentTime =
          currentVideoState.reverseRef.duration;
      } catch (e) {
        console.error(e);
      }
    } else if (VideoEdge.FINISH === edgeType) {
      currentVideoState.reverseRef.currentTime = 0;
    }
  }
}

function setForwardAndReverseVideosProgressToZero(
  videoKey: string,
  { ignoreIfNotFound }: { ignoreIfNotFound?: boolean } = {}
) {
  // the idea is to have both forward and reverse videos
  // sitting on the correct frame before showing the video block (from any direction)

  const states = videoStore.getState();
  const currentVideoState = states[videoKey];

  if (!currentVideoState) {
    if (ignoreIfNotFound) {
      return;
    } else {
      throw new Error(
        `setForwardAndReverseVideosProgressToZero: currentVideoState with videoKey=${videoKey} was not found`
      );
    }
  }

  if (currentVideoState?.forwardRef) {
    currentVideoState.forwardRef.currentTime = 0;
  }

  if (currentVideoState?.reverseRef) {
    currentVideoState.reverseRef.currentTime = 0;
  }
}

const htmlVideoController = {
  init,
  playForward,
  playBackwards,
  pause,
  initForward,
  initBackwards,
  unmountForward,
  unmountBackwards,
  setProgressToEdge,
  setForwardAndReverseVideosProgressToZero,
};

export default htmlVideoController;
