import React, {
  FC,
  SVGAttributes,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import clsx from "clsx";
import ReactResizeDetector from "react-resize-detector";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";

import {
  imageSequenceVideoStore,
  updateImageSequenceVideoState,
} from "stores/imageSequenceVideo";

import { WidthAndHeight } from "types";

import { getRelativeClientRect } from "utils/getRelativeClientRect";

import { ReactComponent as DragIcon } from "./assets/drag-icon.svg";
import { ReactComponent as HolderArea } from "./assets/holder-area.svg";

import "./styles.scss";

type ScanDragLayerProps = {
  startPosition: [number, number];
  endPosition: [number, number];
  imageSequenceVideoKey: string;
};

export const ScanDragLayer: FC<ScanDragLayerProps> = ({
  startPosition,
  endPosition,
  imageSequenceVideoKey,
}) => {
  const { t } = useTranslation();

  const states = useStore(imageSequenceVideoStore);
  const currentSeqState = states[imageSequenceVideoKey];
  const activeIndex = currentSeqState?.activeFrameIndex || 0;
  const maxIndex = currentSeqState.maxFrameIndex;

  const [innerSize, setInnerSize] = useState<WidthAndHeight>({
    width: 1,
    height: 1,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const startWrapperRef = useRef<HTMLDivElement | null>(null);
  const endWrapperRef = useRef<HTMLDivElement | null>(null);
  const dragWrapperRef = useRef<HTMLDivElement | null>(null);

  const [startCoords, setStartCoords] = useState<number[]>([0, 0]);
  const [endCoords, setEndCoords] = useState<number[]>([0, 0]);
  const [controlCoords, setControlCoords] = useState<number[]>([0, 0]); // control point for Bezier curve
  const [dragCoords, setDragCoords] = useState<number[]>([0, 0]);
  const [linePath, setLinePath] = useState<SVGAttributes<SVGPathElement>["d"]>(
    "M0 0"
  );

  const holderOffset = 5; // value for clip of line
  const curveOffset = -90; // distance of control point from mid-point of line

  const getPositionOfDrag = useCallback(() => {
    const n = activeIndex / maxIndex;
    const mx =
      Math.pow(1 - n, 2) * startCoords[0] +
      2 * (1 - n) * n * controlCoords[0] +
      Math.pow(n, 2) * endCoords[0];
    const my =
      Math.pow(1 - n, 2) * startCoords[1] +
      2 * (1 - n) * n * controlCoords[1] +
      Math.pow(n, 2) * endCoords[1];

    return [(mx / innerSize.width) * 100, (my / innerSize.height) * 100];
  }, [activeIndex, maxIndex, endCoords, startCoords, controlCoords, innerSize]);

  const getCoords = useCallback(() => {
    if (startWrapperRef.current && endWrapperRef.current) {
      const startRect = getRelativeClientRect(startWrapperRef.current);
      if (startRect) {
        setStartCoords([
          startRect.left + startRect.width / 2,
          startRect.top + startRect.height / 2,
        ]);
      }
      const endRect = getRelativeClientRect(endWrapperRef.current);
      if (endRect) {
        setEndCoords([
          endRect.left + endRect.width / 2,
          endRect.top + endRect.height / 2,
        ]);
      }
    }
  }, []);

  useEffect(() => {
    const midPointX = (endCoords[0] + startCoords[0]) / 2;
    const midPointY = (endCoords[1] + startCoords[1]) / 2;

    const theta =
      Math.atan2(endCoords[1] - startCoords[1], endCoords[0] - startCoords[0]) -
      Math.PI / 2;

    const controlPointX = midPointX + curveOffset * Math.cos(theta);
    const controlPointY = midPointY + curveOffset * Math.sin(theta);

    setControlCoords([controlPointX, controlPointY]);

    setLinePath(`
      M${startCoords[0]} ${startCoords[1]}
      Q${controlPointX} ${controlPointY}
      ${endCoords[0]} ${endCoords[1]}
    `);
  }, [curveOffset, startCoords, endCoords]);

  useEffect(() => {
    getCoords();
  }, [getCoords]);

  useEffect(() => {
    setDragCoords(getPositionOfDrag());
  }, [getPositionOfDrag]);

  const onDragMove = useCallback(
    (clientX: number, clientY: number) => {
      const xDragPosition = (clientX / window.innerWidth) * 100;
      const yDragPosition = (clientY / window.innerHeight) * 100;

      const currentStep = Math.min(
        Math.round(
          (xDragPosition - startPosition[0]) /
            (-(startPosition[0] - endPosition[0]) / maxIndex)
        ),
        Math.round(
          (yDragPosition - startPosition[1]) /
            (-(startPosition[1] - endPosition[1]) / maxIndex)
        )
      );

      const currentFrameIndex =
        currentStep < 1 ? 0 : currentStep >= maxIndex ? maxIndex : currentStep;

      updateImageSequenceVideoState({
        imageSequenceVideoKey,
        updatedState: {
          activeFrameIndex: currentFrameIndex,
        },
      });
    },
    [startPosition, endPosition, maxIndex, imageSequenceVideoKey]
  );

  const handleMouseDrag = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      updateImageSequenceVideoState({
        imageSequenceVideoKey,
        updatedState: { ignoreEdge: true },
      });

      const dragItem = dragWrapperRef.current;
      if (dragWrapperRef && dragItem) {
        const onMouseMove = (e: React.MouseEvent | MouseEvent) => {
          onDragMove(e.clientX, e.clientY);
        };

        document.addEventListener("mousemove", onMouseMove);
        document.onmouseup = () => {
          document.removeEventListener("mousemove", onMouseMove);
          document.onmouseup = null;

          updateImageSequenceVideoState({
            imageSequenceVideoKey,
            updatedState: { ignoreEdge: false },
          });
        };
      }
    },
    [onDragMove, imageSequenceVideoKey]
  );

  const handleTouchDrag = useCallback(
    (e: React.TouchEvent | TouchEvent) => {
      e.stopPropagation();

      updateImageSequenceVideoState({
        imageSequenceVideoKey,
        updatedState: { ignoreEdge: true },
      });
      const dragItem = dragWrapperRef.current;
      if (dragWrapperRef && dragItem) {
        const onTouchMove = (e: React.TouchEvent | TouchEvent) => {
          onDragMove(e.touches[0].clientX, e.touches[0].clientY);
        };

        document.addEventListener("touchmove", onTouchMove);
        document.ontouchend = () => {
          document.removeEventListener("touchmove", onTouchMove);
          document.ontouchend = null;

          updateImageSequenceVideoState({
            imageSequenceVideoKey,
            updatedState: { ignoreEdge: false },
          });
        };
      }
    },
    [onDragMove, imageSequenceVideoKey]
  );

  return (
    <ReactResizeDetector
      handleWidth
      handleHeight
      targetRef={wrapperRef}
      onResize={(width: undefined | number, height: undefined | number) => {
        if ("number" === typeof width && "number" === typeof height) {
          setInnerSize({
            width,
            height,
          });
        }
        getCoords();
      }}
    >
      <div className="scan-drag-layer" ref={wrapperRef}>
        <div
          ref={endWrapperRef}
          className="scan-drag-layer__holder-area"
          style={{
            left: `${endPosition[0]}%`,
            top: `${endPosition[1]}%`,
          }}
        >
          <HolderArea />
        </div>
        <div
          ref={startWrapperRef}
          className="scan-drag-layer__holder-area"
          style={{
            left: `${startPosition[0]}%`,
            top: `${startPosition[1]}%`,
          }}
        >
          <HolderArea />
        </div>
        <div
          className={clsx("scan-drag-layer__drag-icon")}
          ref={dragWrapperRef}
          onMouseDown={(e) => handleMouseDrag(e)}
          onTouchStart={(e) => handleTouchDrag(e)}
          style={{
            left: `${dragCoords[0]}%`,
            top: `${dragCoords[1]}%`,
          }}
        >
          <DragIcon />
          <div className="scan-drag-layer__drag-text">{t("Click & Drag")}</div>
        </div>
        <svg className="scan-drag-layer__drag-path" width="100%" height="100%">
          {startWrapperRef.current && endWrapperRef.current && (
            <defs>
              <mask id={`cut_${imageSequenceVideoKey}`}>
                <path d={linePath} fill="white" />
                <circle
                  cx={
                    getRelativeClientRect(endWrapperRef.current)!.left +
                    getRelativeClientRect(endWrapperRef.current)!.width / 2
                  }
                  cy={
                    getRelativeClientRect(endWrapperRef.current)!.top +
                    getRelativeClientRect(endWrapperRef.current)!.height / 2
                  }
                  r={
                    getRelativeClientRect(endWrapperRef.current)!.width / 2 +
                    holderOffset * 2
                  }
                  fill="black"
                />
                <circle
                  cx={
                    getRelativeClientRect(startWrapperRef.current)!.left +
                    getRelativeClientRect(startWrapperRef.current)!.width / 2
                  }
                  cy={
                    getRelativeClientRect(startWrapperRef.current)!.top +
                    getRelativeClientRect(startWrapperRef.current)!.height / 2
                  }
                  r={
                    getRelativeClientRect(startWrapperRef.current)!.width / 2 +
                    holderOffset * 2
                  }
                  fill="black"
                />
              </mask>
            </defs>
          )}
          <path
            d={linePath}
            stroke="#fff"
            strokeWidth="2"
            strokeDasharray="3"
            fill="transparent"
            mask={`url(#cut_${imageSequenceVideoKey})`}
          />
        </svg>
      </div>
    </ReactResizeDetector>
  );
};

export default ScanDragLayer;
