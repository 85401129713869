import { reducedBlocks } from "config";
import {
  containerStore,
  UpdateContainerPayload,
  updateContainerState,
} from "stores/container";
import { BlockType } from "types";
import htmlVideoController from "./htmlVideo.controller";
import imageSequenceVideoController from "./imageSequenceVideo.controller";

function init(
  containerKey: string,
  initialState: UpdateContainerPayload["updatedState"],
) {
  updateContainerState({
    containerKey,
    updatedState: initialState,
  });
}

function pauseActiveBlock(containerKey: string) {
  const containerStates = containerStore.getState();
  const currentContainerState = containerStates[containerKey];

  if (!currentContainerState) {
    throw new Error(`State of ${containerKey} container was not found`);
  }

  const activeBlock = currentContainerState.currentBlock;

  if (!activeBlock) {
    // got nothing to pause
    return;
  }

  const blockInfo = reducedBlocks[activeBlock];

  if (!blockInfo) {
    throw new Error(`blockInfo for block ${activeBlock} was not found`);
  }

  if (blockInfo.blockType === BlockType.HTML_VIDEO) {
    htmlVideoController.pause(blockInfo.videoKey);
  } else if (blockInfo.blockType === BlockType.IMAGE_SEQUENCE) {
    imageSequenceVideoController.pause(blockInfo.imageSequenceVideoKey);
  }
}

function setActiveBlock(containerKey: string, blockKey: string) {
  updateContainerState({
    containerKey,
    updatedState: {
      currentBlock: blockKey,
    },
  });
}

const containerController = { pauseActiveBlock, init, setActiveBlock };

export default containerController;
