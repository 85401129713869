import React, { FC, useMemo } from "react";
import { VideoLayer } from "types";

type LayersOverVideoProps = {
  activeFrameOrSecond: number;
  activeSecond?: number;
  layers: VideoLayer[];
};

type ReducedActiveLayers = {
  [key: string]: VideoLayer[];
};

const LayersOverVideo: FC<LayersOverVideoProps> = ({
  activeFrameOrSecond,
  layers,
}) => {
  const recudedFrames: ReducedActiveLayers = useMemo(() => {
    return layers.reduce((acc: ReducedActiveLayers, curr) => {
      for (let i = curr.from; i <= curr.to; i++) {
        const alreadyAddedLayers = acc[i] || [];
        acc[i] = [...alreadyAddedLayers, curr];
      }

      return acc;
    }, {});
  }, [layers]);

  const activeLayers = recudedFrames[Math.round(activeFrameOrSecond)] || [];

  return (
    <>
      {activeLayers.length > 0 && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            overflow: "hidden",
          }}
        >
          {activeLayers.map((layer) => {
            return (
              <React.Fragment key={layer.key} {...layer.wrapperDivProps}>
                {layer.component}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

export default LayersOverVideo;
