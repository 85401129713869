import React, { FC, SetStateAction, useEffect, useRef, useState } from "react";
import ReactResizeDetector from "react-resize-detector";
import { Swipeable } from "react-swipeable";
import { KeyCode } from "types";

import { getScrollDirection } from "utils/uiEvents";

type SingleFrameWrapperProps = {
  isActive: boolean;
  onEndReached: () => void;
  onStartReached: () => void;
  onResizeDetected: (width: SetStateAction<number>) => void;
};

const SingleFrameWrapper: FC<SingleFrameWrapperProps> = ({
  isActive,
  onEndReached,
  onStartReached,
  onResizeDetected,
  children,
}) => {
  const divRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!isActive) {
        return;
      }

      const key = e.key;

      if (([KeyCode.DOWN, KeyCode.RIGHT] as string[]).includes(key)) {
        onEndReached();
      } else if (([KeyCode.UP, KeyCode.LEFT] as string[]).includes(key)) {
        onStartReached();
      }
    };

    window.addEventListener("keydown", listener);

    return () => window.removeEventListener("keydown", listener);
  }, [isActive, onEndReached, onStartReached]);

  return (
    <>
      <ReactResizeDetector
        handleWidth
        targetRef={divRef}
        onResize={(width: number | undefined) => {
          if ("number" === typeof width) {
            setMaxWidth((old) => {
              const newMax = Math.max(old, width);

              onResizeDetected(newMax);

              return newMax;
            });
          }
        }}
      >
        <div
          ref={divRef}
          onKeyDown={(e) => {
            // if (!isActive) {
            //   return;
            // }
            // const key = e.key;
            // if (([KeyCode.DOWN, KeyCode.RIGHT] as string[]).includes(key)) {
            //   onEndReached();
            // } else if (([KeyCode.UP, KeyCode.LEFT] as string[]).includes(key)) {
            //   onStartReached();
            // }
          }}
          onWheel={(e) => {
            if (!isActive) {
              return;
            }

            e.preventDefault();
            const direction = getScrollDirection(e);

            if (direction === "down") {
              onEndReached();
            } else if (direction === "up") {
              onStartReached();
            }
          }}
        >
          <Swipeable
            trackMouse
            trackTouch
            onSwipedDown={() => {
              if (!isActive) {
                return;
              }

              onStartReached();
            }}
            onSwipedUp={() => {
              if (!isActive) {
                return;
              }

              onEndReached();
            }}
          >
            {children}
          </Swipeable>
        </div>
      </ReactResizeDetector>
    </>
  );
};

export default SingleFrameWrapper;
