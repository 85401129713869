import { FC, useCallback, useEffect, useState } from "react";

import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Swipeable } from "react-swipeable";

import App from "components/App";
import HeaderLogo from "components/Header/HeaderLogo";

import { getScrollDirection } from "utils/uiEvents";
import { KeyCode } from "types";

import "./styles.scss";

type ScrollDownToStartNoteLayerProps = {};

const ScrollDownToStartNoteLayer: FC<ScrollDownToStartNoteLayerProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx("background-layer")}
      style={{
        backgroundColor: "rgba(32, 43, 105, 1)",
      }}
    >
      <div className="intro">
        <h1 className="intro__h1 fade">{t("CSM interactive digital")}</h1>
        <h2 className="intro__h2 fade">{t("Demonstration tool")}</h2>
        <h3 className="intro__h3 fade">{t("Scroll to continue experience")}</h3>
      </div>
    </div>
  );
};

type AppWithIntroProps = {
  onStarted: Function;
};

const AppWithIntro: FC<AppWithIntroProps> = ({ onStarted }) => {
  const [started, setStarted] = useState(false);
  const [hideIntro, setHideIntro] = useState(false);
  const [show, setShow] = useState(false);

  const onStart = useCallback(() => {
    if (started) {
      return;
    }

    setStarted(true);

    onStarted();

    setTimeout(() => {
      setHideIntro(true);
    }, 450);
  }, [started, onStarted]);

  useEffect(() => {
    if (started) {
      return;
    }

    setTimeout(() => {
      setShow(true);
    }, 500);

    const keydownListener = (e: KeyboardEvent) => {
      if (started) {
        return;
      }

      const key = e.key;

      if (([KeyCode.DOWN, KeyCode.RIGHT] as string[]).includes(key)) {
        onStart();
      }
    };

    const wheelListener = (e: WheelEvent) => {
      if (started) {
        return;
      }

      const direction = getScrollDirection(e);

      if ("down" === direction) {
        onStart();
      }
    };

    window.addEventListener("keydown", keydownListener);
    window.addEventListener("wheel", wheelListener);

    return () => {
      window.removeEventListener("keydown", keydownListener);
      window.removeEventListener("wheel", wheelListener);
    };
  }, [started, onStart]);

  return (
    <Swipeable
      trackMouse
      trackTouch
      onSwipedUp={() => {
        onStart();
      }}
      className="intro-root"
    >
      <App expandHeader={started} />

      <div className="logo-wrapper">
        <HeaderLogo visible={show} variant={started ? "dark" : "light"} />
      </div>

      {!hideIntro && (
        <div
          className={clsx("intro-wrapper", { fade: started, visible: show })}
        >
          <ScrollDownToStartNoteLayer />
        </div>
      )}
    </Swipeable>
  );
};

export default AppWithIntro;
