import { createStore, createEvent } from "effector";

export type TabState = {
  currentTab: number;
};

export type TabStore = {
  [key: string]: TabState;
};

export type UpdateTabPayload = {
  tabKey: string;
  updatedState: Partial<TabState>;
};

export const updateTabState = createEvent<UpdateTabPayload>();

export const tabStore = createStore<TabStore>({}).on(
  updateTabState,
  (oldState, payload) => ({
    ...oldState,
    [payload.tabKey]: {
      ...(oldState[payload.tabKey] || {}),
      ...payload.updatedState,
    },
  })
);

// tabStore.watch(console.log);
