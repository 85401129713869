import { createStore, createEvent } from "effector";
import { BlockRatioSnapshot } from "types";

export type BlockState = {
  readyForAdjustingSize: boolean;
  ratioSnapshot?: BlockRatioSnapshot;
};

export type BlockStore = {
  [key: string]: BlockState;
};

export type UpdateContainerPayload = {
  blockKey: string;
  updatedState: Partial<BlockState>;
};

export const updateBlockState = createEvent<UpdateContainerPayload>();
export const resetBlocksState = createEvent();

export const blockStore = createStore<BlockStore>({})
  .on(resetBlocksState, () => ({}))
  .on(updateBlockState, (oldState, payload) => ({
    ...oldState,
    [payload.blockKey]: {
      ...(oldState[payload.blockKey] || {}),
      ...payload.updatedState,
    },
  }));

//blockStore.watch(console.log);
