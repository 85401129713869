import clsx from "clsx";
import stackedBlockController from "controllers/stackedBlock.controller";
import { useStore } from "effector-react";
import React, { FC, useEffect, useRef, useState } from "react";
import ReactResizeDetector from "react-resize-detector";
import { Swipeable } from "react-swipeable";
import Transition from "react-transition-group/Transition";
import animatedScrollTo from "animated-scroll-to";

import { stackedBlockStore } from "stores/stackedBlock";
import { getScrollDirection } from "utils/uiEvents";

import { KeyCode, StackedBlockStackItem } from "../../types";

import "./styles.scss";

type ReducedHeights = { [key: string /* index as string */]: number };
type HeightRange = {
  fromInclusive: number;
  toNonInclusive: number;
  index: number;
};

function getRanges(heights: ReducedHeights): HeightRange[] {
  let totalHeight = 0;
  return Object.entries(heights).reduce((acc: HeightRange[], [key, height]) => {
    return [
      ...acc,
      {
        fromInclusive: totalHeight,
        toNonInclusive: totalHeight += height,
        index: Number(key),
      },
    ];
  }, []);
}

function getCurrentIndex(scrollTop: number, heights: ReducedHeights): number {
  const ranges: HeightRange[] = getRanges(heights);

  const currentRange = ranges.find(({ fromInclusive, toNonInclusive }) => {
    return fromInclusive <= scrollTop && scrollTop < toNonInclusive;
  });

  return currentRange?.index || 0;
}

type StackedBlockProps = {
  isActive: boolean;
  isPrevious: boolean;
  stack: StackedBlockStackItem[];
  stackedBlockKey: string;
  className?: string;

  onStartReached: Function;
};

const StackedBlock: FC<StackedBlockProps> = ({
  stack,
  stackedBlockKey,
  isActive,
  isPrevious,
  className,

  onStartReached,
}) => {
  const states = useStore(stackedBlockStore);
  const stackedRef = useRef<HTMLDivElement>(null);

  const currentState = states[stackedBlockKey];
  const index = currentState?.index || 0;
  const shouldSetScrollTop = currentState?.settingScrollPosition || false;
  const shouldScrollWithAnimation = currentState?.scrollWithAnimation || false;

  const [heights, setHeights] = useState<ReducedHeights>({});

  const [allowStartReached, setAllowStartReached] = useState(true);
  const [allowStartReachedTimeout, setAllowStartReachedTimeout] = useState<
    number
  >(0);

  useEffect(() => {
    if (!stackedRef.current) {
      return;
    }

    if (!shouldSetScrollTop) {
      return;
    }

    const wantedScrollTop = Object.values(heights).reduce(
      (acc, curr, i) => (i < index ? acc + curr : acc),
      0,
    );

    if (shouldScrollWithAnimation) {
      animatedScrollTo(wantedScrollTop, {
        elementToScroll: stackedRef.current,
      });
    } else {
      stackedRef.current.scrollTop = wantedScrollTop;
    }

    stackedBlockController.onScrolledToIndex(stackedBlockKey);

    // set scroll when index prop is changed
    // eslint-disable-next-line
  }, [shouldSetScrollTop, shouldScrollWithAnimation, index, stackedBlockKey]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!isActive) {
        return;
      }

      const key = e.key;

      if (([KeyCode.UP, KeyCode.LEFT] as string[]).includes(key)) {
        if (!stackedRef.current) {
          return;
        }

        if (!allowStartReached) {
          return;
        }

        if (stackedRef.current.scrollTop === 0) {
          onStartReached();
        }
      }
    };

    window.addEventListener("keydown", listener);

    return () => window.removeEventListener("keydown", listener);
  }, [isActive, onStartReached, allowStartReached]);

  return (
    <Transition timeout={15} in={isActive}>
      {(state) => (
        <div
          className={clsx("stacked-block", className, state, {
            transparent: !(isActive || isPrevious),
            "active-animation": isActive,
          })}
          ref={stackedRef}
          onWheel={(e) => {
            if (!stackedRef.current) {
              return;
            }

            const direction = getScrollDirection(e);

            if ("up" === direction) {
              if (allowStartReached) {
                if (stackedRef.current.scrollTop === 0) {
                  onStartReached();
                }
              }
            }
          }}
          onScroll={(e) => {
            if (!stackedRef.current) {
              return;
            }

            const scrollTop =
              stackedRef.current.scrollTop +
                stackedRef.current.offsetHeight / 2 || 0;
            const currentIndex = getCurrentIndex(scrollTop, heights);

            if (index !== currentIndex) {
              stackedBlockController.setIndexNoScroll(
                stackedBlockKey,
                currentIndex,
              );
            }

            if (stackedRef.current.scrollTop === 0) {
              if (allowStartReachedTimeout) {
                return;
              }

              const timeoutId: number = Number(
                setTimeout(() => setAllowStartReached(true), 75),
              );
              setAllowStartReachedTimeout(timeoutId);
            } else {
              setAllowStartReached(false);
              clearInterval(allowStartReachedTimeout);
              setAllowStartReachedTimeout(0);
            }
          }}
        >
          <Swipeable
            className={clsx("stacked-block__inner")}
            trackMouse
            trackTouch
            onSwipedDown={(e) => {
              if (!stackedRef.current) {
                return;
              }

              if (!allowStartReached) {
                return;
              }

              if (stackedRef.current.scrollTop === 0) {
                onStartReached();
              }
            }}
          >
            {stack.map(({ key, component }, index) => (
              <ReactResizeDetector
                key={key}
                handleHeight
                handleWidth={false}
                onResize={(
                  width: undefined | number,
                  height: undefined | number,
                ) => {
                  if ("number" === typeof height) {
                    setHeights((old) => ({
                      ...old,
                      [index]: height,
                    }));
                  }
                }}
              >
                {component}
              </ReactResizeDetector>
            ))}
          </Swipeable>
        </div>
      )}
    </Transition>
  );
};

export default StackedBlock;
