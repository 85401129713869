import { createStore, createEvent } from "effector";

export type StackedBlockState = {
  index: number;
  settingScrollPosition: boolean;
  scrollWithAnimation: boolean;
};

export type StackedBlockStore = {
  [key: string]: StackedBlockState;
};

export type UpdateVideoPayload = {
  stackedBlockKey: string;
  updatedState: Partial<StackedBlockState>;
};

export const updateStackedBlockState = createEvent<UpdateVideoPayload>(
  "updateStackedBlockState",
);

export const stackedBlockStore = createStore<StackedBlockStore>({}).on(
  updateStackedBlockState,
  (oldState, payload) => ({
    ...oldState,
    [payload.stackedBlockKey]: {
      ...(oldState[payload.stackedBlockKey] || {}),
      ...payload.updatedState,
    },
  }),
);
