import React, { useContext, useEffect, useMemo } from "react";
import qs from "qs";

import LanguageSwitch from "components/LanguageSwitch";

import { ConfigContext } from "contexts/configContext";

import { getLanguagesFromConfig } from "utils/config";
import i18n from "utils/i18next";
import { prepareSearch } from "utils/qs";

import { CommonSearchParams } from "types";

const I18NextLanguageSwitch = () => {
  const config = useContext(ConfigContext);

  const search = qs.parse(
    prepareSearch(window.location.search),
  ) as CommonSearchParams;

  const { language } = search;

  const languages = useMemo(() => {
    if (!config) {
      return [];
    }

    return getLanguagesFromConfig(config).map((data) => {
      const { key, name, iconUrl } = data;

      return {
        key,
        code: key,
        iconUrl,
        title: name,
      };
    });
  }, [config]);

  useEffect(() => {
    if (language && languages.some((lang) => lang.code === language)) {
      i18n.changeLanguage(language);
    }
  }, [language, languages]);

  return (
    <LanguageSwitch
      languages={languages}
      activeLanguage={i18n.getCurrentLanguage()}
      onChangeLanguage={(lng) => i18n.changeLanguage(lng)}
    />
  );
};

export default I18NextLanguageSwitch;
