import React, { FC, useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import layerStart from "./assets/layerStart.png";
import { ReactComponent as PlayButton } from "./assets/playButton.svg";
import { updateContainerState } from "../../../stores/container";
import htmlVideoController from "../../../controllers/htmlVideo.controller";

import "./styles.scss";

type ModifierProps =
  | "decreased-title"
  | "increased-title"
  | "center"
  | "xl"
  | "lower"
  | "lower2"
  | "lower3"
  | "nofade"
  | "long-line";

type ContentLayerProps = {
  content: string;
  title: string;
  subtitle?: string;
  buttonModule?: {
    text: string;
    link?: string;
  };
  modifier?: ModifierProps | ModifierProps[];
  separatorPosition?: "afterTitle" | "afterContent";
  playButton?: boolean;
  playButtonLink?: string;
  hotspot?: "default" | "contour-line";
  className?: string;
};

type ContentSeparatorProps = {
  position: ContentLayerProps["separatorPosition"];
};

const ContentSeparator: FC<ContentSeparatorProps> = ({ position }) => (
  <div
    className={clsx(
      "content-layer__separator",
      `content-layer__separator--${position}`,
    )}
  >
    <img className="content-layer__separator-image" src={layerStart} alt="" />
  </div>
);

const ContentLayer: FC<ContentLayerProps> = ({
  content,
  title,
  subtitle,
  modifier,
  buttonModule,
  separatorPosition,
  playButton,
  playButtonLink,
  hotspot,
  className,
}) => {
  const { t } = useTranslation();
  const titleRef = useRef<HTMLSpanElement | null>(null);
  const [titleWidth, setTitleWidth] = useState<number>(0);
  const titleIndent = 45;

  useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div
      className={clsx(
        "content-layer",
        className,
        modifier && Array.isArray(modifier)
          ? modifier.map((mod) => (mod ? `content-layer--${mod}` : ""))
          : `content-layer--${modifier}`,
      )}
    >
      {subtitle && (
        <div
          className="content-layer__subtitle swipe"
          dangerouslySetInnerHTML={{ __html: t(subtitle) }}
        />
      )}
      <h1 className="content-layer__title">
        <span
          ref={titleRef}
          dangerouslySetInnerHTML={{ __html: t(title) }}
          className="content-layer__title-row swipe"
        />
        {hotspot && (
          <div
            className={clsx("content-layer__hotspot-wrapper", {
              "content-layer__hotspot-wrapper--contour":
                hotspot === "contour-line",
            })}
          >
            {hotspot === "default" && (
              <svg
                width="100%"
                height="1"
                className="content-layer__hotspot-line"
              >
                <line
                  x1="100%"
                  x2={Number(titleWidth + titleIndent)}
                  transform="translate(0 0.5)"
                  strokeWidth="1"
                />
              </svg>
            )}
            {hotspot === "contour-line" && (
              <svg
                className="content-layer__hotspot-line"
                width="100%"
                height="200"
              >
                <g transform="translate(-190.5 -456)">
                  <line
                    x1="450"
                    transform="translate(766.5 655.5)"
                    fill="none"
                    stroke="#5369e5"
                    strokeWidth="1"
                  />
                  <line
                    x1="576"
                    x2={Number(titleWidth + titleIndent)}
                    transform="translate(190.5 456.5)"
                    fill="none"
                    stroke="#5369e5"
                    strokeWidth="1"
                  />
                  <line
                    y2="199"
                    transform="translate(766.5 456.5)"
                    fill="none"
                    stroke="#5369e5"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            )}
            <div className="content-layer__hotspot" />
          </div>
        )}
      </h1>
      {separatorPosition && separatorPosition === "afterTitle" && (
        <ContentSeparator position={separatorPosition} />
      )}
      <div
        className="content-layer__content fade"
        dangerouslySetInnerHTML={{ __html: t(content) }}
      />
      {separatorPosition && separatorPosition === "afterContent" && (
        <ContentSeparator position={separatorPosition} />
      )}
      {buttonModule && (
        <button
          onClick={() => {
            updateContainerState({
              containerKey: "1_container",
              updatedState: { currentBlock: buttonModule.link },
            });

            var theLink =
              buttonModule.link !== undefined
                ? buttonModule.link
                : "Mod02_USP02";
            htmlVideoController.playForward(theLink, {
              fromStart: true,
            });
          }}
          className="content-layer__callback-button"
        >
          {t(buttonModule.text)}
        </button>
      )}
      {playButton && (
        <button
          onClick={() => {
            window.open(playButtonLink, "_blank");
          }}
          className="content-layer__play-button swipe"
        >
          <span>{t("Play video")}</span>
          <PlayButton className="content-layer__play-button-component" />
        </button>
      )}
    </div>
  );
};

export default ContentLayer;
