import React from "react";
import ReactDOM from "react-dom";

import ConfigProcessor from "components/ConfigProcessor";
import VerticalProof from "components/VerticalProof";
import AppWithIntro from "components/AppWithIntro";
import htmlVideoController from "controllers/htmlVideo.controller";
import containerController from "controllers/containerController";

const onStarted = () => {
  containerController.init("1_container", {
    currentBlock: "Mod00_USP01_Intro",
  });
  htmlVideoController.playForward("Mod00_USP01_Intro");
};

ReactDOM.render(
  <React.StrictMode>
    <ConfigProcessor>
      <VerticalProof>
        <AppWithIntro onStarted={onStarted} />
      </VerticalProof>
    </ConfigProcessor>
  </React.StrictMode>,
  document.getElementById("root")
);
