import { createStore, createEvent } from "effector";

export type ContainerState = {
  currentBlock: string;
};

export type ContainerStore = {
  [key: string]: ContainerState;
};

export type UpdateContainerPayload = {
  containerKey: string;
  updatedState: Partial<ContainerState>;
};

export const updateContainerState = createEvent<UpdateContainerPayload>();

export const containerStore = createStore<ContainerStore>({}).on(
  updateContainerState,
  (oldState, payload) => ({
    ...oldState,
    [payload.containerKey]: {
      ...(oldState[payload.containerKey] || {}),
      ...payload.updatedState,
    },
  })
);

//containerStore.watch(console.log);
