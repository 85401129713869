import AdjustedSizeBlock from "components/AdjustedSizeBlock";
import SingleFrameBlock from "components/SingleFrameBlock";
import ImageFadeBlock from "components/ImageFadeBlock";

import blockController from "controllers/blockController";
import stackedBlockController from "controllers/stackedBlock.controller";
import React, { FC, useState } from "react";
import { ReducedBlocks, Block as IBlock, BlockType, BlockAction } from "types";
import htmlVideoController from "../../controllers/htmlVideo.controller";

import imageSequenceVideoController from "../../controllers/imageSequenceVideo.controller";
import { updateContainerState } from "../../stores/container";
import ImageSequenceVideo from "../ImageSequenceVideo";
import Video from "../Video";

import CustomBlock from "components/CustomBlock";

import "./styles.scss";
import StackedBlock from "components/StackedBlock";

export type FlowDirection = "forward" | "backwards";

type BlockProps = {
  isActive: boolean;
  isPrevious: boolean;
  isNext: boolean;
  blockKey: string;
  containerKey: string;
  blocks: ReducedBlocks;

  flowDirection: FlowDirection;
  onFlowDirectionChanged: (d: FlowDirection) => void;

  wrapperSize: {
    height: number;
    width: number;
  };
};

function findNextOrPreviousBlock(
  type: "previous" | "next",
  currentBlock: IBlock,
  allBlocks: ReducedBlocks,
): IBlock | null {
  if (type === "next") {
    if (!currentBlock?.nextBlockKey) {
      return null;
    }

    return allBlocks[currentBlock.nextBlockKey] || null;
  }

  if (type === "previous") {
    if (!currentBlock?.previousBlockKey) {
      return null;
    }

    return allBlocks[currentBlock.previousBlockKey] || null;
  }

  return null;
}

const Block: FC<BlockProps> = ({
  isActive,
  isPrevious,
  isNext,
  blockKey,
  blocks,
  containerKey,

  wrapperSize,
  flowDirection,
  onFlowDirectionChanged,
}) => {
  const currentBlock = blocks[blockKey];

  if (!currentBlock) {
    return null;
  }

  const onPrevious = (currentBlock: IBlock) => {
    //console.log("onPrevious -> currentBlock", currentBlock);
    onFlowDirectionChanged("backwards");
    if (!currentBlock.onStartReached) {
      return;
    }
    if (currentBlock.onStartReached === BlockAction.IGNORE) {
      return;
    }
    if (currentBlock.onStartReached === BlockAction.STOP_ON_CURRENT) {
      /* it will stop itself */ return;
    }

    const nextBlock = findNextOrPreviousBlock("previous", currentBlock, blocks);

    //console.log("onPrevious -> nextBlock", nextBlock);

    if (
      nextBlock &&
      nextBlock.blockType === BlockType.IMAGE_SEQUENCE &&
      nextBlock.looped
    ) {
      imageSequenceVideoController.setActiveFrameIndex(
        nextBlock.imageSequenceVideoKey,
        0,
      );
    }

    if (currentBlock.onStartReached === BlockAction.PLAY_NEXT) {
      if (!nextBlock) {
        return;
      }

      updateContainerState({
        containerKey,
        updatedState: {
          currentBlock: nextBlock.blockKey,
        },
      });

      if (nextBlock.blockType === BlockType.IMAGE_SEQUENCE) {
        imageSequenceVideoController.playBackwards(
          nextBlock.imageSequenceVideoKey,
          { fromEnd: true },
        );
      } else if (nextBlock.blockType === BlockType.HTML_VIDEO) {
        htmlVideoController.playBackwards(nextBlock.videoKey, {
          fromEnd: true,
        });
      }
    } else if (currentBlock.onStartReached === BlockAction.STOP_ON_NEXT) {
      if (!nextBlock) {
        return;
      }

      updateContainerState({
        containerKey,
        updatedState: {
          currentBlock: nextBlock?.blockKey,
        },
      });
    }
  };

  const onNext = (currentBlock: IBlock) => {
    onFlowDirectionChanged("forward");

    //console.log("onNext -> currentBlock", currentBlock);
    if (!currentBlock.onEndReached) {
      return;
    }
    if (currentBlock.onEndReached === BlockAction.IGNORE) {
      return;
    }
    if (currentBlock.onEndReached === BlockAction.STOP_ON_CURRENT) {
      /* it will stop itself from controller */ return;
    }

    const nextBlock = findNextOrPreviousBlock("next", currentBlock, blocks);

    //console.log("onNext -> nextBlock", nextBlock);

    if (currentBlock.onEndReached === BlockAction.PLAY_NEXT) {
      if (!nextBlock) {
        return;
      }

      updateContainerState({
        containerKey,
        updatedState: {
          currentBlock: nextBlock.blockKey,
        },
      });

      if (nextBlock.blockType === BlockType.IMAGE_SEQUENCE) {
        imageSequenceVideoController.playForward(
          nextBlock.imageSequenceVideoKey,
          { fromFrameIndex: 0 },
        );
      } else if (nextBlock.blockType === BlockType.HTML_VIDEO) {
        htmlVideoController.playForward(nextBlock.videoKey, {
          fromStart: true,
        });
      }
    } else if (currentBlock.onEndReached === BlockAction.STOP_ON_NEXT) {
      if (!nextBlock) {
        return;
      }

      if (nextBlock.blockType === BlockType.STACKED) {
        stackedBlockController.scrollToIndex(nextBlock.blockKey, 0);
      }

      updateContainerState({
        containerKey,
        updatedState: {
          currentBlock: nextBlock.blockKey,
        },
      });
    }
  };

  if (!Object.values(BlockType).includes(currentBlock.blockType)) {
    return <>Unknown block type...</>;
  }

  return (
    <>
      <AdjustedSizeBlock
        containerSize={wrapperSize}
        isPrevious={isPrevious}
        isNext={isNext}
        isActive={isActive}
        blockKey={blockKey}
        blockType={currentBlock.blockType}
      >
        {currentBlock.blockType === BlockType.HTML_VIDEO && (
          <Video
            onReadyForAdjustingSize={() => {
              blockController.setReadyForAdjustingSize(blockKey, true);
            }}
            isActive={isActive}
            layers={currentBlock.layers}
            videoKey={currentBlock.videoKey}
            videos={currentBlock.videos}
            onStartReached={() => {
              if (!isActive) {
                return;
              }

              onPrevious(currentBlock);
            }}
            onEndReached={() => {
              if (!isActive) {
                return;
              }

              onNext(currentBlock);
            }}
            directions={{
              forward: true,
              backwards: true,
            }}
          />
        )}

        {currentBlock.blockType === BlockType.IMAGE_SEQUENCE && (
          <ImageSequenceVideo
            onReadyForAdjustingSize={() => {
              blockController.setReadyForAdjustingSize(blockKey, true);
            }}
            isActive={isActive}
            looped={currentBlock.looped}
            onStartReached={() => {
              if (!isActive) {
                return;
              }

              onPrevious(currentBlock);
            }}
            onEndReached={() => {
              if (!isActive) {
                return;
              }

              onNext(currentBlock);
            }}
            imageSequenceVideoKey={currentBlock.imageSequenceVideoKey}
            frameGroups={currentBlock.frameGroups}
            layers={currentBlock.layers}
            swipeTouchDisabled={currentBlock.swipeTouchDisabled}
          />
        )}

        {currentBlock.blockType === BlockType.SINGLE_FRAME && (
          <SingleFrameBlock
            onReadyForAdjustingSize={() => {
              blockController.setReadyForAdjustingSize(blockKey, true);
            }}
            isActive={isActive}
            isPrevious={isPrevious}
            frameGroup={currentBlock.frameGroup}
            layers={currentBlock.layers}
            onStartReached={() => {
              if (!isActive) {
                return;
              }

              onPrevious(currentBlock);
            }}
            onEndReached={() => {
              if (!isActive) {
                return;
              }

              onNext(currentBlock);
            }}
          />
        )}

        {currentBlock.blockType === BlockType.IMAGE_FADE && (
          <ImageFadeBlock
            onReadyForAdjustingSize={() => {
              blockController.setReadyForAdjustingSize(blockKey, true);
            }}
            fadeDuration={currentBlock.fadeDuration}
            fadeTransition={currentBlock.fadeTransition}
            lastDirection={flowDirection}
            isActive={isActive}
            frameGroup={currentBlock.frameGroup}
            layers={currentBlock.layers}
            onStartReached={() => {
              if (!isActive) {
                return;
              }

              onPrevious(currentBlock);
            }}
            onEndReached={() => {
              if (!isActive) {
                return;
              }

              onNext(currentBlock);
            }}
          />
        )}
      </AdjustedSizeBlock>

      {currentBlock.blockType === BlockType.CUSTOM && (
        <CustomBlock
          isActive={isActive}
          isPrevious={isPrevious}
          className={currentBlock.className}
          onStartReached={() => {
            if (!isActive) {
              return;
            }

            onPrevious(currentBlock);
          }}
          onEndReached={() => {
            if (!isActive) {
              return;
            }

            onNext(currentBlock);
          }}
        >
          {currentBlock.component}
        </CustomBlock>
      )}

      {currentBlock.blockType === BlockType.STACKED && (
        <StackedBlock
          onStartReached={() => {
            if (!isActive) {
              return;
            }

            onPrevious(currentBlock);
          }}
          isActive={isActive}
          isPrevious={isPrevious}
          stackedBlockKey={currentBlock.blockKey}
          stack={currentBlock.stack}
          className={currentBlock.className}
        />
      )}
    </>
  );
};

export default Block;
