import React, { FC, useEffect } from "react";
import { resetBlocksState } from "stores/block";
import useWindowSize from "../../hooks/useWindowSize";
import VerticalModeError from "../VerticalModeError";

const VerticalProof: FC = ({ children }) => {
  const [width, height] = useWindowSize();

  const vertical = height > width;

  useEffect(() => {
    if (vertical) {
      resetBlocksState();
    }
  }, [vertical]);

  if (vertical) {
    return <VerticalModeError />;
  }

  return <>{children}</>;
};

export default VerticalProof;
