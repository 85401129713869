import React, { ComponentProps, FC, useRef } from "react";
import ReactResizeDetector from "react-resize-detector";

export type AnchorPoint = {
  horizontal: "right" | "left" | "center";
  vertical: "bottom" | "top" | "center";
};

export type AnchoredComponentProps = {
  x: number; // percent;
  y: number; // percent;
  anchorPoint: AnchorPoint;
};

function getStyles(
  anchorPoint: AnchorPoint,
  { x, y }: { x: number; y: number },
  { width, height }: { width: number | undefined; height: number | undefined }
): ComponentProps<"div">["style"] {
  const style: ComponentProps<"div">["style"] = { position: "absolute" };

  const makePercent = (val: number): string => `${val}%`;
  const makeReversedPercent = (val: number): string => `${100 - val}%`;
  const makeCalc = (percents: number, minusPx: number): string =>
    `calc(${percents}% - ${minusPx}px)`;

  if (anchorPoint.horizontal === "left") {
    const xPercent = makePercent(x);
    style.left = xPercent;
  } else if (anchorPoint.horizontal === "right") {
    const xReversedPercent = makeReversedPercent(x);
    style.right = xReversedPercent;
  } else if (anchorPoint.horizontal === "center") {
    if ("number" === typeof width) {
      const halfWidth = width / 2;
      style.left = makeCalc(x, halfWidth);
    }
  }

  if (anchorPoint.vertical === "top") {
    const yPercent = makePercent(y);
    style.top = yPercent;
  } else if (anchorPoint.vertical === "bottom") {
    const yReversedPercent = makeReversedPercent(y);
    style.bottom = yReversedPercent;
  } else if (anchorPoint.vertical === "center") {
    if ("number" === typeof height) {
      const halfHeight = height / 2;
      style.top = makeCalc(x, halfHeight);
    }
  }

  return style;
}

const AnchoredComponent: FC<AnchoredComponentProps> = ({
  children,
  anchorPoint,
  x,
  y,
}) => {
  const divRef = useRef(null);

  return (
    <ReactResizeDetector handleHeight handleWidth targetRef={divRef}>
      {({
        width,
        height,
      }: {
        width: undefined | number;
        height: undefined | number;
      }) => (
        <div
          ref={divRef}
          style={getStyles(anchorPoint, { x, y }, { width, height })}
        >
          {children}
        </div>
      )}
    </ReactResizeDetector>
  );
};

export default AnchoredComponent;
