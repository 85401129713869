import { blockStore, resetBlocksState, updateBlockState } from "stores/block";
import { BlockRatioSnapshot } from "types";

function setReadyForAdjustingSize(blockKey: string, isIt: boolean) {
  updateBlockState({
    blockKey,
    updatedState: { readyForAdjustingSize: isIt },
  });
}

type SetRatioSnapshotOptions = {
  ignoreIfLowerAccuracy?: boolean;
};

function setRatioSnapshot(
  blockKey: string,
  ratioSnapshot: BlockRatioSnapshot,
  { ignoreIfLowerAccuracy }: SetRatioSnapshotOptions = {},
) {
  if (ignoreIfLowerAccuracy) {
    const states = blockStore.getState();
    const thisState = states[blockKey];

    const accuracy = thisState?.ratioSnapshot?.accuracy || 0;

    if (accuracy >= ratioSnapshot.accuracy) {
      return;
    }
  }

  updateBlockState({
    blockKey,
    updatedState: {
      ratioSnapshot,
    },
  });
}

function reset() {
  resetBlocksState();
}

const blockController = {
  setReadyForAdjustingSize,
  reset,
  setRatioSnapshot,
};

export default blockController;
