import { ImageSequenceVideoPausePoint } from "../components/ImageSequenceVideo";
import { VideoPausePoint } from "../components/Video";

type ReducedVideoPoints = { [key: string]: Omit<VideoPausePoint, "at"> };

export function reducePausePoints(
  points: (ImageSequenceVideoPausePoint | VideoPausePoint)[]
) {
  return points.reduce((acc: ReducedVideoPoints, curr) => {
    if ("at" in curr) {
      const { at, ...leftoverPoint } = curr;
      acc[at] = leftoverPoint;
      return acc;
    }

    const { atIndex, ...leftoverPoint } = curr;
    acc[atIndex] = leftoverPoint;
    return acc;
  }, {});
}

export function withinTolerance({
  a,
  b,
  tolerance,
}: {
  a: number;
  b: number;
  tolerance: number;
}) {
  const diff = Math.abs(a - b);
  const isWithinTolerance = diff <= tolerance;

  return isWithinTolerance;
}

export const videoEdgeTolerance = 0.1; // todo also there sould be a timeout - in case the event was not catched, and block did not change, it should change on timeout, that is, say, 110% (in case it freezes a bit) of video length, or research better ways of catching the "video ended" event
