import React, {
  ComponentProps,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";

import { videoStore } from "../stores/video";
import {
  ForwardOrReverseVideoData,
  VideoDirection,
  VideoLayer,
} from "../types";
import LayersOverVideo from "./LayersOverVideo";
import htmlVideoController from "../controllers/htmlVideo.controller";
import { videoEdgeTolerance, withinTolerance } from "../utils/commonVideo";
import { findResponsiveOptionByWidth } from "utils/responsive";

export type VideoPausePoint = {
  at: number;
};

export type VideoSource = {
  sourceProps: ComponentProps<"source">;
  key: string | number;
};

export type VideoProps = {
  isActive: boolean;
  onStartReached: Function;
  onEndReached: Function;
  videoKey: string;
  layers: VideoLayer[];

  onReadyForAdjustingSize: () => void;

  directions: {
    forward: boolean;
    backwards: boolean;
  };
  videos: {
    forward: ForwardOrReverseVideoData | null;
    backwards: ForwardOrReverseVideoData | null;
  };
};

const Video: FC<VideoProps> = ({
  layers,
  videos,
  videoKey,
  //directions,

  onReadyForAdjustingSize,

  isActive,
  onEndReached,
  onStartReached,
}) => {
  const { t } = useTranslation();
  const [maxWidth, setMaxWidth] = useState(
    () => window.innerWidth
  ); /* todo move resize detector and pass width as prop */
  //const [mouseIsOver, setMouseIsOver] = useState(false);

  const videosState = useStore(videoStore);

  const videoRef = useRef<HTMLVideoElement>(null);
  const reverseVideoRef = useRef<HTMLVideoElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const currentVideoState = useMemo(() => videosState[videoKey], [
    videosState,
    videoKey,
  ]);

  const playDirection: VideoDirection =
    currentVideoState?.direction || VideoDirection.FORWARD;
  const currentSecond: number = currentVideoState?.currentSecond || 0;

  // const forwardAllowed =
  //   directions.forward &&
  //   !!videos.forward &&
  //   videos.forward.sourceGroups.length > 0;
  // const backwardsAllowed =
  //   directions.backwards &&
  //   !!videos.backwards &&
  //   videos.backwards.sourceGroups.length > 0;

  useEffect(() => {
    setMaxWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const { current } = videoRef;

    if (current) {
      htmlVideoController.initForward(videoKey, current);

      return () => {
        //htmlVideoController.unmountForward(videoKey)
      };
    }
  }, [videoKey, maxWidth, videos.forward]);

  useEffect(() => {
    const { current: reverseCurrent } = reverseVideoRef;

    if (reverseCurrent) {
      htmlVideoController.initBackwards(videoKey, reverseCurrent);

      return () => {
        //htmlVideoController.unmountBackwards(videoKey)
      };
    }
  }, [videoKey, maxWidth, videos.backwards]);

  // useEffect(() => {
  //   if (!mouseIsOver) {
  //     return;
  //   }

  //   const onWheel = (e: WheelEvent) => {
  //     e.preventDefault();

  //     const scrollDirection = getScrollDirection(e);

  //     if ("up" === scrollDirection) {
  //       if (backwardsAllowed) {
  //         htmlVideoController.playBackwards(videoKey);
  //       }
  //     } else if ("down" === scrollDirection) {
  //       if (forwardAllowed) {
  //         htmlVideoController.playForward(videoKey);
  //       }
  //     }
  //   };

  //   window.addEventListener("wheel", onWheel, { passive: false });

  //   return () => window.removeEventListener("wheel", onWheel);
  // }, [mouseIsOver, videoKey, backwardsAllowed, forwardAllowed]);

  const { forwardSources, forwardPosterSrc } = useMemo(() => {
    const forwardSources =
      findResponsiveOptionByWidth(videos.forward?.sourceGroups || [], maxWidth)
        ?.sources || [];

    const forwardPosterSrc =
      findResponsiveOptionByWidth(videos.forward?.postersGroup || [], maxWidth)
        ?.src || "";

    return { forwardSources, forwardPosterSrc };
  }, [maxWidth, videos]);

  const { backwardsSources, backwardsPosterSrc } = useMemo(() => {
    const backwardsSources =
      findResponsiveOptionByWidth(
        videos.backwards?.sourceGroups || [],
        maxWidth
      )?.sources || [];

    const backwardsPosterSrc =
      findResponsiveOptionByWidth(
        videos.backwards?.postersGroup || [],
        maxWidth
      )?.src || "";

    return { backwardsSources, backwardsPosterSrc };
  }, [maxWidth, videos]);

  return (
    <ReactResizeDetector
      handleWidth
      targetRef={wrapperRef}
      onResize={(width: number | undefined) => {
        if ("number" === typeof width) {
          setMaxWidth((old) => {
            const newMax = Math.max(old, width);

            if (old !== newMax) {
              // todo
            }

            return newMax;
          });
        }
      }}
    >
      <div
        onScroll={(e) => e.preventDefault()}
        ref={wrapperRef}
        className={clsx("block-video", "video-wrapper")}
      >
        <img
          alt="poster"
          className={clsx("poster")}
          onError={() => {}}
          src={forwardPosterSrc}
          onLoad={() => {
            onReadyForAdjustingSize();
          }}
        />
        {!!maxWidth && (
          <>
            {!!videos.forward && (
              <video
                {...videos.forward.videoProps}
                ref={videoRef}
                className={clsx({
                  transparent: playDirection !== VideoDirection.FORWARD,
                })}
                poster={forwardPosterSrc}
                onEnded={(e) => {
                  if (!isActive) {
                    return;
                  }

                  if (videoRef.current) {
                    const states = videoStore.getState();
                    const videoState = states[videoKey];
                    if (videoState?.direction === VideoDirection.FORWARD) {
                      if (
                        withinTolerance({
                          a: videoRef.current.duration,
                          b: videoRef.current.currentTime,
                          tolerance: videoEdgeTolerance,
                        })
                      ) {
                        onEndReached();
                      }
                    }
                  }
                }}
              >
                {forwardSources.map((source) => (
                  <source {...source.sourceProps} key={source.key} />
                ))}
                {t(videos.forward.fallback)}
              </video>
            )}
            {!!videos.backwards && (
              <video
                {...videos.backwards.videoProps}
                ref={reverseVideoRef}
                poster={backwardsPosterSrc}
                className={clsx({
                  transparent: playDirection !== VideoDirection.REVERSE,
                })}
                onEnded={() => {
                  if (!isActive) {
                    return;
                  }

                  const states = videoStore.getState();
                  const videoState = states[videoKey];

                  if (
                    !videoState ||
                    !videoState.direction ||
                    !("number" === typeof videoState.currentSecond)
                  ) {
                    return;
                  }

                  if (videoState.direction === VideoDirection.REVERSE) {
                    if (
                      withinTolerance({
                        a: 0,
                        b: videoState.currentSecond,
                        tolerance: videoEdgeTolerance,
                      })
                    ) {
                      onStartReached();
                    }
                  }
                }}
              >
                {backwardsSources.map((source) => (
                  <source {...source.sourceProps} key={source.key} />
                ))}
                {t(videos.backwards.fallback)}
              </video>
            )}
            <LayersOverVideo
              activeFrameOrSecond={currentSecond}
              layers={layers}
            />
          </>
        )}
      </div>
    </ReactResizeDetector>
  );
};

export default Video;
