import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

type Indicator360Props = {};

export const Indicator360: React.FC<Indicator360Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="indicator-360" id="cont" data-pct="100">
      <svg id="svg" width="100%" height="100%" viewBox="0 0 200 200">
        <circle
          id="line"
          r="90"
          cx="50%"
          cy="50%"
          fill="transparent"
          strokeDasharray="565.48"
          strokeDashoffset="0"
        ></circle>
        <circle
          id="bar"
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          strokeDasharray="565.48"
          strokeDashoffset="0"
        ></circle>
      </svg>
      <div
        className="indicator-360__text explore"
        dangerouslySetInnerHTML={{ __html: t("Scroll to explore") }}
      />
    </div>
  );
};

export default Indicator360;
