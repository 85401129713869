import React, { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import "./styles.scss";

type PageTitleProps = {
  title: string;
  modifier?: string;
  imageSrc?: string;
};

const PageTitle: FC<PageTitleProps> = ({ modifier, title, imageSrc }) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "page-title",
        "swipe",
        modifier && `page-title--${modifier}`,
      )}
    >
      <h1 className="page-title__h1">{t(title)}</h1>
      {imageSrc && (
        <img src={imageSrc} alt={t(title)} className="page-title__image" />
      )}
    </div>
  );
};

export default PageTitle;
