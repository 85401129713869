import clsx from "clsx";
import imageSequenceVideoController from "controllers/imageSequenceVideo.controller";
import { useStore } from "effector-react";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Swipeable } from "react-swipeable";
import { KeyCode } from "types";
import { getScrollDirection } from "utils/uiEvents";
import { imageSequenceVideoStore } from "../../stores/imageSequenceVideo";

type Drag360ProcessorProps = {
  hasMomentum: boolean;

  sensitivity: number;
  onEndReached: () => void;
  onStartReached: () => void;
  imagesAmount: number;
  onActiveFrameIndexChanged: (newIndex: number) => void;
  looped?: boolean;
  imageSequenceVideoKey: string;

  isActive: boolean;
  swipeTouchDisabled?: boolean;
  //onDrag: (position: number) => void;

  ignoreEdgeFrame?: boolean;
};

const Drag360Processor: FC<Drag360ProcessorProps> = ({
  hasMomentum = false,

  children,
  sensitivity,
  onEndReached,
  onStartReached,
  imagesAmount,
  imageSequenceVideoKey,
  onActiveFrameIndexChanged,
  ignoreEdgeFrame,

  isActive,

  looped,
  swipeTouchDisabled = false,
}) => {
  const states = useStore(imageSequenceVideoStore);
  const currentSeqState = states[imageSequenceVideoKey];
  const activeIndex = currentSeqState?.activeFrameIndex || 0;

  const maxIndex = imagesAmount - 1;

  const [wasMovedOrPlayed, setWasMovedOrPlayed] = useState(false); // todo dupe logic. refactor

  const [dragStartedAt, setDragStartedAt] = useState<null | {
    activeIndex: number;
  }>(null);

  const onNext = useCallback(() => {
    if (!isActive) {
      return;
    }

    if (!wasMovedOrPlayed && activeIndex === maxIndex && !ignoreEdgeFrame) {
      onEndReached();
    }

    if (looped) {
      imageSequenceVideoController.playForward(imageSequenceVideoKey, {
        onLastFrame: onEndReached,
      });
    } else {
      imageSequenceVideoController.playForward(imageSequenceVideoKey);
    }
  }, [
    isActive,
    wasMovedOrPlayed,
    activeIndex,
    maxIndex,
    onEndReached,
    looped,
    imageSequenceVideoKey,
    ignoreEdgeFrame,
  ]);

  const onPrevious = useCallback(() => {
    if (!isActive) {
      return;
    }

    if (!wasMovedOrPlayed && activeIndex === 0 && !ignoreEdgeFrame) {
      onStartReached();
    }

    if (looped) {
      imageSequenceVideoController.playBackwards(imageSequenceVideoKey, {
        onFirstFrame: onStartReached,
      });
    } else {
      imageSequenceVideoController.playBackwards(imageSequenceVideoKey);
    }
  }, [
    isActive,
    wasMovedOrPlayed,
    activeIndex,
    looped,
    imageSequenceVideoKey,
    onStartReached,
    ignoreEdgeFrame,
  ]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!isActive) {
        return;
      }

      const key = e.key;

      if (([KeyCode.DOWN, KeyCode.RIGHT] as string[]).includes(key)) {
        onNext();
      } else if (([KeyCode.UP, KeyCode.LEFT] as string[]).includes(key)) {
        onPrevious();
      }
    };

    window.addEventListener("keydown", listener);

    return () => window.removeEventListener("keydown", listener);
  }, [isActive, onNext, onPrevious]);

  useEffect(() => {
    if (activeIndex !== 0 && activeIndex !== maxIndex) {
      setWasMovedOrPlayed(true);
    }
  }, [activeIndex, maxIndex]);

  useEffect(() => {
    if (!wasMovedOrPlayed) {
      return;
    }

    if (activeIndex <= 0) {
      setWasMovedOrPlayed(false);
    }

    if (activeIndex >= maxIndex) {
      setWasMovedOrPlayed(false);
    }
  }, [activeIndex, wasMovedOrPlayed, maxIndex]);

  return (
    <div
      onClick={() => {
        //imageSequenceVideoController.stopMomentum(imageSequenceVideoKey);
        //imageSequenceVideoController.pause(imageSequenceVideoKey);
      }}
      onWheel={(e) => {
        const direction = getScrollDirection(e);

        if ("up" === direction) {
          if (hasMomentum) {
            imageSequenceVideoController.addMomentum(
              imageSequenceVideoKey,
              -500,
            );
          } else {
            onActiveFrameIndexChanged(Math.max(0, activeIndex - 1));
          }
        } else if ("down" === direction) {
          if (hasMomentum) {
            imageSequenceVideoController.addMomentum(
              imageSequenceVideoKey,
              500,
            );
          } else {
            onActiveFrameIndexChanged(Math.min(maxIndex, activeIndex + 1));
          }
        }
      }}
    >
      <Swipeable
        trackTouch={!swipeTouchDisabled}
        trackMouse
        onSwipedDown={() => {
          onPrevious();
        }}
        onSwipedUp={() => {
          onNext();
        }}
        onSwiping={(e) => {
          if (!isActive) {
            return;
          }

          let dragStartedAtIndex = dragStartedAt?.activeIndex || 0;

          if (e.first) {
            setDragStartedAt({
              activeIndex,
            });

            dragStartedAtIndex = activeIndex;
          }
          //const newIndex = Math.round(-e.deltaX * sensitivity) + dragStartedAtIndex;

          const theVal =
            e.deltaX < 0
              ? Math.ceil(-e.deltaX * sensitivity)
              : Math.floor(-e.deltaX * sensitivity);
          const newIndex = theVal + dragStartedAtIndex;
          if (activeIndex === newIndex) return;

          if (looped) {
            if (hasMomentum) {
              alert("TODO: Not implemented");
            } else {
              const rangedIndex =
                newIndex % imagesAmount; /* yes, imagesAmount, not maxIndex */

              let absIndex = Math.abs(rangedIndex);

              if (rangedIndex < 0) {
                absIndex = imagesAmount - absIndex;
              }

              onActiveFrameIndexChanged(absIndex);
            }
          } else {
            if (hasMomentum) {
              //const direction = e.deltaX < 0 ? 1 : -1;

              imageSequenceVideoController.addMomentum(
                imageSequenceVideoKey,
                -e.deltaX * e.velocity * 75,
              );
            } else {
              if (newIndex >= 0 && newIndex <= maxIndex) {
                onActiveFrameIndexChanged(newIndex);
              }

              if (newIndex <= 0) {
                onStartReached();
                onActiveFrameIndexChanged(0);
              }

              if (newIndex >= maxIndex) {
                onEndReached();
                onActiveFrameIndexChanged(maxIndex);
              }
            }
          }
        }}
        style={{ width: "100%", position: "relative" }}
        className={clsx({ dragArea: true }, "frame-" + activeIndex)}
        // onMouseDown={(e) => {
        //   const { clientX } = e;
        //   setDragStartedAt({ clientX, activeIndex });
        // }}
        // onMouseMove={(e) => {
        //   if (!dragStartedAt) {
        //     return;
        //   }
        //   const { clientX } = e;

        //   const practicalDiff = clientX - dragStartedAt.clientX;

        //   const sensitivityDiff = practicalDiff * sensitivity;

        //   const roundedDiff = Math.round(sensitivityDiff);

        //   const newIndex = dragStartedAt.activeIndex + roundedDiff;

        //   if (newIndex <= 0) {
        //     onActiveFrameIndexChanged(0);
        //     onStartReached();
        //   } else if (newIndex > maxIndex) {
        //     onActiveFrameIndexChanged(maxIndex);
        //     onEndReached();
        //   } else {
        //     onActiveFrameIndexChanged(newIndex);
        //   }
        // }}
        // onMouseUp={() => {
        //   setDragStartedAt(null);
        // }}
        // onMouseLeave={() => {
        //   setDragStartedAt(null);
        // }}
      >
        {children}
      </Swipeable>
    </div>
  );
};

export default Drag360Processor;
