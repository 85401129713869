import clsx from "clsx";
import htmlVideoController from "controllers/htmlVideo.controller";
import imageSequenceVideoController from "controllers/imageSequenceVideo.controller";
import { useStore } from "effector-react";
import React, { forwardRef, ReactNode, useEffect } from "react";
import { containerStore } from "stores/container";
import { BlockType, ReducedBlocks, VideoEdge } from "types";

type ScreenProps = {
  containerKey: string;
  blocks: ReducedBlocks;
  children: ReactNode;
};

const PREPARE_ADJACENT_BLOCKS_TIMEOUT_MS = 280;

const Screen = forwardRef<HTMLDivElement, ScreenProps>((props, ref) => {
  const { children, containerKey: screenKey, blocks } = props;

  const containerStates = useStore(containerStore);

  const screenState = containerStates[screenKey];

  const currentBlock =
    (!!screenState?.currentBlock && blocks[screenState?.currentBlock]) || null;

  let cut = true;
  if (currentBlock) {
    if (currentBlock?.blockType === BlockType.CUSTOM) {
      cut = false;
    }
  }

  // todo maybe not the best idea.
  // It works because in our case all video/image sequence keys are same as block keys,
  // but those can be different,
  // also not all blocks are videos/image sequences.
  // Should be fixed.

  // by the way, we can just use block key like in stacked block

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      //console.log(`useEffect -> currentBlock?.nextBlockKey `, currentBlock?.nextBlockKey,);

      if (currentBlock?.nextBlockKey) {
        htmlVideoController.setProgressToEdge(
          currentBlock.nextBlockKey,
          VideoEdge.START,
          { ignoreIfNotFound: true },
        );

        imageSequenceVideoController.stopMomentum(currentBlock.nextBlockKey, {
          ignoreIfNotFound: true,
        });

        imageSequenceVideoController.setProgressToEdge(
          currentBlock.nextBlockKey,
          VideoEdge.START,
          { ignoreIfNotFound: true },
        );
      }
    }, PREPARE_ADJACENT_BLOCKS_TIMEOUT_MS);

    return () => {
      clearInterval(timeoutId);
    };
  }, [currentBlock?.nextBlockKey]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      //console.log(`useEffect -> currentBlock?.previousBlockKey `, currentBlock?.previousBlockKey);
      if (currentBlock?.previousBlockKey) {
        htmlVideoController.setProgressToEdge(
          currentBlock.previousBlockKey,
          VideoEdge.FINISH,
          { ignoreIfNotFound: true },
        );

        imageSequenceVideoController.stopMomentum(
          currentBlock.previousBlockKey,
          {
            ignoreIfNotFound: true,
          },
        );

        imageSequenceVideoController.setProgressToEdge(
          currentBlock.previousBlockKey,
          VideoEdge.FINISH,
          { ignoreIfNotFound: true },
        );
      }
    }, PREPARE_ADJACENT_BLOCKS_TIMEOUT_MS);

    return () => {
      clearInterval(timeoutId);
    };
  }, [currentBlock?.previousBlockKey]);

  return (
    <div
      ref={ref}
      onScroll={(e) => {
        e.preventDefault();
      }}
      className={clsx("content", {
        cut,
      })}
    >
      {children}
    </div>
  );
});

export default Screen;
