import React, { FC } from "react";
import clsx from "clsx";
import { ResponsiveFrame } from "../../types";

type FramesProps = {
  responsiveFrames: ResponsiveFrame[];
  activeFrameIndex: number;

  onReadyForAdjustingSize: () => void;
};

const Frames: FC<FramesProps> = ({
  responsiveFrames,
  activeFrameIndex,
  onReadyForAdjustingSize,
}) => {
  return (
    <>
      {responsiveFrames.map((frame, index) => {
        const { imgProps, key } = frame;

        const isActive = index === activeFrameIndex;

        return (
          <img
            alt=""
            key={key}
            {...imgProps}
            className={clsx(
              "block-image-sequence",
              {
              transparent: !isActive,
              "image-sequence-img": true,
            })}
            onLoad={() => {
              if (!isActive) {
                return;
              }

              onReadyForAdjustingSize();
            }}
            onError={() => {
              if (!isActive) {
                return;
              }

              onReadyForAdjustingSize();
            }}
          />
        );
      })}
    </>
  );
};

export default Frames;
