import React, { FC, useEffect, useState } from "react";

import configActions from "actions/configActions";
import { Config } from "types";
import i18n from "utils/i18next";
import { ConfigProvider } from "contexts/configContext";

const ConfigProcessor: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<null | Config>(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setLoading(true);

    configActions
      .get()
      .then((result) => {
        // todo

        if ("data" in result) {
          setConfig(result.data);
          i18n.init(result.data);
        }

        if ("errorMessage" in result) {
          setErrorMessage(result.errorMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <></>;
  }

  if (errorMessage) {
    return <>Error while loading config: ${errorMessage}</>;
  }

  return <ConfigProvider value={config}>{children}</ConfigProvider>;
};

export default ConfigProcessor;
