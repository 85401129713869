import {
  StackedBlockState,
  updateStackedBlockState,
} from "stores/stackedBlock";

function init(stackedBlockKey: string, initialState: StackedBlockState) {
  updateStackedBlockState({
    stackedBlockKey,
    updatedState: initialState,
  });
}

type ScrollToIndexOptions = {
  withAnimation?: boolean;
};

function scrollToIndex(
  stackedBlockKey: string,
  newIndex: number,
  options: ScrollToIndexOptions = {},
) {
  // see StackedBlock for scrolling logic itself

  const scrollWithAnimation = options?.withAnimation || false;

  updateStackedBlockState({
    stackedBlockKey,
    updatedState: {
      index: newIndex,
      settingScrollPosition: true,
      scrollWithAnimation,
    },
  });
}

function onScrolledToIndex(stackedBlockKey: string) {
  updateStackedBlockState({
    stackedBlockKey,
    updatedState: { settingScrollPosition: false, scrollWithAnimation: false },
  });
}

function setIndexNoScroll(stackedBlockKey: string, newIndex: number) {
  updateStackedBlockState({
    stackedBlockKey,
    updatedState: { index: newIndex },
  });
}

const stackedBlockController = {
  setIndexNoScroll,
  scrollToIndex,
  onScrolledToIndex,
  init,
};

export default stackedBlockController;
