import React, {
  FC,
  ReactComponentElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useStore } from "effector-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Swipeable } from "react-swipeable";

import { getScrollDirection } from "utils/uiEvents";

import { tabStore, updateTabState, UpdateTabPayload } from "stores/tabs";

import "./styles.scss";
import { KeyCode } from "types";
import { containerStore } from "stores/container";

import { useThrottleFn } from "@react-cmpt/use-throttle";

type ModifierProps = "transparent" | "dark" | "fixed";

type LinkProps = {
  text: string;
  url: string;
};

type TabItemProps = {
  tabName?: string;
  title: string;
  content: string;
  linksBlocks?: LinkProps[];
  imageComponent?: ReactComponentElement<"svg">;
  tabModifier?: string;
};

type TabLayerProps = {
  blockKey: string;
  containerKey: string;

  tabs: TabItemProps[];
  onStartReached?: Function;
  onEndReached?: Function;
  modifier?: ModifierProps | ModifierProps[];
  anchorTabs?: {
    tabName: string;
    onClick: () => void;
  }[];
  tabKey?: UpdateTabPayload["tabKey"];
};

type TabButtonProps = {
  tabName: TabItemProps["tabName"];
  onClick: () => void;
  isActive?: boolean;
};

const TabButton: FC<TabButtonProps> = ({
  tabName,
  onClick,
  isActive = false,
}) => {
  return (
    <button
      className={clsx("tab-layer__tab-item", {
        "tab-layer__tab-item--active": isActive,
      })}
      onClick={onClick}
    >
      {tabName}
    </button>
  );
};

const TabLayer: FC<TabLayerProps> = ({
  tabs,
  onStartReached,
  onEndReached,
  modifier,
  anchorTabs,
  tabKey,

  containerKey,
  blockKey,
}) => {
  const { t } = useTranslation();
  const tabState = useStore(tabStore);
  const currentState = useMemo(() => {
    return tabKey ? tabState[tabKey] : undefined;
  }, [tabState, tabKey]);

  const activeTabIndex = useMemo(() => {
    return currentState?.currentTab || 0;
  }, [currentState]);

  const containerStates = useStore(containerStore);
  const thisContainer = containerStates[containerKey];

  const isActive = thisContainer?.currentBlock === blockKey;

  const [animStart, setAnimStart] = useState<boolean>(true);

  const timeout = 1000;

  const { callback } = useThrottleFn(
    (direction?: string) => {
      if (tabKey) {
        if (direction === "down") {
          onPrevious(tabKey);
        } else if (direction === "up") {
          onNext(tabKey);
        }
      }
    },
    550,
    { leading: true },
  );

  const animationCallback = (callback: Function) => {
    setAnimStart(false);
    setTimeout(() => {
      callback();
      setAnimStart(true);
    }, timeout);
  };

  const onPrevious = useCallback(
    (tabKey: string) => {
      animationCallback(() => {
        if (activeTabIndex !== tabs.length - 1) {
          updateTabState({
            tabKey,
            updatedState: {
              currentTab: activeTabIndex + 1,
            },
          });
        } else if (onEndReached) {
          onEndReached();
        }
      });
    },
    [activeTabIndex, onEndReached, tabs.length],
  );

  const onNext = useCallback(
    (tabKey: string) => {
      animationCallback(() => {
        if (activeTabIndex !== 0) {
          updateTabState({
            tabKey,
            updatedState: {
              currentTab: activeTabIndex - 1,
            },
          });
        } else if (onStartReached) {
          onStartReached();
        }
      });
    },
    [activeTabIndex, onStartReached],
  );

  const handleOnWheel = useCallback(
    (e: WheelEvent | React.WheelEvent) => {
      if (!isActive) {
        return;
      }

      if (tabKey) {
        e.preventDefault();
        e.stopPropagation();

        callback(getScrollDirection(e));
      }
    },
    [callback, isActive, tabKey],
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!isActive) {
        return;
      }

      if (!tabKey) {
        return;
      }

      const key = e.key;

      if (([KeyCode.DOWN, KeyCode.RIGHT] as string[]).includes(key)) {
        onPrevious(tabKey);
      } else if (([KeyCode.UP, KeyCode.LEFT] as string[]).includes(key)) {
        onNext(tabKey);
      }
    };

    window.addEventListener("keydown", listener);

    return () => window.removeEventListener("keydown", listener);
  }, [tabKey, onNext, onPrevious, isActive]);

  return (
    <Swipeable
      trackMouse
      trackTouch
      onSwipedUp={() => {
        if (!isActive) {
          return;
        }

        if (tabKey) {
          onPrevious(tabKey);
        }
      }}
      onSwipedDown={() => {
        if (!isActive) {
          return;
        }

        if (tabKey) {
          onNext(tabKey);
        }
      }}
    >
      <div
        className={clsx(
          "tab-layer",
          modifier && Array.isArray(modifier)
            ? modifier.map((mod) => mod && `tab-layer--${mod}`)
            : `tab-layer--${modifier}`,
        )}
        onWheel={(e) => tabs.length > 1 && handleOnWheel(e)}
        onKeyDown={(e) => {
          // if (!isActive) {
          //   return;
          // }
          // if (tabKey) {
          //   const key = e.key;
          //   if (([KeyCode.DOWN, KeyCode.RIGHT] as string[]).includes(key)) {
          //     onPrevious(tabKey);
          //   } else if (([KeyCode.UP, KeyCode.LEFT] as string[]).includes(key)) {
          //     onNext(tabKey);
          //   }
          // }
        }}
      >
        <div className="tab-layer__container">
          {tabs.map((tab, index) => {
            return (
              <div
                key={index}
                className={clsx("tab-layer__content-wrapper", {
                  "tab-layer__content-wrapper--active":
                    activeTabIndex === index,
                  "active-animation": activeTabIndex === index && isActive,
                })}
              >
                <div
                  className={clsx(
                    "tab-layer__content-item",
                    tab.tabModifier,
                    !tab.imageComponent && "tab-layer__content-item--once",
                    animStart ? "in" : "out",
                  )}
                >
                  {tab.imageComponent && (
                    <div className="tab-layer__column tab-layer__column--image">
                      <div className="tab-layer__image-container fade">
                        {tab.imageComponent}
                      </div>
                    </div>
                  )}
                  <div className="tab-layer__column">
                    <h1
                      className="tab-layer__title swipe"
                      dangerouslySetInnerHTML={{ __html: t(tab.title) }}
                    />
                    <div
                      className="tab-layer__content fade"
                      dangerouslySetInnerHTML={{ __html: t(tab.content) }}
                    />
                    {tab.linksBlocks && tab.linksBlocks.length > 0 && (
                      <ol
                        className={clsx("tab-layer__links-list", "fade", {
                          "tab-layer__links-list--list-none": !(
                            tab.linksBlocks.length > 1
                          ),
                        })}
                      >
                        {tab.linksBlocks.map((link, index) => (
                          <li className="tab-layer__link-item" key={index}>
                            <a
                              className="tab-layer__link"
                              href={link.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t(link.text)}
                            </a>
                          </li>
                        ))}
                      </ol>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {tabs.length > 1 && tabKey && (
          <div className="tab-layer__tab-list">
            {tabs.map((tab, index) => {
              return (
                tab.tabName && (
                  <TabButton
                    key={index}
                    tabName={t(tab.tabName)}
                    isActive={activeTabIndex === index}
                    onClick={() =>
                      animationCallback(() => {
                        updateTabState({
                          tabKey,
                          updatedState: {
                            currentTab: index,
                          },
                        });
                      })
                    }
                  />
                )
              );
            })}
          </div>
        )}
        {anchorTabs && (
          <div className="tab-layer__tab-list">
            {anchorTabs.map((tab, index) => {
              return (
                tab.tabName && (
                  <TabButton
                    key={index}
                    tabName={t(tab.tabName)}
                    onClick={() => animationCallback(() => tab.onClick())}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    </Swipeable>
  );
};

export default TabLayer;
