import React, { FC, DetailedHTMLProps, InputHTMLAttributes } from "react";
import { useForm, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import containerController from "controllers/containerController";
import { updateContainerState } from "stores/container";

import PageTitle from "components/PageTitle";

import titleImageSrc from "assets/svg/contact-form-title.svg";

import "./styles.scss";

const purposesList = [
  {
    name: "value-1",
    label: "Improving early recognition of patient deterioration",
  },
  {
    name: "value-2",
    label: "Benefits of a fully connected workflow",
  },
  {
    name: "value-3",
    label: "Implement an Early Warning Score Protocol",
  },
  {
    name: "value-4",
    label:
      "Want to learn more about the Hillrom Welch Allyn Connex Spot Monitor",
  },
  {
    name: "value-5",
    label: "Other (please specify)",
  },
];

type Inputs = {
  first_name: string;
  last_name: string;
  organization: string;
  role: string;
  address: string;
  zipcode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
};

type PurposesItemProps = {
  label: string;
  name: string;
};

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  placeholder: string;
  name: string;
  register: ({ required }: { required?: boolean }) => RefReturn;
  error?: FieldErrors;
  errorText?: string;
  type?: string;
};

const Input: FC<InputProps> = ({
  name,
  placeholder,
  register,
  required,
  error,
  errorText,
  type = "text",
}) => {
  const { t } = useTranslation();

  return (
    <div className="contact-form__input-container">
      <input
        type={type}
        placeholder={t(placeholder)}
        aria-label={t(placeholder)}
        className="contact-form__input"
        name={name}
        ref={register({ required })}
        aria-labelledby={error && error[name] && errorText && `error-${name}`}
      />
      {error && error[name] && errorText && (
        <div
          className="contact-form__input-error"
          role="alert"
          id={`error-${name}`}
        >
          {t(errorText)}
        </div>
      )}
    </div>
  );
};

const ContactForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    //console.log(data);

    containerController.pauseActiveBlock("1_container");

    updateContainerState({
      containerKey: "1_container",
      updatedState: {
        currentBlock: "submit_finish",
      },
    });

    // TODO: convert data to FormData and fetch
    let formData = new FormData();
    fetch("/contact", {
      method: "POST",
      body: formData,
    }).then((response) => response.json());
    reset();
  };

  return (
    <div className="contact-form anim01 in anim-delay--1">
      <div className="contact-form__inner">
        <div className="contact-form__container content-container">
          <PageTitle
            title="You are interested in"
            imageSrc={titleImageSrc}
          />
          <iframe
            src="https://go.pardot.com/l/8232/2020-12-08/b9qtlv"
            width="100%"
            height="800px"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
