import React, { FC, useMemo, SetStateAction, useState } from "react";

import SingleFrameWrapper from "components/SingleFrameWrapper";

import { Layer, ResponsiveSingleFrame } from "types";
import { findResponsiveOptionByWidth } from "utils/responsive";
import LayersOverVideo from "components/LayersOverVideo";

type SingleFrameBlockProps = {
  isPrevious: boolean;
  frameGroup: ResponsiveSingleFrame[];
  isActive: boolean;
  onEndReached: () => void;
  onStartReached: () => void;
  layers: Layer[];
  onReadyForAdjustingSize: () => void;
};

const SingleFrameBlock: FC<SingleFrameBlockProps> = ({
  frameGroup,
  isActive,
  onEndReached,
  onStartReached,
  layers,
  onReadyForAdjustingSize,
}) => {
  const [maxWidth, setMaxWidth] = useState(0);

  const currentImgProps = useMemo(() => {
    const current = findResponsiveOptionByWidth(frameGroup, maxWidth);

    return current?.imgProps;
  }, [maxWidth, frameGroup]);

  const convertedLayers = useMemo(
    () => layers.map((layer) => ({ ...layer, from: 0, to: 0 })),
    [layers]
  );

  return (
    <SingleFrameWrapper
      isActive={isActive}
      onEndReached={onEndReached}
      onStartReached={onStartReached}
      onResizeDetected={(width: SetStateAction<number>) => setMaxWidth(width)}
    >
      {currentImgProps && (
        <img
          style={{
            verticalAlign: "middle",
            width: "100%",
          }}
          alt=""
          {...currentImgProps}
          onLoad={() => {
            onReadyForAdjustingSize();
          }}
          onError={() => {
            onReadyForAdjustingSize();
          }}
        />
      )}
      {layers && (
        <LayersOverVideo activeFrameOrSecond={0} layers={convertedLayers} />
      )}
    </SingleFrameWrapper>
  );
};

export default SingleFrameBlock;
