import React, { ComponentProps, FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import "./styles.scss";

type ButtonProps = {
  text: string;
  onClick: ComponentProps<"button">["onClick"];
  modifier?: string;
};

type BackgroundLayerProps = {
  title: string;
  buttons?: ButtonProps[];
  helptext?: string;
  modifier?: string;
  subtitle?: string;
  imageSrc?: string;
  className?: string;
};

const BackgroundLayer: FC<BackgroundLayerProps> = ({
  title,
  buttons,
  helptext,
  modifier,
  subtitle,
  imageSrc,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "background-layer",
        className,
        modifier && `background-layer--${modifier}`,
      )}
    >
      {imageSrc && (
        <img
          src={imageSrc}
          alt={t(title)}
          className="background-layer__image"
        />
      )}
      <h1
        className="background-layer__title"
        dangerouslySetInnerHTML={{ __html: t(title) }}
      ></h1>
      {buttons && (
        <div className="background-layer__buttons-group">
          {buttons.map((button, index) => (
            <button
              className={clsx(
                "background-layer__button button",
                button.modifier && `button--${button.modifier}`,
              )}
              key={index}
              onClick={button.onClick}
            >
              {t(button.text)}
            </button>
          ))}
        </div>
      )}
      {helptext && (
        <div className="background-layer__helptext">{t(helptext)}</div>
      )}
      {subtitle && (
        <div className="background-layer__subtitle">{t(subtitle)}</div>
      )}
    </div>
  );
};

export default BackgroundLayer;
