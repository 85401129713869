import AnchoredComponent, { AnchorPoint } from "components/AnchoredComponent";
import { useStore } from "effector-react";
import React, { ComponentProps, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Container, { ContainerProps } from "./components/Container";
import MediaLibraryComponent from "components/MediaLibrary";
import ContactFormComponent from "components/ContactForm";
import Hotspot from "components/Hotspot";
import ContentLayer from "components/LayersOverVideo/ContentLayer";
import BackgroundLayer from "components/LayersOverVideo/BackgroundLayer";
import TabLayer from "components/LayersOverVideo/TabLayer";
import ScanDragLayer from "components/LayersOverVideo/ScanDragLayer";

import htmlVideoController from "./controllers/htmlVideo.controller";
import imageSequenceVideoController from "./controllers/imageSequenceVideo.controller";
import { updateContainerState } from "./stores/container";
import { imageSequenceVideoStore } from "./stores/imageSequenceVideo";
import { updateTabState } from "./stores/tabs";

import {
  Block,
  BlockAction,
  BlockType,
  Key,
  ReducedBlocks,
  ResponsiveFrame,
  ResponsiveFrameGroup,
  ResponsivePoster,
  ResponsiveSingleFrame,
  ResponsiveImageFadeGroup,
} from "./types";
import containerController from "controllers/containerController";
import StepsLayer from "components/StepsLayer";
import Indicator360 from "components/Indicator360";

import { ReactComponent as SignificantTimeSavingsSVG } from "assets/svg/significant_time_savings.svg";
import { ReactComponent as SignificantTimeSavingsSVG2 } from "assets/svg/significant_time_savings_connectivity.svg";
import { ReactComponent as ProvenConnectivitySVG } from "assets/svg/proven_connectivity.svg";
import { ReactComponent as ReductionSVG } from "assets/svg/reduction.svg";
import { ReactComponent as DataSecuritySVG } from "assets/svg/data_security.svg";
import thankYouImageSrc from "assets/svg/contact-form-title.svg";
import WindowWidth from "components/WindowWidth";
import stackedBlockController from "controllers/stackedBlock.controller";

const TodoFullScreen = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    ></div>
  );
};

const TodoComponent = ({ number }: { number: number }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      TODO {number}
    </div>
  );
};

const hotspotData360: HotspotData[] = [
  {
    x: [
      955,
      932,
      911,
      892,
      876,
      861,
      849,
      840,
      834,
      830,
      829,
      832,
      838,
      847,
      859,
      875,
      894,
      917,
      942,
      971,
      1003,
      1037,
      1077,
      1119,
      1164,
      1209,
      1256,
      1304,
      1351,
      1399,
      1446,
      1493,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      1745,
      1723,
      1699,
      1673,
      1647,
      1618,
      1589,
      1559,
      1529,
      1497,
      1466,
      1437,
      1408,
      1379,
      1349,
      1320,
      1291,
      1262,
      1234,
      1206,
      1178,
      1151,
      1124,
      1097,
      1072,
      1047,
      1023,
      999,
      977,
      955,
    ],
    y: [
      418,
      421,
      424,
      428,
      432,
      436,
      440,
      444,
      449,
      454,
      459,
      464,
      468,
      473,
      478,
      483,
      488,
      493,
      497,
      501,
      505,
      509,
      515,
      521,
      526,
      531,
      536,
      540,
      543,
      546,
      548,
      550,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      466,
      459,
      452,
      445,
      439,
      433,
      427,
      421,
      416,
      411,
      406,
      405,
      404,
      403,
      402,
      401,
      401,
      401,
      401,
      402,
      402,
      403,
      404,
      405,
      407,
      409,
      410,
      413,
      415,
      418,
    ],
    w: [
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      83,
      83,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      78,
      72,
      66,
      60,
      53,
      47,
      41,
      35,
      29,
      23,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      22,
      29,
      35,
      41,
      47,
      53,
      60,
      66,
      72,
      78,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      85,
      84,
      84,
      84,
    ],
    h: [
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      83,
      83,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      78,
      72,
      66,
      60,
      53,
      47,
      41,
      35,
      29,
      23,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      22,
      29,
      35,
      41,
      47,
      53,
      60,
      66,
      72,
      78,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      85,
      84,
      84,
      84,
    ],
  },
  {
    x: [
      1021,
      1008,
      996,
      986,
      978,
      972,
      968,
      966,
      966,
      968,
      973,
      980,
      989,
      1001,
      1014,
      1031,
      1049,
      1072,
      1098,
      1125,
      1155,
      1185,
      1217,
      1251,
      1285,
      1319,
      1354,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      1381,
      1356,
      1331,
      1307,
      1283,
      1259,
      1236,
      1214,
      1192,
      1171,
      1150,
      1133,
      1117,
      1101,
      1086,
      1071,
      1058,
      1045,
      1033,
      1021,
    ],
    y: [
      661,
      664,
      668,
      672,
      676,
      680,
      685,
      689,
      694,
      699,
      704,
      709,
      713,
      718,
      722,
      727,
      731,
      738,
      745,
      751,
      757,
      763,
      768,
      773,
      778,
      782,
      785,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      666,
      663,
      659,
      656,
      653,
      651,
      648,
      646,
      644,
      642,
      640,
      642,
      644,
      646,
      648,
      650,
      652,
      655,
      658,
      661,
    ],
    w: [
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      85,
      84,
      84,
      84,
      83,
      84,
      84,
      84,
      84,
      84,
      78,
      72,
      66,
      60,
      54,
      48,
      42,
      35,
      29,
      23,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      23,
      29,
      35,
      41,
      48,
      53,
      60,
      66,
      72,
      78,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
    ],
    h: [
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      85,
      84,
      84,
      84,
      83,
      84,
      84,
      84,
      84,
      84,
      78,
      72,
      66,
      60,
      54,
      48,
      42,
      35,
      29,
      23,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      23,
      29,
      35,
      41,
      48,
      53,
      60,
      66,
      72,
      78,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
    ],
  },
  {
    x: [
      1528,
      1524,
      1518,
      1512,
      1504,
      1495,
      1485,
      1474,
      1462,
      1449,
      1436,
      1422,
      1407,
      1396,
      1384,
      1372,
      1360,
      1348,
      1336,
      1324,
      1313,
      1302,
      1291,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      1174,
      1180,
      1188,
      1197,
      1207,
      1219,
      1232,
      1246,
      1261,
      1276,
      1293,
      1313,
      1333,
      1354,
      1374,
      1394,
      1413,
      1431,
      1449,
      1465,
      1479,
      1492,
      1503,
      1512,
      1519,
      1524,
      1527,
      1528,
    ],
    y: [
      793,
      789,
      786,
      782,
      779,
      776,
      773,
      770,
      767,
      765,
      762,
      760,
      759,
      760,
      762,
      764,
      766,
      768,
      771,
      774,
      777,
      780,
      784,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      843,
      843,
      842,
      842,
      841,
      840,
      839,
      838,
      836,
      835,
      832,
      833,
      833,
      833,
      832,
      831,
      829,
      827,
      825,
      823,
      820,
      816,
      813,
      809,
      805,
      801,
      797,
      793,
    ],
    w: [
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      78,
      72,
      65,
      59,
      54,
      47,
      41,
      35,
      29,
      22,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      23,
      29,
      36,
      41,
      48,
      54,
      60,
      66,
      72,
      77,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      85,
      84,
      83,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
    ],
    h: [
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      78,
      72,
      65,
      59,
      54,
      47,
      41,
      35,
      29,
      22,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      23,
      29,
      36,
      41,
      48,
      54,
      60,
      66,
      72,
      77,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      85,
      84,
      83,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
      84,
    ],
  },
];

function onGetInTouch() {
  stackedBlockController.scrollToIndex("media_library&contact_form", 1);
  containerController.setActiveBlock(
    "1_container",
    "media_library&contact_form",
  );
}

const seqFps = 25;

function createFrameGroup(
  frames: ResponsiveFrame[],
  key: Key,
  inclusiveFrom: null | number,
  nonInclusiveTo: null | number,
): ResponsiveFrameGroup {
  return {
    frames,
    key,
    inclusiveFrom,
    nonInclusiveTo,
  };
}

/*

the resolution syntax is as following:
file@0.5x.jpg  (480x270)
file@0.666x.jpg (640x360)
file.jpg  (default: 960x540)
file@1.333x.jpg  (1280x720)
file@2x.jpg  (1920x1080)

*/

const resolutions = [
  {
    ending: "@0.5x",
    form: 0,
    to: 270,
  },
  {
    ending: "@0.666x",
    form: 270,
    to: 360,
  },
  {
    ending: "",
    form: 360,
    to: 540,
  },
  {
    ending: "@1.333x",
    form: 540,
    to: 720,
  },
  {
    ending: "@1.333x",
    form: 720,
    to: null,
  },
];

function generatePostersGroup(
  generateSrcFromEnding: (ending: string) => string,
): ResponsivePoster[] {
  return resolutions.map((resolution) => {
    return {
      inclusiveFrom: resolution.form,
      nonInclusiveTo: resolution.to,
      src: generateSrcFromEnding(resolution.ending),
    };
  });
}

function generateVideo(name: string) {
  return {
    fallback: htmlVideoFallback,
    postersGroup: generatePostersGroup(
      (ending) => `./demo/${name}/${name}${ending}.jpg`,
    ),
    sourceGroups: generateVideoSourceGroups([
      (ending: string): string => `./demo/${name}/${name}${ending}.mp4`,
      (ending: string): string => `./demo/${name}/${name}${ending}.webm`,
    ]),
    videoProps,
  };
}

function generateVideos(name: string, revEnding: string | null = "-rev") {
  const forward = generateVideo(name);

  let backwards: ReturnType<typeof generateVideo> | null = null;

  if ("string" === typeof revEnding) {
    backwards = generateVideo(name + revEnding);
  }

  return { backwards, forward };
}

function getVideoSourceType(src: string) {
  if (src.endsWith(".mp4")) {
    return "video/mp4";
  } else if (src.endsWith(".webm")) {
    return "video/webm";
  }
}

function generateVideoSourceGroups(
  generateSrcFromEndingFunctions: ((ending: string) => string)[],
) {
  return resolutions.map((resolution, index) => {
    const sources = generateSrcFromEndingFunctions
      .map((generateSrcFromEnding) => generateSrcFromEnding(resolution.ending))
      .map((src) => {
        return {
          key: src,
          sourceProps: {
            src,
            type: getVideoSourceType(src),
          },
        };
      });

    return {
      inclusiveFrom: resolution.form,
      nonInclusiveTo: resolution.to,
      key: index,
      sources,
    };
  });
}

function generateResponsiveFrameGroups(
  amountOfFrames: number,
  generateSrc: (index: number, ending: string) => string,
): ResponsiveFrameGroup[] {
  return resolutions.map((resolution, index) => {
    const frames = Array(amountOfFrames)
      .fill("")
      .map((_, index) => {
        const frameKey = `${index}_frame`;

        const frameGroup: ResponsiveFrame = {
          key: frameKey,
          imgProps: {
            alt: frameKey,
            src: generateSrc(index, resolution.ending),
          },
        };

        return frameGroup;
      });

    return createFrameGroup(frames, index, resolution.form, resolution.to);
  });
}

function generateSingleFrameGroup(name: string): ResponsiveSingleFrame[] {
  const generateSrc = (index: number, ending: string): string =>
    `./demo/${name}/${name}${ending}.jpg`;

  const groups = generateResponsiveFrameGroups(1, generateSrc);

  return groups.map((group) => {
    const { frames, inclusiveFrom, key, nonInclusiveTo } = group;
    const result: ResponsiveSingleFrame = {
      nonInclusiveTo,
      inclusiveFrom,
      imgProps: { ...frames[0].imgProps },
    };
    return result;
  });
}

function generateImageFadesGroup(
  previous: string,
  current: string,
  next: string,
): ResponsiveImageFadeGroup[] {
  return resolutions.map((resolution, index) => {
    const images = [previous, current, next]
      .map((name) => {
        return `./demo/${name}/${name}${resolution.ending}.jpg`;
      })
      .map((src, index) => {
        const frameKey = `${index}_frame`;
        return {
          key: src,
          imgProps: {
            src,
            alt: frameKey,
          },
        };
      });

    return {
      inclusiveFrom: resolution.form,
      nonInclusiveTo: resolution.to,
      key: index,
      images,
    };
  });
}

const frames360: ResponsiveFrameGroup[] = generateResponsiveFrameGroups(
  80,
  (index, ending) =>
    `./demo/Mod00_USP02_360/Mod00_USP02_360_${index}${ending}.jpg`,
);

const GoBackButtonLayer = () => {
  return (
    <>
      <button
        className="button"
        onClick={() => {
          htmlVideoController.playBackwards("usp");
        }}
      >
        Go back
      </button>
    </>
  );
};

const NextButtonLayer = () => {
  return (
    <div>
      <button
        className="button"
        onClick={() => {
          imageSequenceVideoController.playForward("360", {
            onLastFrame: () => {
              updateContainerState({
                containerKey: "1_container",
                updatedState: { currentBlock: "usp" },
              });
              htmlVideoController.playForward("usp", { fromStart: true });
            },
          });
        }}
      >
        Next
      </button>

      <p>Drag to the right to explore</p>
    </div>
  );
};

/* 
const anyVideoProps: ComponentProps<"video"> = {
  preload: `auto`,
  muted: true, // allow starting the video by calling play() method without prior user interaction on Chromium browsers
  playsInline: true, // mobile devices should not auto full-screen the video
}; 
*/

const labelsCoordinatesByFrameIndex = generateLabelLayerCoordinates();

function generateLabelLayerCoordinates() {
  const providedPositions = hotspotData360.map((hotspotData360) =>
    convertHotspotData(
      {
        width: 1920,
        height: 1440,
      },
      hotspotData360,
    ),
  );

  return Array(80)
    .fill("")
    .map((_, index) => {
      const x = Math.min(index, 80); /* % left to right */
      const y = Math.min(index * 2, 80); /* % top to bottom */

      const getTitle = (index: number) => {
        if (0 === index) {
          return `Early detection of patient deterioration`;
        }

        if (1 === index) {
          return `Seamless connectivity`;
        }

        if (2 === index) {
          return `Early warning scores`;
        }

        return `index=${index} todo`;
      };

      const getDespinationBlockKey = (index: number) => {
        if (0 === index) {
          //Early detectiion
          return "Mod01_USP02a__Mod01_USP02b__frame";
        }

        if (1 === index) {
          //Seamless connectivity
          return "Mod03_USP01__Mod03_USP02_Intro__frame";
        }

        if (2 === index) {
          //Early warning scoring
          return "Mod01_USP02b__Mod01_USP02c-d__frame";
        }

        throw new Error(
          `unhandled index in getDespinationBlockKey(index=${index})`,
        );
      };

      return providedPositions.map((providedPositions, i) => {
        if (
          !!providedPositions[index] &&
          "number" === typeof providedPositions[index]?.x &&
          "number" === typeof providedPositions[index]?.y &&
          !!providedPositions[index]?.anchorPoint
        ) {
          const secondBtn = {
            key: i,
            x: providedPositions[index]?.x || x,
            y: providedPositions[index]?.y || y,
            anchorPoint: providedPositions[index]?.anchorPoint,
            component: (
              <Hotspot
                onClick={() => {
                  imageSequenceVideoController.playTowardsClosestEdge("360", {
                    onEdgeFrame: () => {
                      const targetBlock = getDespinationBlockKey(i);
                      updateContainerState({
                        containerKey: "1_container",
                        updatedState: {
                          currentBlock: targetBlock,
                        },
                      });
                    },
                  });
                }}
                title={getTitle(i)}
                height={providedPositions[index]?.h || 0}
              />
            ),
          };

          return secondBtn;
        }
      });
    });
}
const LabelsLayer = () => {
  const states = useStore(imageSequenceVideoStore);
  const currentState = states["360"];
  const currentIndex = currentState?.activeFrameIndex || 0;

  const labels = labelsCoordinatesByFrameIndex?.[currentIndex] || null;

  if (!labels) {
    return null;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {labels.map((labelInfo) => {
        if (!labelInfo) {
          return null;
        }

        const key = `${currentIndex}_${labelInfo.key}`;

        if (!labelInfo.anchorPoint) {
          // todo how can it be undefined if labelInfo exists?
          return <Fragment key={key} />;
        }

        return (
          <AnchoredComponent
            key={key}
            x={labelInfo.x}
            y={labelInfo.y}
            anchorPoint={labelInfo.anchorPoint}
          >
            {labelInfo.component}
          </AnchoredComponent>
        );
      })}
    </div>
  );
};

const htmlVideoFallback = "Error in video";

const videoProps: ComponentProps<"video"> = {
  muted: true,
  playsInline: true,
  preload: `auto`,
};

const blocksAsArray: Block[] = [
  // {
  //   previousBlockKey: null,
  //   blockKey: "Mod00_USP01_Intro__frame",
  //   nextBlockKey: "Mod00_USP01_Intro",
  //   blockType: BlockType.SINGLE_FRAME,
  //   frameGroup: generateSingleFrameGroup(`Mod00_USP01_Intro`),
  //   layers: [
  //     {
  //       key: "ScrollDownToStartNoteLayer",
  //       component: (
  //         <WindowWidth>
  //           <ScrollDownToStartNoteLayer />
  //         </WindowWidth>
  //       ),
  //       wrapperDivProps: {},
  //     },
  //   ],
  //   onEndReached: BlockAction.PLAY_NEXT,
  //   onStartReached: BlockAction.IGNORE,
  // },
  {
    blockKey: "Mod00_USP01_Intro",
    videoKey: "Mod00_USP01_Intro",
    videos: generateVideos("Mod00_USP01_Intro", null),
    onStartReached: BlockAction.IGNORE, // not gonna happen
    onEndReached: BlockAction.STOP_ON_NEXT,
    nextBlockKey: "360",
    previousBlockKey: null,
    blockType: BlockType.HTML_VIDEO,
    layers: [],
  },
  {
    blockKey: "360",
    onStartReached: BlockAction.IGNORE,
    onEndReached: BlockAction.PLAY_NEXT,
    nextBlockKey: "Mod01_USP01a",
    previousBlockKey: "Mod00_USP01_Intro",
    blockType: BlockType.IMAGE_SEQUENCE,
    frameGroups: frames360,
    layers: [
      {
        from: 0,
        to: 79,
        key: "Overview",
        component: (
          <WindowWidth>
            <Indicator360 />
            <ContentLayer
              className="in anim01"
              title="Connex spot monitor®"
              subtitle="Meet the Welch Allyn®"
              modifier={["increased-title", "xl", "center"]}
              content="Connex spot monitor® (content)"
              buttonModule={{
                text: "Try out now",
                link: "Mod02_USP01",
              }}
              separatorPosition="afterTitle"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
      {
        from: 0,
        to: 79,
        key: "LabelsLayer",
        wrapperDivProps: {},
        component: <LabelsLayer />,
      },
    ],
    pauses: [],
    imageSequenceVideoKey: "360",
    looped: false,
  },
  {
    blockKey: "Mod01_USP01a",
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "360",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod01_USP01a",
    videos: generateVideos("Mod01_USP01a"),
    nextBlockKey: "Mod01_USP01a__Mod01_USP01b-d__frame",
    layers: [
      {
        from: 0,
        to: 1,
        key: "Overview-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim01"
              title="Connex spot monitor®"
              subtitle="Meet the Welch Allyn®"
              modifier={["increased-title", "xl", "center"]}
              content="Connex spot monitor® (content)"
              buttonModule={{
                text: "Try out now",
                link: "Mod02_USP01",
              }}
              separatorPosition="afterTitle"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.SINGLE_FRAME,
    previousBlockKey: "Mod01_USP01a",
    blockKey: "Mod01_USP01a__Mod01_USP01b-d__frame",
    nextBlockKey: "Mod01_USP01b-d",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    frameGroup: generateSingleFrameGroup(`Mod01_USP01b-d`),
    layers: [
      {
        key: "Explore_the_device",
        component: (
          <WindowWidth>
            <ContentLayer
              className="in anim01"
              title="Automatically capture a full set of vitals in under 1 minute"
              content="Automatically capture a full set of vitals in under 1 minute (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockKey: "Mod01_USP01b-d",
    blockType: BlockType.HTML_VIDEO,
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    layers: [
      {
        from: 0,
        to: 1,
        key: "Explore_the_device-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim01"
              title="Automatically capture a full set of vitals in under 1 minute"
              content="Automatically capture a full set of vitals in under 1 minute (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
    videoKey: "Mod01_USP01b-d",
    videos: generateVideos("Mod01_USP01b-d"),
    previousBlockKey: "Mod01_USP01a__Mod01_USP01b-d__frame",
    nextBlockKey: "Mod01_USP01b-d__Mod01_USP01e1__frame",
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod01_USP01b-d",
    blockKey: "Mod01_USP01b-d__Mod01_USP01e1__frame",
    nextBlockKey: "Mod01_USP01b-d__Mod01_USP01e2__frame",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod01_USP01e`,
      `Mod01_USP02a_Static`,
      `Mod01_USP02b_Static`,
    ),
    layers: [
      {
        key: "NIBP",
        component: (
          <WindowWidth>
            <ContentLayer
              className="in anim02"
              title="NIBP"
              content="NIBP (content)"
              modifier="decreased-title"
              hotspot="default"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod01_USP01b-d__Mod01_USP01e1__frame",
    blockKey: "Mod01_USP01b-d__Mod01_USP01e2__frame",
    nextBlockKey: "Mod01_USP01b-d__Mod01_USP01e3__frame",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod01_USP02a_Static`,
      `Mod01_USP02b_Static`,
      `Mod01_USP02c_Static`,
    ),
    layers: [
      {
        key: "Sp02",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim01"
              title="NIBP"
              content="NIBP (content)"
              modifier="decreased-title"
              hotspot="default"
            />
            <ContentLayer
              className="in anim02 anim-delay--065"
              title="Sp02"
              content="Sp02 (content)"
              modifier={["decreased-title", "lower2"]}
              hotspot="default"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod01_USP01b-d__Mod01_USP01e2__frame",
    blockKey: "Mod01_USP01b-d__Mod01_USP01e3__frame",
    nextBlockKey: "Mod01_USP01e",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod01_USP02b_Static`,
      `Mod01_USP02c_Static`,
      `Mod01_USP01e`,
    ),
    layers: [
      {
        key: "Temperature",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim01"
              title="Sp02"
              content="Sp02 (content)"
              modifier={["decreased-title", "lower2"]}
              hotspot="default"
            />
            <ContentLayer
              className="in anim02 anim-delay--065"
              title="Temperature"
              content="Temperature (content)"
              modifier={["decreased-title", "lower3", "long-line"]}
              hotspot="default"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockKey: "Mod01_USP01e",
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "Mod01_USP01b-d__Mod01_USP01e3__frame",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod01_USP01e",
    videos: generateVideos("Mod01_USP01e"),
    nextBlockKey: "Mod01_USP01e__Mod01_USP02a__frame",
    layers: [
      {
        from: 0,
        to: 1,
        key: "Temperature-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim01"
              title="Temperature"
              content="Temperature (content)"
              modifier={["decreased-title", "lower3", "long-line"]}
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod01_USP01e",
    blockKey: "Mod01_USP01e__Mod01_USP02a__frame",
    nextBlockKey: "Mod01_USP02a",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod01_USP02a`,
      `Mod01_USP03a_Static`,
      `Mod01_USP02a`,
    ),
    layers: [
      {
        key: "Respiration_rate_at_your_fingertips",
        component: (
          <WindowWidth>
            <ContentLayer
              className="in anim02"
              title="Respiration rate at your fingertips"
              content="Respiration rate at your fingertips (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockKey: "Mod01_USP02a",
    blockType: BlockType.HTML_VIDEO,
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod01_USP02a",
    videos: generateVideos("Mod01_USP02a"),
    previousBlockKey: "Mod01_USP01e__Mod01_USP02a__frame",
    nextBlockKey: "Mod01_USP02a__Mod01_USP02b__frame",
    layers: [
      {
        from: 0,
        to: 1,
        key: "Respiration_rate_at_your_fingertips-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Respiration rate at your fingertips"
              content="Respiration rate at your fingertips (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod01_USP02a",
    blockKey: "Mod01_USP02a__Mod01_USP02b__frame",
    nextBlockKey: "Mod01_USP02b",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod01_USP02a-rev`,
      `Mod01_USP03b_Static`,
      `Mod01_USP02b`,
    ),
    layers: [
      {
        key: "Respiration_rate_at_your_fingertips",
        component: (
          <WindowWidth>
            <ContentLayer
              className="in anim02"
              title="Identify patient deterioration earlier"
              content="Identify patient deterioration earlier (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockKey: "Mod01_USP02b",
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "Mod01_USP02a__Mod01_USP02b__frame",
    nextBlockKey: "Mod01_USP02b__Mod01_USP02c-d__frame",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod01_USP02b",
    videos: generateVideos("Mod01_USP02b"),
    layers: [
      {
        from: 0,
        to: 1,
        key: "Respiration_rate_at_your_fingertips-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Identify patient deterioration earlier"
              content="Identify patient deterioration earlier (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod01_USP02b",
    blockKey: "Mod01_USP02b__Mod01_USP02c-d__frame",
    nextBlockKey: "Mod01_USP02c-d",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod01_USP02b-rev`,
      `Mod01_USP03c_Static`,
      `Mod01_USP02c-d`,
    ),
    layers: [
      {
        key: "Early_warning_scoring-frame",
        component: (
          <WindowWidth>
            <ContentLayer
              className="in anim02"
              title="Early warning scoring"
              content="Early warning scoring (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockKey: "Mod01_USP02c-d",
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "Mod01_USP02b__Mod01_USP02c-d__frame",
    nextBlockKey: "Mod01_USP02c-d__Mod01_USP03a__frame",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod01_USP02c-d",
    videos: generateVideos("Mod01_USP02c-d"),
    layers: [
      {
        from: 0,
        to: 1,
        key: "Early_warning_scoring-frame-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Early warning scoring"
              content="Early warning scoring (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.SINGLE_FRAME,
    previousBlockKey: "Mod01_USP02c-d",
    blockKey: "Mod01_USP02c-d__Mod01_USP03a__frame",
    nextBlockKey: "Mod01_USP03a",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    frameGroup: generateSingleFrameGroup(`Mod01_USP03a`),
    layers: [
      {
        key: "Early_warning_scoring-styled",
        component: (
          <WindowWidth>
            <ContentLayer
              className="in anim02"
              title="Early warning scoring (styled)"
              content="Early warning scoring (content_2)"
              playButton
              playButtonLink="https://www.youtube.com/watch?v=RoiNjdmoxaE"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockKey: "Mod01_USP03a",
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "Mod01_USP02c-d__Mod01_USP03a__frame",
    nextBlockKey: "Mod01_USP03a__Mod01_USP4_Outro__frame",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod01_USP03a",
    videos: generateVideos("Mod01_USP03a"),
    layers: [
      {
        from: 0,
        to: 1,
        key: "Early_warning_scoring-styled-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Early warning scoring (styled)"
              content="Early warning scoring (content_2)"
              playButton
              playButtonLink="https://www.youtube.com/watch?v=RoiNjdmoxaE"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.SINGLE_FRAME,
    previousBlockKey: "Mod01_USP03a",
    blockKey: "Mod01_USP03a__Mod01_USP4_Outro__frame",
    nextBlockKey: "Mod01_USP03a__Mod01_USP4_Outro2__frame",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.STOP_ON_NEXT,
    frameGroup: generateSingleFrameGroup(`Mod01_USP4_Outro`),
    layers: [
      {
        wrapperDivProps: {},
        key: "significant_time_savings",
        component: (
          <WindowWidth>
            <TabLayer
              containerKey="1_container"
              blockKey="Mod01_USP03a__Mod01_USP4_Outro__frame"
              modifier="transparent"
              tabs={[
                {
                  tabModifier: "anim01",
                  title: "Significant time savings",
                  content: "Significant time savings (content)",
                  linksBlocks: [
                    {
                      text: "Read case study",
                      url: "https://pubmed.ncbi.nlm.nih.gov/28339626/",
                    },
                  ],
                  imageComponent: <SignificantTimeSavingsSVG />,
                },
              ]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockType: BlockType.SINGLE_FRAME,
    previousBlockKey: "Mod01_USP03a__Mod01_USP4_Outro__frame",
    blockKey: "Mod01_USP03a__Mod01_USP4_Outro2__frame",
    nextBlockKey: "Mod01_USP4_Outro",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    frameGroup: generateSingleFrameGroup(`Mod01_USP4_Outro`),
    layers: [
      {
        key: "Mod01_USP4_Outro_Contact_button__video",
        component: (
          <WindowWidth>
            <BackgroundLayer
              className="layerswipe in"
              title="You’ve completed the device exploration"
              buttons={[
                {
                  text: "Get in touch",
                  onClick: () => {
                    onGetInTouch();
                  },
                },
              ]}
              helptext="Scroll to continue experience"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockKey: "Mod01_USP4_Outro",
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "Mod01_USP03a__Mod01_USP4_Outro__frame",
    nextBlockKey: "Mod02_USP01",
    onEndReached: BlockAction.PLAY_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod01_USP4_Outro",
    videos: generateVideos("Mod01_USP4_Outro"),
    layers: [
      {
        from: 0,
        to: 1,
        key: "Mod01_USP4_Outro_Contact_button__video",
        component: (
          <WindowWidth>
            <BackgroundLayer
              className="layerswipe out"
              title="You’ve completed the device exploration"
              buttons={[
                {
                  text: "Get in touch",
                  onClick: () => {
                    onGetInTouch();
                  },
                },
              ]}
              helptext="Scroll to continue experience"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    previousBlockKey: "Mod01_USP4_Outro",
    blockKey: "Mod01_USP4_Outro_Contact_button__frame",
    nextBlockKey: "Mod02_USP01",
    blockType: BlockType.SINGLE_FRAME,
    frameGroup: generateSingleFrameGroup("Mod00_USP02_360"),
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    layers: [],
  },
  {
    blockKey: "Mod02_USP01",
    videoKey: "Mod02_USP01",
    previousBlockKey: "Mod01_USP03a__Mod01_USP4_Outro2__frame",
    nextBlockKey: "Mod02_USP01__Mod02_USP02__frame",
    blockType: BlockType.HTML_VIDEO,
    layers: [],
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videos: generateVideos("Mod02_USP01"),
  },
  {
    blockType: BlockType.SINGLE_FRAME,
    previousBlockKey: "Mod02_USP01",
    blockKey: "Mod02_USP01__Mod02_USP02__frame",
    nextBlockKey: "Mod02_USP02",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    frameGroup: generateSingleFrameGroup(`Mod02_USP02`),
    layers: [
      {
        key: "Try_it-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="in anim01"
              title="Experience the one minute setup"
              content="Experience the one minute setup (content)"
              buttonModule={{
                text: "Start now",
                link: "Mod02_USP02",
              }}
              separatorPosition="afterContent"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockKey: "Mod02_USP02",
    videoKey: "Mod02_USP02",
    previousBlockKey: "Mod02_USP01__Mod02_USP02__frame",
    nextBlockKey: "Mod02_USP02_Scan",
    blockType: BlockType.HTML_VIDEO,
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videos: generateVideos("Mod02_USP02"),
    layers: [
      {
        from: 0,
        to: 1,
        key: "Try_it-out-out",
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim01"
              title="Experience the one minute setup"
              content="Experience the one minute setup (content)"
              buttonModule={{
                text: "Start now",
                link: "Mod02_USP02",
              }}
              separatorPosition="afterContent"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_SEQUENCE,
    previousBlockKey: "Mod02_USP02",
    blockKey: "Mod02_USP02_Scan",
    nextBlockKey: "Mod02_USP03",
    swipeTouchDisabled: true,
    layers: [
      {
        from: 0,
        to: 10,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ScanDragLayer
              startPosition={[75.8, 63.5]}
              endPosition={[61.2, 37]}
              imageSequenceVideoKey="Mod02_USP02_Scan"
            />
            <ContentLayer
              className="in anim02"
              title="Scan Clinician ID"
              content="Scan Clinician ID (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
    imageSequenceVideoKey: "Mod02_USP02_Scan",
    onEndReached: BlockAction.PLAY_NEXT,
    onStartReached: BlockAction.PLAY_NEXT,
    pauses: [],
    frameGroups: generateResponsiveFrameGroups(
      11,
      (index, ending) =>
        `./demo/Mod02_USP02_Scan/Mod02_USP02_Scan_${index}${ending}.jpg`,
    ),
  },
  {
    blockKey: "Mod02_USP03",
    videoKey: "Mod02_USP03",
    previousBlockKey: "Mod02_USP02_Scan",
    nextBlockKey: "Mod02_USP03_Scan",
    blockType: BlockType.HTML_VIDEO,
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videos: generateVideos("Mod02_USP03"),
    layers: [
      {
        from: 0,
        to: 1,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Scan Clinician ID"
              content="Scan Clinician ID (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_SEQUENCE,
    previousBlockKey: "Mod02_USP03",
    blockKey: "Mod02_USP03_Scan",
    nextBlockKey: "Mod02_USP04",
    swipeTouchDisabled: true,
    layers: [
      {
        from: 0,
        to: 10,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ScanDragLayer
              startPosition={[83.5, 67]}
              endPosition={[72.5, 38]}
              imageSequenceVideoKey="Mod02_USP03_Scan"
            />
            <ContentLayer
              className="in anim02"
              title="Scan patient ID"
              content="Scan patient ID (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
    imageSequenceVideoKey: "Mod02_USP03_Scan",
    onEndReached: BlockAction.PLAY_NEXT,
    onStartReached: BlockAction.PLAY_NEXT,
    pauses: [],
    frameGroups: generateResponsiveFrameGroups(
      11,
      (index, ending) =>
        `./demo/Mod02_USP03_Scan/Mod02_USP03_Scan_${index}${ending}.jpg`,
    ),
  },
  {
    blockKey: "Mod02_USP04",
    blockType: BlockType.HTML_VIDEO,
    nextBlockKey: "Mod02_USP04_Scan",
    previousBlockKey: "Mod02_USP03_Scan",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod02_USP04",
    videos: generateVideos("Mod02_USP04"),
    layers: [
      {
        from: 0,
        to: 1,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Scan patient ID"
              content="Scan patient ID (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_SEQUENCE,
    previousBlockKey: "Mod02_USP04",
    blockKey: "Mod02_USP04_Scan",
    nextBlockKey: "Mod02_USP05",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    imageSequenceVideoKey: "Mod02_USP04_Scan",
    frameGroups: generateResponsiveFrameGroups(
      11,
      (index, end) =>
        `./demo/Mod02_USP04_Scan/Mod02_USP04_Scan_${index}${end}.jpg`,
    ),
    pauses: [],
    swipeTouchDisabled: true,
    layers: [
      {
        from: 0,
        to: 10,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ScanDragLayer
              startPosition={[68.5, 68]}
              endPosition={[49.5, 44.5]}
              imageSequenceVideoKey="Mod02_USP04_Scan"
            />
            <ContentLayer
              className="in anim02"
              title="Apply blood pressure cuff on patient"
              content="Apply blood pressure cuff on patient (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockType: BlockType.HTML_VIDEO,
    videos: generateVideos("Mod02_USP05"),
    previousBlockKey: "Mod02_USP04_Scan",
    videoKey: "Mod02_USP05",
    blockKey: "Mod02_USP05",
    nextBlockKey: "Mod02_USP05_Scan",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    layers: [
      {
        from: 0,
        to: 1,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Apply blood pressure cuff on patient"
              content="Apply blood pressure cuff on patient (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockKey: "Mod02_USP05_Scan",
    blockType: BlockType.IMAGE_SEQUENCE,
    swipeTouchDisabled: true,
    layers: [
      {
        from: 0,
        to: 10,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ScanDragLayer
              startPosition={[91, 64]}
              endPosition={[67, 37]}
              imageSequenceVideoKey="Mod02_USP05_Scan"
            />
            <ContentLayer
              className="in anim02"
              title="Apply Sp02 Sensor on patient"
              content="Apply Sp02 Sensor on patient (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
    previousBlockKey: "Mod02_USP05",
    nextBlockKey: "Mod02_USP06",
    onEndReached: BlockAction.PLAY_NEXT,
    onStartReached: BlockAction.PLAY_NEXT,
    imageSequenceVideoKey: "Mod02_USP05_Scan",
    pauses: [],
    frameGroups: generateResponsiveFrameGroups(
      11,
      (i, e) => `./demo/Mod02_USP05_Scan/Mod02_USP05_Scan_${i}${e}.jpg`,
    ),
  },
  {
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "Mod02_USP05_Scan",
    blockKey: "Mod02_USP06",
    nextBlockKey: "Mod02_USP06_Scan",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod02_USP06",
    videos: generateVideos("Mod02_USP06"),
    layers: [
      {
        from: 0,
        to: 10,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Apply Sp02 Sensor on patient"
              content="Apply Sp02 Sensor on patient (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    imageSequenceVideoKey: "Mod02_USP06_Scan",
    blockKey: "Mod02_USP06_Scan",
    blockType: BlockType.IMAGE_SEQUENCE,
    swipeTouchDisabled: true,
    layers: [
      {
        from: 0,
        to: 10,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ScanDragLayer
              startPosition={[86, 68.5]}
              endPosition={[71, 38]}
              imageSequenceVideoKey="Mod02_USP06_Scan"
            />
            <ContentLayer
              className="in anim02"
              title="Take patient temperature"
              content="Take patient temperature (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
    previousBlockKey: "Mod02_USP06",
    nextBlockKey: "Mod02_USP07",
    frameGroups: generateResponsiveFrameGroups(
      11,
      (i, e) => `./demo/Mod02_USP06_Scan/Mod02_USP06_Scan_${i}${e}.jpg`,
    ),
    pauses: [],
    onEndReached: BlockAction.PLAY_NEXT,
    onStartReached: BlockAction.PLAY_NEXT,
  },
  {
    blockKey: "Mod02_USP07",
    blockType: BlockType.HTML_VIDEO,
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    previousBlockKey: "Mod02_USP06_Scan",
    nextBlockKey: "Mod02_USP07__Mod02_USP08__frame",
    videoKey: "Mod02_USP07",
    videos: generateVideos("Mod02_USP07"),
    layers: [
      {
        from: 0,
        to: 10,
        key: "full_screen",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Take patient temperature"
              content="Take patient temperature (content)"
              modifier={["lower"]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod02_USP07",
    blockKey: "Mod02_USP07__Mod02_USP08__frame",
    nextBlockKey: "Mod02_USP08__Mod02_USP09__frame",
    layers: [
      {
        key: "Enter_additional_parameters",
        component: (
          <WindowWidth>
            <ContentLayer
              className="in anim02"
              title="Enter additional parameters"
              content="Take patient temperature (content)"
            />
          </WindowWidth>
        ),
        wrapperDivProps: {},
      },
    ],
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod02_USP07-rev`,
      `Mod02_USP07_Static`,
      `Mod02_USP08_Static`,
    ),
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.STOP_ON_NEXT,
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod02_USP07__Mod02_USP08__frame",
    blockKey: "Mod02_USP08__Mod02_USP09__frame",
    nextBlockKey: "Mod02_USP09__Mod02_USP10__frame",
    layers: [
      {
        key: "Mod02_USP08-layer",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Enter additional parameters"
              content="Take patient temperature (content)"
            />
            <ContentLayer
              className="in anim02 anim-delay--05"
              title="Select the required score"
              content="Select the required score (content)"
            />
          </WindowWidth>
        ),
      },
    ],
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod02_USP07_Static`,
      `Mod02_USP08_Static`,
      `Mod02_USP09_Static`,
    ),
    onStartReached: BlockAction.STOP_ON_NEXT,
    onEndReached: BlockAction.STOP_ON_NEXT,
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod02_USP08__Mod02_USP09__frame",
    blockKey: "Mod02_USP09__Mod02_USP10__frame",
    nextBlockKey: "Mod02_USP10__Mod02_USP11__frame",
    layers: [
      {
        key: "Mod02_USP09-layer",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Select the required score"
              content="Select the required score (content)"
            />
            <ContentLayer
              className="in anim02 anim-delay--05"
              title="Review calculation"
              content="Review calculation (content)"
            />
          </WindowWidth>
        ),
      },
    ],
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod02_USP08_Static`,
      `Mod02_USP09_Static`,
      `Mod02_USP10_Static`,
    ),
    onStartReached: BlockAction.STOP_ON_NEXT,
    onEndReached: BlockAction.STOP_ON_NEXT,
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod02_USP09__Mod02_USP10__frame",
    blockKey: "Mod02_USP10__Mod02_USP11__frame",
    nextBlockKey: "Mod02_USP11__Mod02_USP12__frame",
    layers: [
      {
        key: "Mod02_USP10-layer",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Review calculation"
              content="Review calculation (content)"
            />
            <ContentLayer
              className="in anim02 anim-delay--05"
              title="Review caregiver instructions"
              content="Review caregiver instructions (content)"
            />
          </WindowWidth>
        ),
      },
    ],
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod02_USP09_Static`,
      `Mod02_USP10_Static`,
      `Mod02_USP11_Static`,
    ),
    onStartReached: BlockAction.STOP_ON_NEXT,
    onEndReached: BlockAction.STOP_ON_NEXT,
  },
  {
    blockType: BlockType.IMAGE_FADE,
    previousBlockKey: "Mod02_USP10__Mod02_USP11__frame",
    blockKey: "Mod02_USP11__Mod02_USP12__frame",
    nextBlockKey: "Mod02_USP12_Intro",
    layers: [
      {
        key: "Mod02_USP11-layer",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Review caregiver instructions"
              content="Review caregiver instructions (content)"
            />
            <ContentLayer
              className="in anim02 anim-delay--05"
              title="Save to send to EMR"
              content="Save to send to EMR (content)"
            />
          </WindowWidth>
        ),
      },
    ],
    fadeDuration: 0.5,
    fadeTransition: "transition--linear",
    frameGroup: generateImageFadesGroup(
      `Mod02_USP10_Static`,
      `Mod02_USP11_Static`,
      `Mod02_USP12_Intro`,
    ),
    onStartReached: BlockAction.STOP_ON_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
  },
  {
    blockKey: "Mod02_USP12_Intro",
    videoKey: "Mod02_USP12_Intro",
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "Mod02_USP11__Mod02_USP12__frame",
    nextBlockKey: "Mod02_USP12_Intro__Mod02_USP12_Outro__frame",
    videos: generateVideos("Mod02_USP12_Intro"),
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    layers: [
      {
        from: 0,
        to: 1,
        key: "Mod02_USP11-layer-out",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <ContentLayer
              className="out anim02"
              title="Save to send to EMR"
              content="Save to send to EMR (content)"
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockType: BlockType.SINGLE_FRAME,
    previousBlockKey: "Mod02_USP12_Intro",
    blockKey: "Mod02_USP12_Intro__Mod02_USP12_Outro__frame",
    nextBlockKey: "Mod02_USP12_Outro",
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    frameGroup: generateSingleFrameGroup(`Mod02_USP12_Outro`),
    layers: [
      {
        key: "Mod02_USP12_Intro",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <BackgroundLayer
              className="layerswipe in"
              modifier="transparent"
              title="Patient Vitals Signs were successfully sent to the emr"
              buttons={[
                {
                  text: "Try again",
                  modifier: "secondary",
                  onClick: () => {
                    containerController.pauseActiveBlock("1_container");
                    updateContainerState({
                      containerKey: "1_container",
                      updatedState: {
                        currentBlock: "Mod02_USP01",
                      },
                    });
                    htmlVideoController.playForward("Mod02_USP01", {
                      fromStart: true,
                    });
                  },
                },
                {
                  text: "Get in touch",
                  onClick: () => {
                    onGetInTouch();
                  },
                },
              ]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    onEndReached: BlockAction.PLAY_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    blockType: BlockType.HTML_VIDEO,
    previousBlockKey: "Mod02_USP12_Intro__Mod02_USP12_Outro__frame",
    videoKey: "Mod02_USP12_Outro",
    blockKey: "Mod02_USP12_Outro",
    nextBlockKey: "Mod03_USP01",
    videos: generateVideos("Mod02_USP12_Outro"),
    layers: [
      {
        from: 0,
        to: 1,
        key: "Mod02_USP12_Intro",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <BackgroundLayer
              className="layerswipe out"
              modifier="transparent"
              title="Patient Vitals Signs were successfully sent to the emr"
              buttons={[
                {
                  text: "Try again",
                  modifier: "secondary",
                  onClick: () => {
                    containerController.pauseActiveBlock("1_container");
                    updateContainerState({
                      containerKey: "1_container",
                      updatedState: {
                        currentBlock: "Mod02_USP01",
                      },
                    });
                    htmlVideoController.playForward("Mod02_USP01", {
                      fromStart: true,
                    });
                  },
                },
                {
                  text: "Get in touch",
                  onClick: () => {
                    onGetInTouch();
                  },
                },
              ]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    previousBlockKey: "Mod02_USP12_Outro",
    blockKey: "Mod02_USP12_Outro_Contact_button__frame",
    nextBlockKey: "Mod03_USP01",
    blockType: BlockType.SINGLE_FRAME,
    frameGroup: generateSingleFrameGroup("Mod00_USP02_360"),
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.PLAY_NEXT,
    layers: [
      {
        key: "Contact_button",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <StepsLayer
              steps={[
                {
                  key: "todo1",
                  component: <TodoComponent number={1} />,
                },
              ]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockKey: "Mod03_USP01",
    blockType: BlockType.HTML_VIDEO,
    layers: [],
    previousBlockKey: "Mod02_USP12_Outro",
    nextBlockKey: "Mod03_USP01__Mod03_USP02_Intro__frame",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.PLAY_NEXT,
    videoKey: "Mod03_USP01",
    videos: generateVideos("Mod03_USP01"),
  },
  {
    previousBlockKey: "Mod03_USP01",
    blockKey: "Mod03_USP01__Mod03_USP02_Intro__frame",
    nextBlockKey: "Mod03_USP02_Intro",
    blockType: BlockType.SINGLE_FRAME,
    frameGroup: generateSingleFrameGroup("Mod03_USP02_Intro"),
    onEndReached: BlockAction.PLAY_NEXT,
    onStartReached: BlockAction.PLAY_NEXT,
    layers: [
      {
        key: "connectivity",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <TabLayer
              containerKey="1_container"
              blockKey="Mod03_USP01__Mod03_USP02_Intro__frame"
              tabs={[
                {
                  title: "Leading the way in connectivity",
                  content: "Leading the way in connectivity (content)",
                  tabModifier: "anim01",
                },
              ]}
              anchorTabs={[
                {
                  tabName: "Proven connectivity",
                  onClick: () => {
                    containerController.pauseActiveBlock("1_container");

                    updateContainerState({
                      containerKey: "1_container",
                      updatedState: {
                        currentBlock: "Mod03_USP02_Intro",
                      },
                    });

                    htmlVideoController.playForward("Mod03_USP02_Intro", {
                      fromStart: true,
                    });

                    updateTabState({
                      tabKey: "connectivity",
                      updatedState: {
                        currentTab: 0,
                      },
                    });
                  },
                },
                {
                  tabName: "Time savings",
                  onClick: () => {
                    containerController.pauseActiveBlock("1_container");

                    updateContainerState({
                      containerKey: "1_container",
                      updatedState: {
                        currentBlock: "Mod03_USP02_Intro",
                      },
                    });

                    htmlVideoController.playForward("Mod03_USP02_Intro", {
                      fromStart: true,
                    });

                    updateTabState({
                      tabKey: "connectivity",
                      updatedState: {
                        currentTab: 1,
                      },
                    });
                  },
                },
                {
                  tabName: "Reduction of documentation errors",
                  onClick: () => {
                    containerController.pauseActiveBlock("1_container");

                    updateContainerState({
                      containerKey: "1_container",
                      updatedState: {
                        currentBlock: "Mod03_USP02_Intro",
                      },
                    });

                    htmlVideoController.playForward("Mod03_USP02_Intro", {
                      fromStart: true,
                    });

                    updateTabState({
                      tabKey: "connectivity",
                      updatedState: {
                        currentTab: 2,
                      },
                    });
                  },
                },
                {
                  tabName: "Data security",
                  onClick: () => {
                    containerController.pauseActiveBlock("1_container");

                    updateContainerState({
                      containerKey: "1_container",
                      updatedState: {
                        currentBlock: "Mod03_USP02_Intro",
                      },
                    });

                    htmlVideoController.playForward("Mod03_USP02_Intro", {
                      fromStart: true,
                    });

                    updateTabState({
                      tabKey: "connectivity",
                      updatedState: {
                        currentTab: 3,
                      },
                    });
                  },
                },
              ]}
              modifier={["transparent", "dark", "fixed"]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockType: BlockType.HTML_VIDEO,
    layers: [],
    previousBlockKey: "Mod03_USP01__Mod03_USP02_Intro__frame",
    blockKey: "Mod03_USP02_Intro",
    nextBlockKey: "Mod03_USP02_Intro__Mod03_USP02_Outro__frame",
    onEndReached: BlockAction.STOP_ON_NEXT,
    onStartReached: BlockAction.STOP_ON_NEXT,
    videoKey: "Mod03_USP02_Intro",
    videos: generateVideos("Mod03_USP02_Intro"),
  },
  {
    blockType: BlockType.SINGLE_FRAME,
    previousBlockKey: "Mod03_USP01__Mod03_USP02_Intro__frame",
    blockKey: "Mod03_USP02_Intro__Mod03_USP02_Outro__frame",
    nextBlockKey: "none",
    onStartReached: BlockAction.IGNORE,
    onEndReached: BlockAction.IGNORE,
    frameGroup: generateSingleFrameGroup(`Mod03_USP02_Outro`),
    layers: [
      {
        key: "connectivity_tabs",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <TabLayer
              containerKey="1_container"
              blockKey="Mod03_USP02_Intro__Mod03_USP02_Outro__frame"
              tabKey="connectivity"
              modifier="transparent"
              onStartReached={() => {
                containerController.pauseActiveBlock("1_container");

                updateContainerState({
                  containerKey: "1_container",
                  updatedState: {
                    currentBlock: "Mod03_USP01",
                  },
                });

                htmlVideoController.playForward("Mod03_USP01", {
                  fromStart: true,
                });
              }}
              onEndReached={() => {
                containerController.pauseActiveBlock("1_container");

                updateContainerState({
                  containerKey: "1_container",
                  updatedState: {
                    currentBlock: "Mod03_USP02_Outro_Contact_button__frame",
                  },
                });
              }}
              tabs={[
                {
                  title: "Proven connectivity (title)",
                  tabName: "Proven connectivity",
                  content: "Proven connectivity (content)",
                  tabModifier: "anim01",
                  linksBlocks: [
                    {
                      text: "Read more",
                      url:
                        "media/APR67101-EN-EMEA-R1_360_Vital-Signs-Management-Solution_Brochure-LR.pdf",
                    },
                  ],
                  imageComponent: <ProvenConnectivitySVG />,
                },
                {
                  title: "Time savings (title)",
                  tabName: "Time savings",
                  content: "Time savings (content)",
                  tabModifier: "anim02",
                  linksBlocks: [
                    {
                      text: "Read more",
                      url:
                        "https://www.welchallyn.com/content/dam/welchallyn/documents/upload-docs/emeai/en-uk/MC13739%20Rev%20A%20Imperial%20Case%20Study%200317%20WR.pdf",
                    },
                  ],
                  imageComponent: <SignificantTimeSavingsSVG2 />,
                },
                {
                  title: "Reduction of documentation errors (title)",
                  tabName: "Reduction of documentation errors",
                  content: "Reduction of documentation errors (content)",
                  tabModifier: "anim02",
                  linksBlocks: [
                    {
                      text:
                        "A ward-based time study of paper and electronic documentation",
                      url: "https://pubmed.ncbi.nlm.nih.gov/28339626/",
                    },
                    {
                      text:
                        "‘Errors’ and omissions in paper-based early warning scores",
                      url: "https://bmjopen.bmj.com/content/5/7/e007376",
                    },
                    {
                      text: "Reducing errors through automated vital signs",
                      url:
                        "https://www.nursingcenter.com/journalarticle?Article_ID=932354&Journal_ID=54020&Issue_ID=932332",
                    },
                  ],
                  imageComponent: <ReductionSVG />,
                },
                {
                  title: "Data security (title)",
                  tabName: "Data security",
                  content: "Data security (content)",
                  tabModifier: "anim02",
                  linksBlocks: [
                    {
                      text: "The clinicians guide to cybersecurity",
                      url:
                        "https://www.welchallyn.com/content/dam/welchallyn/documents/sap-documents/MRC/80022/80022567MRCPDF.pdf",
                    },
                    {
                      text: "Connected Vital signs single sign on brochure",
                      url:
                        "https://www.hillrom.com/en/knowledge/article/clinicians-guide-to-healthcare-cybersecurity-ebook/",
                    },
                  ],
                  imageComponent: <DataSecuritySVG />,
                },
              ]}
            />
          </WindowWidth>
        ),
      },
    ],
  },
  // {
  //   blockKey: "Mod03_USP02_Outro",
  //   blockType: BlockType.HTML_VIDEO,
  //   layers: [],
  //   previousBlockKey: "Mod03_USP02_Intro__Mod03_USP02_Outro__frame",
  //   nextBlockKey: "Mod03_USP02_Outro_Contact_button__frame",
  //   onEndReached: BlockAction.STOP_ON_NEXT,
  //   onStartReached: BlockAction.STOP_ON_NEXT,
  //   videoKey: "Mod03_USP02_Outro",
  //   videos: generateVideos("Mod03_USP02_Outro"),
  // },
  {
    previousBlockKey: "Mod03_USP02_Intro__Mod03_USP02_Outro__frame",
    blockKey: "Mod03_USP02_Outro_Contact_button__frame",
    nextBlockKey: "media_library&contact_form",
    blockType: BlockType.SINGLE_FRAME,
    frameGroup: generateSingleFrameGroup("Mod03_USP02_Outro-rev"),
    onStartReached: BlockAction.PLAY_NEXT,
    onEndReached: BlockAction.STOP_ON_NEXT,
    layers: [
      {
        key: "Contact_button",
        wrapperDivProps: {},
        component: (
          <WindowWidth>
            <BackgroundLayer
              className="in layerswipe"
              title="You’ve completed the connectivity exploration"
              buttons={[
                {
                  text: "Get in touch",
                  onClick: () => {
                    onGetInTouch();
                  },
                },
              ]}
              helptext="Scroll to continue experience"
            />
          </WindowWidth>
        ),
      },
    ],
  },
  {
    blockKey: "media_library&contact_form",
    previousBlockKey: "Mod03_USP02_Outro_Contact_button__frame",
    nextBlockKey: null,
    blockType: BlockType.STACKED,
    onEndReached: BlockAction.IGNORE,
    onStartReached: BlockAction.STOP_ON_NEXT,
    className: "bottom-block-transition",
    stack: [
      {
        key: "media_library",
        component: <MediaLibrary />,
      },
      {
        key: "contact_form",
        component: <ContactForm />,
      },
    ],
  },

  {
    blockKey: "submit_finish",
    previousBlockKey: "contact_form",
    nextBlockKey: null,
    blockType: BlockType.CUSTOM,
    component: (
      <BackgroundLayer
        className="layerswipe in"
        title="Thank you for submitting your details"
        subtitle="A Hillrom representative will contact you shortly."
        modifier="no-opacity"
        imageSrc={thankYouImageSrc}
      />
    ),
  },
];

function reduceBlocks(blocksAsArray: Block[]): ReducedBlocks {
  return blocksAsArray.reduce((acc: ReducedBlocks, curr) => {
    acc[curr.blockKey] = curr;
    return acc;
  }, {});
}

export const reducedBlocks = reduceBlocks(blocksAsArray);

const containerProps: ContainerProps = {
  containerKey: "1_container",
  initialBlockKey: "Mod00_USP01_Intro",
  blocks: reducedBlocks,
};

type HotspotDataItem = number | undefined;

type HotspotData = {
  x: HotspotDataItem[];
  y: HotspotDataItem[];
  w: HotspotDataItem[];
  h: HotspotDataItem[];
};

type FrameSize = { width: number; height: number };

type HotspotObject = {
  x: HotspotDataItem;
  y: HotspotDataItem;
  w: HotspotDataItem;
  h: HotspotDataItem;
};

function hotspotObjectToLabelData(
  absoluteFrameSize: FrameSize,
  hotspotObject: HotspotObject,
) {
  const { x, y, w, h } = hotspotObject;

  if (
    x === undefined ||
    y === undefined ||
    w === undefined ||
    h === undefined
  ) {
    return null;
  }

  const halfWidth = absoluteFrameSize.width / 1.275;

  const anchorPoint: AnchorPoint = { horizontal: "left", vertical: "bottom" };
  const anchorPointAbsolutePosition = { x: -1, y: -1 };

  const containerSize = { halfWidth: w / 2, halfHeight: h / 2 };

  // todo hmmmmm... something seems off. Maybe it's just the provided data? ↓
  if (anchorPoint.horizontal === "left") {
    if (anchorPoint.vertical === "bottom") {
      anchorPointAbsolutePosition.x = x - containerSize.halfWidth;
      anchorPointAbsolutePosition.y = y + containerSize.halfHeight;
    } else if (anchorPoint.vertical === "top") {
      anchorPointAbsolutePosition.x = x - containerSize.halfWidth;
      anchorPointAbsolutePosition.y = y - containerSize.halfHeight;
    } else if (anchorPoint.vertical === "center") {
      anchorPointAbsolutePosition.x = x - containerSize.halfWidth;
      anchorPointAbsolutePosition.y = y;
    }
  } else if (anchorPoint.horizontal === "right") {
    if (anchorPoint.vertical === "bottom") {
      anchorPointAbsolutePosition.x = x + containerSize.halfWidth;
      anchorPointAbsolutePosition.y = y + containerSize.halfHeight;
    } else if (anchorPoint.vertical === "top") {
      anchorPointAbsolutePosition.x = x + containerSize.halfWidth;
      anchorPointAbsolutePosition.y = y - containerSize.halfHeight;
    } else if (anchorPoint.vertical === "center") {
      anchorPointAbsolutePosition.x = x + containerSize.halfWidth;
      anchorPointAbsolutePosition.y = y;
    }
  } else if (anchorPoint.horizontal === "center") {
    if (anchorPoint.vertical === "bottom") {
      anchorPointAbsolutePosition.x = x;
      anchorPointAbsolutePosition.y = y + containerSize.halfHeight;
    } else if (anchorPoint.vertical === "top") {
      anchorPointAbsolutePosition.x = x;
      anchorPointAbsolutePosition.y = y - containerSize.halfHeight;
    } else if (anchorPoint.vertical === "center") {
      anchorPointAbsolutePosition.x = x;
      anchorPointAbsolutePosition.y = y;
    }
  }
  // ↑

  const anchorPointXYPercents = {
    x: (anchorPointAbsolutePosition.x / absoluteFrameSize.width) * 100,
    y: (anchorPointAbsolutePosition.y / absoluteFrameSize.height) * 100,
  };

  return {
    x: anchorPointXYPercents.x,
    y: anchorPointXYPercents.y,
    h,
    anchorPoint,
  };
}

function absoluteHotspotDataToObjects(absoluteHotspotData: HotspotData) {
  return absoluteHotspotData.x.map((x, index) => {
    const y = absoluteHotspotData.y[index];
    const w = absoluteHotspotData.w[index];
    const h = absoluteHotspotData.h[index];

    return { x, y, w, h };
  });
}

function convertHotspotData(
  absoluteFrameSize: FrameSize,
  absoluteHotspotData: HotspotData,
) {
  const { x, y, w, h } = absoluteHotspotData;

  const sameLengths = [x.length, y.length, w.length, h.length].every(
    (val, i, arr) => val === arr[0],
  );

  if (!sameLengths) {
    throw new Error("x, y, w, and h arrays must have equal amount of values");
  }

  const hotspotObjects = absoluteHotspotDataToObjects(absoluteHotspotData);

  return hotspotObjects.map((hotspotObject) =>
    hotspotObjectToLabelData(absoluteFrameSize, hotspotObject),
  );
}

function ContactForm() {
  return <ContactFormComponent />;
}

function MediaLibrary() {
  return (
    <MediaLibraryComponent
      mediaList={[
        {
          title: "Connected Vital Signs Brochures",
          type: "doc",
          items: [
            {
              name: "360 Vital Signs Management Solution",
              link:
                "media/APR67101-EN-EMEA-R1_360_Vital-Signs-Management-Solution_Brochure-LR.pdf",
            },
            {
              name: "Hillrom Welch Allyn Connex Spot Monitor (CSM)",
              link:
                "media/APR58101-EN-EMEA-R1_Connex-Spot-Monitor-Hospital-Market_Brochure-LR.pdf",
            },
            {
              name: "CSM with Masimo Respiration Rate",
              link:
                "media/APR63501-EN-EMEA-R1_Connex-Spot-Monitor-RRp-Feature_Proof-Point-Sheet-LR.pdf",
            },
          ],
        },
        {
          title: "Patient Deterioration",
          type: "doc",
          items: [
            {
              name: "Patient Deterioration - Sepsis",
              link:
                "media/APR75703-EN-UK-r1_Patient_Deterioration-Sepsis_Brochure-LR.pdf",
            },
            {
              name:
                "Thought Leadership on Early Detection of Deteriorating Patients",
              link:
                "media/APR102301-UK-R1_Thought_Leadership_Early_Detection_of_Deteriorating_Patients-LR.pdf",
            },
            {
              name: "Respiratory Rate Overview Brochure",
              link:
                "media/APR114201-EN-EMEA-R1_Resp-Rate-Overview_Brochure-LR.pdf",
            },
            {
              name: "Nursing Times Respiratory Rate Clinical Collection",
              link: "media/APR123101-EN-UK-R1_Nursing_Times_Advertorial.pdf",
            },
          ],
        },
        {
          title: "Webinars",
          type: "video",
          items: [
            {
              name: "Hillrom Respiration Rate Overview Seminar",
              link: "https://youtu.be/TKpqYF8MOOs",
            },
          ],
        },
        {
          title: "Video",
          type: "video",
          items: [
            {
              name: "CSM with Masimo RRp Demonstration Video",
              link: "https://youtu.be/tjUcFz6cQEk",
            },
            {
              name: "Hillrom Respiratory Rate Overview Video",
              link: "https://youtu.be/j0tCpPuNbMc",
            },
          ],
        },
        {
          title: "Data security",
          type: "doc",
          items: [
            {
              name: "The Clinicians Guide to Cybersecurity",
              link: "media/MC15673 The-Clinicians-Guide-to-Cybersecurity.pdf",
            },
            {
              name: "Connected Vital Signs Single Sign On Brochure",
              link: "media/SM4184EU_Vitals-Single-Sign-On_Sell-Sheet.pdf",
            },
          ],
        },
        {
          title: "Customer References",
          type: "doc",
          items: [
            {
              name: "UK Imperial Connected Vital Signs Case Study",
              link: "media/MC13739-Rev-A-Imperial-Case-Study-0317-WR.pdf",
            },
            {
              name: "SE Capio St. Gorans Connected Vital Signs Case Study",
              link: "media/MC14994-EU_Capio-St-Gorans_Case-Study.pdf",
            },
            {
              name: "UK Derby Hospital Connectd Vital Signs Case Study",
              link: "media/MC14671_Derby_Case-Study.pdf",
            },
          ],
        },
      ]}
    />
  );
}

const config = {
  screens: [
    {
      key: "01_screen",
      component: <Container {...containerProps} />,
    },
  ],
};

// todo move, automate
containerController.init("1_container", {
  currentBlock: "Mod00_USP01_Intro",
});
imageSequenceVideoController.init("360", seqFps, [], 79);
htmlVideoController.init("usp", {
  backwardsPauses: [],
  forwardPauses: [],
});

stackedBlockController.init("media_library&contact_form", {
  index: 0,
  settingScrollPosition: false,
  scrollWithAnimation: false,
});

imageSequenceVideoController.init("Mod02_USP02_Scan", seqFps, [], 10);
imageSequenceVideoController.init("Mod02_USP03_Scan", seqFps, [], 10);
imageSequenceVideoController.init("Mod02_USP04_Scan", seqFps, [], 10);
imageSequenceVideoController.init("Mod02_USP05_Scan", seqFps, [], 10);
imageSequenceVideoController.init("Mod02_USP06_Scan", seqFps, [], 10);

imageSequenceVideoController.setActiveFrameIndex("Mod02_USP02_Scan", 0);
imageSequenceVideoController.setActiveFrameIndex("Mod02_USP03_Scan", 0);
imageSequenceVideoController.setActiveFrameIndex("Mod02_USP04_Scan", 0);
imageSequenceVideoController.setActiveFrameIndex("Mod02_USP05_Scan", 0);
imageSequenceVideoController.setActiveFrameIndex("Mod02_USP06_Scan", 0);

htmlVideoController.init("Mod00_USP01_Intro", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP01a", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP01b-d", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP01e", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP02b", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP02c-d", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP03a", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP4_Outro", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod02_USP01", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod02_USP02", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP02a", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod01_USP02b", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod02_USP03", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod02_USP04", {
  backwardsPauses: [],
  forwardPauses: [],
});

htmlVideoController.init("Mod02_USP05", {
  backwardsPauses: [],
  forwardPauses: [],
});

htmlVideoController.init("Mod02_USP06", {
  backwardsPauses: [],
  forwardPauses: [],
});

htmlVideoController.init("Mod02_USP07", {
  backwardsPauses: [],
  forwardPauses: [],
});

htmlVideoController.init("Mod02_USP12_Intro", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod02_USP12_Outro", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod03_USP01", {
  backwardsPauses: [],
  forwardPauses: [],
});
htmlVideoController.init("Mod03_USP02_Intro", {
  backwardsPauses: [],
  forwardPauses: [],
});

export default config;
