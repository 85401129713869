import i18next, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { Config } from "types";

const fallbackLng = "en";

function configToi18nextResources(config: Config) {
  if (!config?.i18n || "object" !== typeof config.i18n) {
    throw new Error("config.i18n was not an object");
  }

  return Object.entries(config.i18n).reduce((acc: Resource, curr) => {
    const [lng, keyValueObj] = curr;

    acc[lng] = {
      translation: keyValueObj.translations,
    };

    return acc;
  }, {});
}

function changeLanguage(language: string) {
  i18next.changeLanguage(language);
}

function init(config: Config) {
  const resources = configToi18nextResources(config);

  i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
      {
        resources,
        fallbackLng,
      },
      (err, t) => {
        if (err) {
          console.error("Error while i18next init:", err);
        }
      },
    )
    .then(() => {
      changeLanguage(
        initialLng(Object.keys(resources), getCurrentLanguage(), fallbackLng),
      );
    });
}

function initialLng(
  availableLanguages: string[],
  detectedLanguage: string,
  fallbackLng: string,
) {
  if (availableLanguages.includes(detectedLanguage)) {
    return detectedLanguage;
  }
  return fallbackLng;
}

function getCurrentLanguage() {
  const lng = i18next.language;

  if (!lng.includes("-")) {
    return lng;
  }

  return lng.split("-")[0];
}

const i18n = {
  init,
  changeLanguage,
  getCurrentLanguage,
};

export default i18n;
